<template>
    <div>
        <div class="content-header">
            <el-row v-if="compareData.type==0" class="content-block">
                <el-col class="pull-left" :span="12">
                    <h3>原简历</h3>
                    <!-- <a v-if="!!data.id" :href="'/Headhunting/MyCompany.html#/candidateDetail/' + data.id" target="_blank">查看</a> -->
                    <a v-if="!!data.id" :href="'/#/candidateDetail/' + data.id" target="_blank" class="view">查看</a>
                </el-col>
                <el-col class="pull-right text-xs" :span="9" v-if="data.updateDate">
                    最后编辑时间: {{ data.updateDate | formatDateWithDot }}
                </el-col>
            </el-row>
            <el-row v-else class="content-block">
                <el-col class="pull-left" :span="12">
                    <h3>你上传的简历</h3>
                </el-col>
                <el-col class="pull-right text-xs" :span="9" v-if="data.updateDate">
                    文档最后编辑时间: {{ data.updateDate | formatDateWithDot }}
                </el-col>
            </el-row>
        </div>
        <el-row class="content-main">
            <el-row class="content-block base-info">
                <div class="high-line">
                    <div class="real-name" :title="data.realName">{{data.realName}}</div>
                    <div class="some-info">
                        <div v-if="data.gender!=null">{{genderMap[data.gender]}}</div>
                        <template v-if="data.yearOfExperience">
                            <div class="v-line">
                                <span class="v-line-span">{{data.yearOfExperience}}年经验</span>
                            </div> 
                        </template>
                        <template v-if="data.city">
                            <div class="v-line address-info" :title="provinceCityMap[data.city]">
                                {{provinceCityMap[data.city]}}
                            </div>
                        </template> 
                    </div>
                </div>
                <el-row>
                    <div class="resume-filed">
                        <i class="icon-font icon-briefcase"></i>
                    </div>
                    <div class="resume-filed">{{data.company}}</div>
                    <div class="resume-filed job-resume-filed">{{data.title}}</div>
                </el-row>
                <el-row>
                    <div class="resume-filed">
                        <i class="icon-font icon-boshimao"></i>
                    </div>
                    <div class="resume-filed">{{data.school}}</div>
                    <div class="resume-filed">{{data.major}}</div>
                    <div class="resume-filed" v-if="data.degree">{{degreeMap[data.degree]}}</div>
                </el-row>
                <el-row v-if="compareData.type == 0 || (compareData.type == 1 && !isXlsAnonymous)">
                    <template v-if="compareData.type == 0">
                        <div class="resume-filed">
                            <i class="icon-font icon-lianxigray"></i>
                        </div>
                        <div class="resume-filed">
                            <el-button
                                ref="mobileViewButton"
                                v-if="data.mobileStatus == 1"
                                class="info-block-btn"
                                type="primary"
                                size="mini"
                                @click="applyView('mobile')">
                                查看手机
                            </el-button>
                            <el-tooltip
                                placement="bottom"
                                effect="light"
                                popper-class="concat-tooltip"
                                :visible-arrow="false"
                                v-if="data.mobileStatus == 2">
                                <div slot="content">
                                    候选人正在入职保证期中，暂不能查看其联<br/>系方式
                                </div>
                                <span class="info-lock-btn">
                                    查看手机
                                    <i class="icon-font icon-suo"></i>
                                </span>
                            </el-tooltip>
                            <span
                                :class="data.mobileStatus == 4 ? 'mobile-unable' : ''"
                                v-if="data.mobileStatus == 3 || data.mobileStatus == 4">
                                {{data.mobile || 'xxxxxxxxxxx'}}
                            </span>
                        </div>
                        <div class="resume-filed email-resume-filed">
                            <i class="icon-font icon-et-envelope"></i>
                        </div>
                        <div class="resume-filed">
                            <el-button
                                ref="emailViewButton"
                                v-if="data.emailStatus == 1"
                                class="info-block-btn"
                                type="primary"
                                size="mini"
                                @click="applyView('email')">
                                查看邮箱
                            </el-button>
                            <el-tooltip
                                placement="bottom"
                                effect="light"
                                popper-class="concat-tooltip"
                                :visible-arrow="false"
                                v-if="data.emailStatus == 2">
                                <div slot="content">
                                    候选人正在入职保证期中，暂不能查看其联<br/>系方式
                                </div>
                                <span class="info-lock-btn">
                                    查看邮箱
                                    <i class="icon-font icon-suo"></i>
                                </span>
                            </el-tooltip>
                            <span
                                :class="data.emailStatus == 4 ? 'email-unable' : ''"
                                v-if="data.emailStatus == 3 || data.emailStatus == 4">
                                {{data.email || 'xxx@xx.com'}}
                            </span>
                        </div>
                        <br>
                        <div class="resume-filed wechat-resume-filed">
                            <i class="icon-font icon-wechat"></i>
                        </div>
                        <div class="resume-filed">
                            <el-button
                                ref="wechatViewButton"
                                v-if="data.wechatStatus == 1"
                                class="info-block-btn"
                                type="primary"
                                size="mini"
                                @click="applyView('wechat')">
                                查看微信
                            </el-button>
                            <el-tooltip
                                placement="bottom"
                                effect="light"
                                popper-class="concat-tooltip"
                                :visible-arrow="false"
                                v-if="data.wechatStatus == 2">
                                <div slot="content">
                                    候选人正在入职保证期中，暂不能查看其联<br/>系方式
                                </div>
                                <span class="info-lock-btn">
                                    查看微信
                                    <i class="icon-font icon-suo"></i>
                                </span>
                            </el-tooltip>
                            <span
                                :class="data.wechatStatus == 4 ? 'wechat-unable' : ''"
                                v-if="data.wechatStatus == 3 || data.wechatStatus == 4">
                                {{data.wechat || 'xxxxxx'}}
                            </span>
                        </div>
                    </template>
                    <template v-if="compareData.type == 1 && !isXlsAnonymous">
                        <div class="resume-filed">
                            <i class="icon-font icon-lianxigray"></i>
                        </div>
                        <div class="resume-filed">
                            <span>{{data.mobile || 'xxxxxxxxxxx'}}</span>
                        </div>
                        <div class="resume-filed email-resume-filed">
                            <i class="icon-font icon-et-envelope"></i>
                        </div>
                        <div class="resume-filed">
                            <span>{{data.email || 'xxx@xx.com'}}</span>
                        </div>
                        <br>
                        <div class="resume-filed wechat-resume-filed">
                            <i class="icon-font icon-wechat"></i>
                        </div>
                        <div class="resume-filed">
                            <span>{{data.wechat || 'xxxxxx'}}</span>
                        </div>
                    </template>
                </el-row>
                <div class="resume-tags">
                    <div class="custom-tag tag-fresher" v-show="compareData.isFresher"></div>
                    <div class="custom-tag tag-more-complete" v-show="compareData.moreComplete"></div>
                </div> 
                <div class="operate-box" 
                    v-if="compareData.type==1"
                    @click="baseInfoEdit">
                    <img src="@src/assets/images/candidateDetail/edit.png" alt="">
                    <span>编辑</span>
                </div>
            </el-row>

            <el-row class="content-block work">
                <p class="block-header"
                    :class="{'header-add': compareData.type==1}"
                    >
                    工作经历
                    <span class="add" @click="addItem('workExperiences')">
                        <img src="@src/assets/images/jobCreateMulti/ic_add.png" alt="">
                        <span>增加</span>
                    </span>
                </p>
                <el-row class="cell-wrap" :key="index" v-for="(item,index) in data.workExperiences">
                    <div class="block-title">
                        <div class="resume-filed">{{item.company}}</div>
                        <div class="resume-filed">{{item.title}}</div>
                    </div>
                    <div>
                        <div class="resume-filed">{{item.startDate | formatDateWithDot}}</div> -
                        <div class="resume-filed">{{item.endDate | formatDateWithDot}}</div>
                    </div>
                    <div class="operate">
                        <div class="operate-cell" v-if="compareData.type==0" @click="addToNewItem(item,index, 'workExperiences')">
                            <img src="@src/assets/images/jobCreateMulti/icon-excel-active.png" alt="">
                            <span>添加至新简历</span>
                        </div>
                        <template v-else>
                            <template v-if="index != editWorkExperiencesIndex">
                                <div class="operate-cell" 
                                    @click="onWorkExperiencesEdit(index)">
                                    <img src="@src/assets/images/candidateDetail/edit.png" alt="">
                                    <span>编辑</span>
                                </div>
                                <div class="operate-cell" 
                                    @click="deleteItem(index, 'workExperiences')">
                                    <img src="@src/assets/images/program/delete_icon.png" alt="">
                                    <span>删除</span>
                                </div>
                            </template>
                        </template>

                    </div>
                </el-row>
                <p v-if="!data.workExperiences || !data.workExperiences.length">无</p>
                <div class="form-wrap" v-if="compareData.type==1" v-show="editWorkExperiencesIndex >= 0">
                    <!-- @candidate-detail-refresh="candidatedetailrefresh" -->
                    <candidate-resume-work-form 
                        ref="candidateResumeWorkForm"
                        @resume-work-form-cancel="handleFormCancel"
                        @show-delete-dialog="showDeleteDialog"
                        @cache-select-compnay="cacheSelectCompnay"
                        :is-save-to-db="false"
                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'workExperiences')"

                        :candidate-id="candidateId"
                        :edit-url="editUrl"
                        :add-url="addUrl"
                    />
                </div>
            </el-row>

            <el-row class="content-block education">
                <p class="block-header"
                    :class="{'header-add': compareData.type==1}"
                    >教育经历
                    <span class="add" @click="addItem('educationExperiences')">
                        <img src="@src/assets/images/jobCreateMulti/ic_add.png" alt="">
                        <span>增加</span>
                    </span>
                </p>
                <el-row class="cell-wrap" :key="index" v-for="(item,index) in data.educationExperiences">
                    <div class="block-title">
                        <div class="resume-filed">{{item.school}}</div>
                        <div class="resume-filed">{{item.major}}</div>
                        <div class="resume-filed" v-if="item.degree">{{degreeMap[item.degree]}}</div>
                    </div>
                    <div>
                        <div class="resume-filed">{{item.startDate | formatDateWithDot}}</div> -
                        <div class="resume-filed">{{item.endDate | formatDateWithDot}}</div>
                    </div>
                    <div class="operate">
                        <div class="operate-cell" v-if="compareData.type==0" @click="addToNewItem(item,index, 'educationExperiences')">
                            <img src="@src/assets/images/jobCreateMulti/icon-excel-active.png" alt="">
                            <span>添加至新简历</span>
                        </div>
                        <template v-else>
                            <template v-if="index != editEducationExperiencesIndex">
                                <div class="operate-cell" @click="onEducationExperiencesEdit(index)">
                                    <img src="@src/assets/images/candidateDetail/edit.png" alt="">
                                    <span>编辑</span>
                                </div>
                                <div class="operate-cell" @click="deleteItem(index, 'educationExperiences')">
                                    <img src="@src/assets/images/program/delete_icon.png" alt="">
                                    <span>删除</span>
                                </div>
                            </template>
                        </template>
                    </div>
                </el-row>
                <div class="form-wrap" v-if="compareData.type==1" v-show="editEducationExperiencesIndex >= 0">
                    <!-- @candidate-detail-refresh="candidatedetailrefresh" -->
                    <candidate-resume-education-form
                        ref="candidateResumeEducationForm"
                        @resume-education-form-cancel="handleFormCancel"
                        :is-save-to-db="false"
                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'educationExperiences')"
                    />
                </div>
                <p v-if="!data.educationExperiences || !data.educationExperiences.length">无</p>
            </el-row>

            <el-row class="content-block project">
                <p class="block-header"
                    :class="{'header-add': compareData.type==1}">
                    项目经验
                    <span class="add" @click="addItem('projectExperiences')">
                        <img src="@src/assets/images/jobCreateMulti/ic_add.png" alt="">
                        <span>增加</span>
                    </span>
                </p>
                <el-row class="cell-wrap" :key="index" v-for="(item,index) in data.projectExperiences">
                    <div class="block-title">
                        <div class="resume-filed">{{item.projectName}}</div>
                        <div class="resume-filed">{{item.projectRole}}</div>
                    </div>
                    <div>
                        <div class="resume-filed">{{item.startDate | formatDateWithDot}}</div> -
                        <div class="resume-filed">{{item.endDate | formatDateWithDot}}</div>
                    </div>
                    <div class="operate">
                        <div class="operate-cell" v-if="compareData.type==0" @click="addToNewItem(item,index, 'projectExperiences')">
                            <img src="@src/assets/images/jobCreateMulti/icon-excel-active.png" alt="">
                            <span>添加至新简历</span>
                        </div>
                        <template v-else>
                            <template v-if="index != editProjectExperiencesIndex">
                                <div class="operate-cell" @click="onProjectExperiencesEdit(index)">
                                    <img src="@src/assets/images/candidateDetail/edit.png" alt="">
                                    <span>编辑</span>
                                </div>
                                <div class="operate-cell" @click="deleteItem(index, 'projectExperiences')">
                                    <img src="@src/assets/images/program/delete_icon.png" alt="">
                                    <span>删除</span>
                                </div>
                            </template>
                        </template>
                    </div>
                </el-row>
                <div class="form-wrap" v-if="compareData.type==1" v-show="editProjectExperiencesIndex >= 0">
                    <candidate-resume-project-form
                        ref="candidateResumeProjectForm"
                        @resume-project-form-cancel="handleFormCancel"
                        :is-save-to-db="false"
                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'projectExperiences')"
                    />
                </div>
                <p v-if="!data.projectExperiences || !data.projectExperiences.length">无</p>
            </el-row>

            <el-row class="content-block evaluation">
                <p class="block-header" 
                    :class="{'header-add': compareData.type==1}">自我评价
                    <span class="add" @click="addItem('selfEvaluation')" v-if="!data.selfEvaluation">
                        <img src="@src/assets/images/jobCreateMulti/ic_add.png" alt="">
                        <span>增加</span>
                    </span>
                </p>
                <el-row class="cell-wrap" v-if="data.selfEvaluation">
                    <p>{{data.selfEvaluation}}</p>
                    <div class="operate">
                        <div class="operate-cell" v-if="compareData.type==0" @click="addToNewItem(data.selfEvaluation,0, 'selfEvaluation')">
                            <img src="@src/assets/images/jobCreateMulti/icon-excel-active.png" alt="">
                            <span>添加至新简历</span>
                        </div>
                        <template v-else>
                            <!-- v-if="index != editSelfEvaluationIndex" -->
                            <template >
                                <div class="operate-cell" @click="onEvaluationEdit(0)">
                                    <img src="@src/assets/images/candidateDetail/edit.png" alt="">
                                    <span>编辑</span>
                                </div>
                                <!-- <div class="operate-cell" @click="deleteItem(index, 'selfEvaluation')">
                                    <img src="@src/assets/images/program/delete_icon.png" alt="">
                                    <span>删除</span>
                                </div> -->
                            </template>
                        </template>
                    </div>
                </el-row>
                <p v-else>无</p>

                <div class="form-wrap" v-if="compareData.type==1"  v-show="editSelfEvaluationIndex >= 0">
                    <candidate-resume-evaluation-form
                        ref="candidateResumeEvaluationForm"
                        @resume-evaluation-form-cancel="handleFormCancel"

                        :is-save-to-db="false"
                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'selfEvaluation')"
                    />
                </div>
            </el-row>

            <el-row class="content-block intention">
                <p class="block-header" 
                    :class="{'header-add': compareData.type==1}">期望工作
                    <span class="add" @click="addItem('intention')">
                        <img src="@src/assets/images/jobCreateMulti/ic_add.png" alt="">
                        <span>增加</span>
                    </span>
                </p>
                <el-row class="cell-wrap" :key="index" v-for="(item,index) in data.intentions">
                    <div class="resume-filed">{{provinceCityMap[item.locationId]}}</div>
                    <div class="resume-filed">{{item.title}}</div>
                    <div class="resume-filed">
                        <span>{{item.minSalary}}k</span> -
                        <span>{{item.maxSalary}}k</span>
                    </div>
                    <div class="operate">
                        <div class="operate-cell" v-if="compareData.type==0" @click="addToNewItem(item,index, 'intentions')">
                            <img src="@src/assets/images/jobCreateMulti/icon-excel-active.png" alt="">
                            <span>添加至新简历</span>
                        </div>
                        <template v-else>
                            <template v-if="index != editIntentionsIndex">
                                <div class="operate-cell" @click="onIntentionEdit(index)">
                                    <img src="@src/assets/images/candidateDetail/edit.png" alt="">
                                    <span>编辑</span>
                                </div>
                                <div class="operate-cell" @click="deleteItem(index, 'intentions')">
                                    <img src="@src/assets/images/program/delete_icon.png" alt="">
                                    <span>删除</span>
                                </div>
                            </template>
                        </template>
                    </div>
                </el-row>
                <div class="form-wrap" v-if="compareData.type==1" v-show="editIntentionsIndex >= 0">
                    <candidate-resume-intention-form
                        ref="candidateResumeIntentionForm"
                        @resume-intention-form-cancel="handleFormCancel"
                        :is-save-to-db="false"
                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'intentions')"
                    />
                </div>
                <p v-if="!data.intentions || !data.intentions.length">无</p>
            </el-row>
        </el-row>
        <base-info-edit-dialog
            ref="baseInfoEditDialog"
            :is-save-to-db="false"
            :isRecommend="true"
            @save-btn-callback="handleBaseInfoSave"
        ></base-info-edit-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    // import { request } from '@/utils/api.js';
    // import '@/utils/filters';
    // import provinceCityMap from '@/statistic/provinceCity.json';
    import provinceCityMap from '#/js/config/provinceCity.json';

    // import CandidateResumeWorkForm from '../component/candidate-resume-work-form/index';

    // import {
    //     Row,
    //     Col,
    //     Tooltip,
    //     Loading
    // } from "element-ui";
    
    // Vue.component(Row.name, Row);
    // Vue.component(Col.name, Col);
    // Vue.component(Tooltip.name, Tooltip);
    import CandidateResumeWorkForm from '../coc-update/components/candidate-resume-work-form/index.vue';
    import CandidateResumeEducationForm from '../coc-update/components/candidate-resume-education-form/index';
    import CandidateResumeProjectForm from '../coc-update/components/candidate-resume-project-form/index';
    import CandidateResumeEvaluationForm from '../coc-update/components/candidate-resume-evaluation-form/index';
    import CandidateResumeIntentionForm from '../coc-update/components/candidate-resume-intention-form/index';

    import BaseInfoEditDialog from '#/component/common/dialog/baseInfoEditDialog.vue';
    export default {
        name: 'candidateRawDetail',
        props: {
            data: {
                type:Object,
                default: {}
            },
            compareData: {
                type: Object,
                default: {}
            },
            isXlsAnonymous: {
                type: Boolean,
                default: false
            }
        },
        components: {
            CandidateResumeWorkForm,
            CandidateResumeEducationForm,
            CandidateResumeProjectForm,
            CandidateResumeEvaluationForm,
            CandidateResumeIntentionForm,

            BaseInfoEditDialog,
        },
        computed:{},
        data() {
            return {
                genderMap: ['未知','男','女'],
                degreeMap: ['无',"高中及以下","大专","本科","硕士","MBA","博士"],
                provinceCityMap,

                editWorkExperiencesIndex: -1,
                editIntentionsIndex: -1,
                editEducationExperiencesIndex: -1,
                editProjectExperiencesIndex: -1,
                editSelfEvaluationIndex: -1,


                candidateId: '',
                editUrl: '',
                addUrl: '',

            }
        },
        methods: {
            applyView(type) {
                let _self = this,
                    url = `/My/Candidate/${this.data.id}/LogViewContact`,
                    data = {
                        type: 0,
                        __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                    };
                if(type === 'mobile') {
                    data.type = 1;
                } else if(type === 'email'){
                    data.type = 2;
                } else if(type === 'wechat'){
                    data.type = 3;
                }
                // request(url, data, "post", true, true).then(res => {
                 _request({
                    method: 'POST',
                    url: url,
                    data: data,
                })
                .then(res => {
                    _self.data[type] = res.contact;
                    _self.data[type + 'Status'] = res.isValid ? 3 : 4;
                })
                .catch(err => {
                    if(err.message || err.errorMeaasge) {
                        shortTip(err.message || err.errorMeaasge)
                    } else {
                        shortTip('系统异常，请稍后重试')
                    }
                    console.log(err);
                }).finally(() => {
                    // loading finish
                });
            },
            
            addToNewItem(item, index, type) {
                this.$emit('addToNewItem', item, type);
            },

            addItem(type) {
                this.handleFormCancel();
                switch(type) {
                    case 'workExperiences':
                        this.$refs.candidateResumeWorkForm._reset();
                        this.editWorkExperiencesIndex = 10000;
                        this.goAnchor('#candidate-resume-work-form');
                        break;
                    case 'educationExperiences':
                        this.$refs.candidateResumeEducationForm._reset();
                        this.editEducationExperiencesIndex = 10000;
                        this.goAnchor('#candidate-resume-education-form');
                        break;
                    case 'projectExperiences':
                        this.$refs.candidateResumeProjectForm._reset();
                        this.editProjectExperiencesIndex = 10000;
                        this.goAnchor('#candidate-resume-project-form');
                        break;
                    case 'selfEvaluation':
                        this.$refs.candidateResumeEvaluationForm._reset();
                        this.editSelfEvaluationIndex = 10000;
                        this.goAnchor('#candidate-resume-evaluation-form');
                        break;
                    case 'intention':
                        this.$refs.candidateResumeIntentionForm._reset();
                        this.editIntentionsIndex = 10000;
                        this.goAnchor('#candidate-resume-intention-form');
                        break;
                    default: 
                        break;
                }
            },

            goAnchor(selector){
                this.$nextTick(() => {
                    let anchor = this.$el.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'center' });
                })
            },

            onWorkExperiencesEdit(index,ev){
                this.handleFormCancel();
                
                // 将最近一份工作的部门参数传入
                let customerDepartmentId, customerDepartmentName;
                // 当前工作传入部门名和id
                if(this.data.company == this.data.workExperiences[index].company) {
                    customerDepartmentId = this.data.customerDepartmentId;
                    customerDepartmentName = this.data.customerDepartmentName;
                } else {
                    customerDepartmentId = 0;
                    customerDepartmentName = '';
                }

                this.editWorkExperiencesIndex = index;
                this.$refs.candidateResumeWorkForm.mergeFormData(Object.assign(
                    this.data.workExperiences[index], 
                    {
                    customerDepartmentId: customerDepartmentId,
                    customerDepartmentName: customerDepartmentName,
                    customerId: this.data.company==this.data.workExperiences[index].company ? this.data.customerId: null,
                    currentCompany: this.data.company,
                    tempCustomerId: this.data.workExperiences[index].tempCustomerId || null,
                    }));

                this.goAnchor('#candidate-resume-work-form');
            },
            onEducationExperiencesEdit(index,ev){
                this.handleFormCancel();

                this.editEducationExperiencesIndex = index;
                this.$refs.candidateResumeEducationForm.mergeFormData(this.data.educationExperiences[index]);
                this.goAnchor('#candidate-resume-education-form');
            },
            onProjectExperiencesEdit(index,ev){
                this.handleFormCancel();

                this.editProjectExperiencesIndex = index;
                this.$refs.candidateResumeProjectForm.mergeFormData(this.data.projectExperiences[index]);
                this.goAnchor('#candidate-resume-project-form');
            },
            onEvaluationEdit(vm,ev){
                this.handleFormCancel();

                this.editSelfEvaluationIndex = 0;
                this.$refs.candidateResumeEvaluationForm.mergeFormData(this.data.selfEvaluation);

                this.goAnchor('#candidate-resume-evaluation-form');
            },

            onIntentionEdit(index,ev){
                this.handleFormCancel();

                this.editIntentionsIndex = index;
                this.$refs.candidateResumeIntentionForm.mergeFormData(this.data.intentions[index]);

                this.goAnchor('#candidate-resume-intention-form');
            },

            candidatedetailrefresh() {
                this.editWorkExperiencesIndex = -1;
            },

            handleFormCancel() {
                this.editWorkExperiencesIndex = -1;

                this.editIntentionsIndex = -1;
                this.editEducationExperiencesIndex= -1;
                // editWorkExperiencesIndex
                // editWorkExperiencesIndex
                this.editProjectExperiencesIndex= -1;
                this.editSelfEvaluationIndex= -1;
            },
            showDeleteDialog() {

            },

            cacheSelectCompnay() {

            },

            deleteItem(index, type) {
                this.$emit('deleteItem', index, type);
            },

            handleSaveBtnCallback(val, type) {
                console.log(arguments)

                const _name = type.slice(0,1).toUpperCase() + type.slice(1);
                const idx = this.$data[`edit${_name}Index`];

                this.$emit('editItem', val, idx, type);

                setTimeout(() => {
                    this.handleFormCancel();
                }, 300);
            },

            baseInfoEdit() {
                this.$refs.baseInfoEditDialog.show({
                    ...this.data,
                    degree: +this.data.degree,
                    mobileStatus: 3,
                    emailStatus: 3,
                    wechatStatus: 3,
                    isFirmResume: false,

                    openFrom: 'compare',
                });
            },
            handleBaseInfoSave(params) {
                this.$emit('baseInfoEditCallback', params);
            },
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss" scoped>
    .raw-detail-main {
        .content-header {
            color: #999;
            // background:#eeeeee;
            height:50px;
            margin-bottom: 0;
            border-bottom: 1px solid #eee;

            .content-block {
                padding: 15px 20px;
                border-bottom:none!important;

                h3{
                    color: #000;
                    display: inline-block;
                    font-size: 16px;
                }

                a{
                    color: #38bc9c;

                    &:hover {
                        color: #7dceb8;
                    }
                    
                    &.view {
                        margin-left: 10px;
                    }
                }

                .text-xs{
                    font-size: 12px;
                }

                .icon-font{
                    color: #ccc;
                }
            }
        }

        .content-main {
            color: #666;
            width: 100%;

            .v-line:before {
                content: '|';
                color: #ccc;
                // margin-right: 5px;
                margin: 0 10px;
            }
            .v-line-span{
                // width: 85%;
                text-align: center;
                display: inline-block;
            }
            .resume-filed {
                display: inline-block;
                margin: 0 3px;
                &.job-resume-filed {
                    margin-left: 20px;
                }
                &.email-resume-filed {
                    margin-left: 30px;
                }
            }

            .info-block-btn.el-button {
                height: 20px;
                line-height: 20px;
                border: none;
            }
            .mobile-unable, .email-unable, .wechat-unable {
                text-decoration: line-through;
            }

            .info-lock-btn {
                width: 100px;
                height: 20px;
                line-height: 20px;
                border-radius: 4px;
                margin-right: 10px;
                color: #fff;
                display: inline-block;
                text-align: center;
                background-color: #38bc9c;
                cursor: default;
                .icon-font {
                    color: #fff;
                }
            }

            .content-block {
                padding: 15px 20px;
                border-bottom:1px solid #eeeeee;

                .el-row {
                    min-height: 32px;
                    line-height: 32px;  
                    padding: 0 0 10px 30px;
                    margin-bottom: -10px;
                    background: url('~@src/assets/images/p-line.png') no-repeat;
                    background-position-y: 10px;
                    padding-right: 120px;
                    box-sizing: border-box;
                }
                .form-wrap{
                    overflow: hidden;
                }
                .cell-wrap{
                    position: relative;
                    &:hover{
                        background: #F9F9F9 url('~@src/assets/images/p-line.png') no-repeat;
                        background-position-y: 10px;
                        .operate{
                            display: block;
                        }
                    }
                    .operate{
                        display: none;
                        color: $primary;
                        width: 160px;
                        position: absolute;
                        right: 2px;
                        top: 2px;
                        z-index: 10;
                        text-align: right;
                        .operate-cell{
                            display: inline-block;
                            cursor: pointer;
                            margin-right: 8px;
                            // background: rgba(255, 255, 255, 0.8);
                            >img{
                                max-width: 16px;
                                max-height: 18px;
                                margin-right: 6px;
                            }
                            >span{
                                font-size: 14px;
                            }
                        }
                        
                    }
                }

                .block-header {
                    font-size: 18px;
                    color: #000;
                    height: 30px;
                    line-height: 30px;
                    .add{
                        display: none;
                    }
                }
                .header-add{
                    position: relative;
                    height: 30px;
                    line-height: 30px;
                    &:hover{
                        background: #F9F9F9;
                        .add{
                            display: block;
                        }
                    }
                    .add{
                        position: absolute;
                        z-index: 10;
                        right: 10px;
                        top: 0;
                        // display: inline-block;
                        cursor: pointer;
                        display: none;
                        >img{
                            width: 18px;
                            height: 18px;
                            margin-right: 6px;
                        }
                        >span{
                            font-size: 14px;
                            color: $primary;
                        }
                    }
                }

                .block-title {
                    color: #000;
                }
            }

            .base-info {
                .high-line {
                    height: 43px;
                    line-height: 43px;
                    display: flex;
                }

                .real-name {
                    max-width: 20%;
                    font-size:24px;
                    color:#000;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-right: 30px;
                }
                .some-info {
                    display: flex;
                }

                .el-row {
                    background: unset;
                    padding:0 0;
                    margin-bottom: 0;
                }

                .address-info {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                &:hover{
                    .operate-box{
                        display: inline-block;
                    }
                }

                .operate-box{
                    display: none;
                    position: absolute;
                    z-index: 10;
                    right: 20px;
                    bottom: 10px;
                    // display: inline-block;
                    cursor: pointer;
                    margin-right: 8px;
                    // background: rgba(255, 255, 255, 0.8);
                    >img{
                        max-width: 16px;
                        max-height: 18px;
                        margin-right: 6px;
                    }
                    >span{
                        font-size: 14px;
                    }
                }
            }
            
        }

        .resume-tags {
            right: 30px;
            top: 0;
            position: absolute;
            padding: 0 0 20px 20px;
            overflow: hidden;

            .custom-tag {
                width: 100px;
                height: 50px;
                transform: rotateZ(-20deg);
            }

            .tag-fresher {
                background: url('~@src/assets/images/fresher.png') no-repeat;
            }

            .tag-more-complete {
                background: url('~@src/assets/images/moreComplete.png') no-repeat;
            }
        }
    }
</style>
<style lang="scss">
    .el-loading-mask.view-button-loading {
        .el-loading-spinner {
            top: 0;
            margin-top: 0;
            .circular {
                width: 20px;
                height: 20px;
            }
        }
    }
    .concat-tooltip.el-tooltip__popper.is-light {
        box-shadow: 0 0 10px #aaa;
        border: none;
        color: #666;
    }
</style>
