var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "content-header" },
        [
          _vm.compareData.type == 0
            ? _c(
                "el-row",
                { staticClass: "content-block" },
                [
                  _c(
                    "el-col",
                    { staticClass: "pull-left", attrs: { span: 12 } },
                    [
                      _c("h3", [_vm._v("原简历")]),
                      !!_vm.data.id
                        ? _c(
                            "a",
                            {
                              staticClass: "view",
                              attrs: {
                                href: "/#/candidateDetail/" + _vm.data.id,
                                target: "_blank",
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.data.updateDate
                    ? _c(
                        "el-col",
                        {
                          staticClass: "pull-right text-xs",
                          attrs: { span: 9 },
                        },
                        [
                          _vm._v(
                            "\n                最后编辑时间: " +
                              _vm._s(
                                _vm._f("formatDateWithDot")(_vm.data.updateDate)
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "el-row",
                { staticClass: "content-block" },
                [
                  _c(
                    "el-col",
                    { staticClass: "pull-left", attrs: { span: 12 } },
                    [_c("h3", [_vm._v("你上传的简历")])]
                  ),
                  _vm.data.updateDate
                    ? _c(
                        "el-col",
                        {
                          staticClass: "pull-right text-xs",
                          attrs: { span: 9 },
                        },
                        [
                          _vm._v(
                            "\n                文档最后编辑时间: " +
                              _vm._s(
                                _vm._f("formatDateWithDot")(_vm.data.updateDate)
                              ) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "content-main" },
        [
          _c(
            "el-row",
            { staticClass: "content-block base-info" },
            [
              _c("div", { staticClass: "high-line" }, [
                _c(
                  "div",
                  {
                    staticClass: "real-name",
                    attrs: { title: _vm.data.realName },
                  },
                  [_vm._v(_vm._s(_vm.data.realName))]
                ),
                _c(
                  "div",
                  { staticClass: "some-info" },
                  [
                    _vm.data.gender != null
                      ? _c("div", [
                          _vm._v(_vm._s(_vm.genderMap[_vm.data.gender])),
                        ])
                      : _vm._e(),
                    _vm.data.yearOfExperience
                      ? [
                          _c("div", { staticClass: "v-line" }, [
                            _c("span", { staticClass: "v-line-span" }, [
                              _vm._v(
                                _vm._s(_vm.data.yearOfExperience) + "年经验"
                              ),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.data.city
                      ? [
                          _c(
                            "div",
                            {
                              staticClass: "v-line address-info",
                              attrs: {
                                title: _vm.provinceCityMap[_vm.data.city],
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.provinceCityMap[_vm.data.city]) +
                                  "\n                        "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("el-row", [
                _c("div", { staticClass: "resume-filed" }, [
                  _c("i", { staticClass: "icon-font icon-briefcase" }),
                ]),
                _c("div", { staticClass: "resume-filed" }, [
                  _vm._v(_vm._s(_vm.data.company)),
                ]),
                _c("div", { staticClass: "resume-filed job-resume-filed" }, [
                  _vm._v(_vm._s(_vm.data.title)),
                ]),
              ]),
              _c("el-row", [
                _c("div", { staticClass: "resume-filed" }, [
                  _c("i", { staticClass: "icon-font icon-boshimao" }),
                ]),
                _c("div", { staticClass: "resume-filed" }, [
                  _vm._v(_vm._s(_vm.data.school)),
                ]),
                _c("div", { staticClass: "resume-filed" }, [
                  _vm._v(_vm._s(_vm.data.major)),
                ]),
                _vm.data.degree
                  ? _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(_vm.degreeMap[_vm.data.degree])),
                    ])
                  : _vm._e(),
              ]),
              _vm.compareData.type == 0 ||
              (_vm.compareData.type == 1 && !_vm.isXlsAnonymous)
                ? _c(
                    "el-row",
                    [
                      _vm.compareData.type == 0
                        ? [
                            _c("div", { staticClass: "resume-filed" }, [
                              _c("i", {
                                staticClass: "icon-font icon-lianxigray",
                              }),
                            ]),
                            _c(
                              "div",
                              { staticClass: "resume-filed" },
                              [
                                _vm.data.mobileStatus == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        ref: "mobileViewButton",
                                        staticClass: "info-block-btn",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.applyView("mobile")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            查看手机\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.data.mobileStatus == 2
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          effect: "light",
                                          "popper-class": "concat-tooltip",
                                          "visible-arrow": false,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                候选人正在入职保证期中，暂不能查看其联"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "系方式\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "info-lock-btn" },
                                          [
                                            _vm._v(
                                              "\n                                查看手机\n                                "
                                            ),
                                            _c("i", {
                                              staticClass: "icon-font icon-suo",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.data.mobileStatus == 3 ||
                                _vm.data.mobileStatus == 4
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          _vm.data.mobileStatus == 4
                                            ? "mobile-unable"
                                            : "",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.data.mobile || "xxxxxxxxxxx"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "resume-filed email-resume-filed",
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-et-envelope",
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "resume-filed" },
                              [
                                _vm.data.emailStatus == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        ref: "emailViewButton",
                                        staticClass: "info-block-btn",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.applyView("email")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            查看邮箱\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.data.emailStatus == 2
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          effect: "light",
                                          "popper-class": "concat-tooltip",
                                          "visible-arrow": false,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                候选人正在入职保证期中，暂不能查看其联"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "系方式\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "info-lock-btn" },
                                          [
                                            _vm._v(
                                              "\n                                查看邮箱\n                                "
                                            ),
                                            _c("i", {
                                              staticClass: "icon-font icon-suo",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.data.emailStatus == 3 ||
                                _vm.data.emailStatus == 4
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          _vm.data.emailStatus == 4
                                            ? "email-unable"
                                            : "",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.data.email || "xxx@xx.com"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("br"),
                            _c(
                              "div",
                              {
                                staticClass: "resume-filed wechat-resume-filed",
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-wechat",
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "resume-filed" },
                              [
                                _vm.data.wechatStatus == 1
                                  ? _c(
                                      "el-button",
                                      {
                                        ref: "wechatViewButton",
                                        staticClass: "info-block-btn",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.applyView("wechat")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            查看微信\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.data.wechatStatus == 2
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          effect: "light",
                                          "popper-class": "concat-tooltip",
                                          "visible-arrow": false,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "content" },
                                            slot: "content",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                候选人正在入职保证期中，暂不能查看其联"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "系方式\n                            "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "info-lock-btn" },
                                          [
                                            _vm._v(
                                              "\n                                查看微信\n                                "
                                            ),
                                            _c("i", {
                                              staticClass: "icon-font icon-suo",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.data.wechatStatus == 3 ||
                                _vm.data.wechatStatus == 4
                                  ? _c(
                                      "span",
                                      {
                                        class:
                                          _vm.data.wechatStatus == 4
                                            ? "wechat-unable"
                                            : "",
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.data.wechat || "xxxxxx"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.compareData.type == 1 && !_vm.isXlsAnonymous
                        ? [
                            _c("div", { staticClass: "resume-filed" }, [
                              _c("i", {
                                staticClass: "icon-font icon-lianxigray",
                              }),
                            ]),
                            _c("div", { staticClass: "resume-filed" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.data.mobile || "xxxxxxxxxxx")
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "resume-filed email-resume-filed",
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-et-envelope",
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "resume-filed" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.data.email || "xxx@xx.com")),
                              ]),
                            ]),
                            _c("br"),
                            _c(
                              "div",
                              {
                                staticClass: "resume-filed wechat-resume-filed",
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-font icon-wechat",
                                }),
                              ]
                            ),
                            _c("div", { staticClass: "resume-filed" }, [
                              _c("span", [
                                _vm._v(_vm._s(_vm.data.wechat || "xxxxxx")),
                              ]),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c("div", { staticClass: "resume-tags" }, [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.compareData.isFresher,
                      expression: "compareData.isFresher",
                    },
                  ],
                  staticClass: "custom-tag tag-fresher",
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.compareData.moreComplete,
                      expression: "compareData.moreComplete",
                    },
                  ],
                  staticClass: "custom-tag tag-more-complete",
                }),
              ]),
              _vm.compareData.type == 1
                ? _c(
                    "div",
                    {
                      staticClass: "operate-box",
                      on: { click: _vm.baseInfoEdit },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/candidateDetail/edit.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("编辑")]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "content-block work" },
            [
              _c(
                "p",
                {
                  staticClass: "block-header",
                  class: { "header-add": _vm.compareData.type == 1 },
                },
                [
                  _vm._v("\n                工作经历\n                "),
                  _c(
                    "span",
                    {
                      staticClass: "add",
                      on: {
                        click: function ($event) {
                          return _vm.addItem("workExperiences")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobCreateMulti/ic_add.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("增加")]),
                    ]
                  ),
                ]
              ),
              _vm._l(_vm.data.workExperiences, function (item, index) {
                return _c("el-row", { key: index, staticClass: "cell-wrap" }, [
                  _c("div", { staticClass: "block-title" }, [
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(item.company)),
                    ]),
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateWithDot")(item.startDate))
                      ),
                    ]),
                    _vm._v(" -\n                    "),
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(_vm._f("formatDateWithDot")(item.endDate))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "operate" },
                    [
                      _vm.compareData.type == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "operate-cell",
                              on: {
                                click: function ($event) {
                                  return _vm.addToNewItem(
                                    item,
                                    index,
                                    "workExperiences"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/jobCreateMulti/icon-excel-active.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("添加至新简历")]),
                            ]
                          )
                        : [
                            index != _vm.editWorkExperiencesIndex
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onWorkExperiencesEdit(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/candidateDetail/edit.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(
                                            index,
                                            "workExperiences"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/program/delete_icon.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("删除")]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ])
              }),
              !_vm.data.workExperiences || !_vm.data.workExperiences.length
                ? _c("p", [_vm._v("无")])
                : _vm._e(),
              _vm.compareData.type == 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editWorkExperiencesIndex >= 0,
                          expression: "editWorkExperiencesIndex >= 0",
                        },
                      ],
                      staticClass: "form-wrap",
                    },
                    [
                      _c("candidate-resume-work-form", {
                        ref: "candidateResumeWorkForm",
                        attrs: {
                          "is-save-to-db": false,
                          "candidate-id": _vm.candidateId,
                          "edit-url": _vm.editUrl,
                          "add-url": _vm.addUrl,
                        },
                        on: {
                          "resume-work-form-cancel": _vm.handleFormCancel,
                          "show-delete-dialog": _vm.showDeleteDialog,
                          "cache-select-compnay": _vm.cacheSelectCompnay,
                          "save-btn-callback": (val) =>
                            _vm.handleSaveBtnCallback(val, "workExperiences"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-row",
            { staticClass: "content-block education" },
            [
              _c(
                "p",
                {
                  staticClass: "block-header",
                  class: { "header-add": _vm.compareData.type == 1 },
                },
                [
                  _vm._v("教育经历\n                "),
                  _c(
                    "span",
                    {
                      staticClass: "add",
                      on: {
                        click: function ($event) {
                          return _vm.addItem("educationExperiences")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobCreateMulti/ic_add.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("增加")]),
                    ]
                  ),
                ]
              ),
              _vm._l(_vm.data.educationExperiences, function (item, index) {
                return _c("el-row", { key: index, staticClass: "cell-wrap" }, [
                  _c("div", { staticClass: "block-title" }, [
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(item.school)),
                    ]),
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(item.major)),
                    ]),
                    item.degree
                      ? _c("div", { staticClass: "resume-filed" }, [
                          _vm._v(_vm._s(_vm.degreeMap[item.degree])),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateWithDot")(item.startDate))
                      ),
                    ]),
                    _vm._v(" -\n                    "),
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(_vm._f("formatDateWithDot")(item.endDate))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "operate" },
                    [
                      _vm.compareData.type == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "operate-cell",
                              on: {
                                click: function ($event) {
                                  return _vm.addToNewItem(
                                    item,
                                    index,
                                    "educationExperiences"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/jobCreateMulti/icon-excel-active.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("添加至新简历")]),
                            ]
                          )
                        : [
                            index != _vm.editEducationExperiencesIndex
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onEducationExperiencesEdit(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/candidateDetail/edit.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(
                                            index,
                                            "educationExperiences"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/program/delete_icon.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("删除")]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ])
              }),
              _vm.compareData.type == 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editEducationExperiencesIndex >= 0,
                          expression: "editEducationExperiencesIndex >= 0",
                        },
                      ],
                      staticClass: "form-wrap",
                    },
                    [
                      _c("candidate-resume-education-form", {
                        ref: "candidateResumeEducationForm",
                        attrs: { "is-save-to-db": false },
                        on: {
                          "resume-education-form-cancel": _vm.handleFormCancel,
                          "save-btn-callback": (val) =>
                            _vm.handleSaveBtnCallback(
                              val,
                              "educationExperiences"
                            ),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.data.educationExperiences ||
              !_vm.data.educationExperiences.length
                ? _c("p", [_vm._v("无")])
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-row",
            { staticClass: "content-block project" },
            [
              _c(
                "p",
                {
                  staticClass: "block-header",
                  class: { "header-add": _vm.compareData.type == 1 },
                },
                [
                  _vm._v("\n                项目经验\n                "),
                  _c(
                    "span",
                    {
                      staticClass: "add",
                      on: {
                        click: function ($event) {
                          return _vm.addItem("projectExperiences")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobCreateMulti/ic_add.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("增加")]),
                    ]
                  ),
                ]
              ),
              _vm._l(_vm.data.projectExperiences, function (item, index) {
                return _c("el-row", { key: index, staticClass: "cell-wrap" }, [
                  _c("div", { staticClass: "block-title" }, [
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(item.projectName)),
                    ]),
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(item.projectRole)),
                    ]),
                  ]),
                  _c("div", [
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateWithDot")(item.startDate))
                      ),
                    ]),
                    _vm._v(" -\n                    "),
                    _c("div", { staticClass: "resume-filed" }, [
                      _vm._v(_vm._s(_vm._f("formatDateWithDot")(item.endDate))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "operate" },
                    [
                      _vm.compareData.type == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "operate-cell",
                              on: {
                                click: function ($event) {
                                  return _vm.addToNewItem(
                                    item,
                                    index,
                                    "projectExperiences"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/jobCreateMulti/icon-excel-active.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("添加至新简历")]),
                            ]
                          )
                        : [
                            index != _vm.editProjectExperiencesIndex
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onProjectExperiencesEdit(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/candidateDetail/edit.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(
                                            index,
                                            "projectExperiences"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/program/delete_icon.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("删除")]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ])
              }),
              _vm.compareData.type == 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editProjectExperiencesIndex >= 0,
                          expression: "editProjectExperiencesIndex >= 0",
                        },
                      ],
                      staticClass: "form-wrap",
                    },
                    [
                      _c("candidate-resume-project-form", {
                        ref: "candidateResumeProjectForm",
                        attrs: { "is-save-to-db": false },
                        on: {
                          "resume-project-form-cancel": _vm.handleFormCancel,
                          "save-btn-callback": (val) =>
                            _vm.handleSaveBtnCallback(
                              val,
                              "projectExperiences"
                            ),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.data.projectExperiences ||
              !_vm.data.projectExperiences.length
                ? _c("p", [_vm._v("无")])
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-row",
            { staticClass: "content-block evaluation" },
            [
              _c(
                "p",
                {
                  staticClass: "block-header",
                  class: { "header-add": _vm.compareData.type == 1 },
                },
                [
                  _vm._v("自我评价\n                "),
                  !_vm.data.selfEvaluation
                    ? _c(
                        "span",
                        {
                          staticClass: "add",
                          on: {
                            click: function ($event) {
                              return _vm.addItem("selfEvaluation")
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@src/assets/images/jobCreateMulti/ic_add.png"),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v("增加")]),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm.data.selfEvaluation
                ? _c("el-row", { staticClass: "cell-wrap" }, [
                    _c("p", [_vm._v(_vm._s(_vm.data.selfEvaluation))]),
                    _c(
                      "div",
                      { staticClass: "operate" },
                      [
                        _vm.compareData.type == 0
                          ? _c(
                              "div",
                              {
                                staticClass: "operate-cell",
                                on: {
                                  click: function ($event) {
                                    return _vm.addToNewItem(
                                      _vm.data.selfEvaluation,
                                      0,
                                      "selfEvaluation"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@src/assets/images/jobCreateMulti/icon-excel-active.png"),
                                    alt: "",
                                  },
                                }),
                                _c("span", [_vm._v("添加至新简历")]),
                              ]
                            )
                          : [
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "operate-cell",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEvaluationEdit(0)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@src/assets/images/candidateDetail/edit.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("span", [_vm._v("编辑")]),
                                  ]
                                ),
                              ],
                            ],
                      ],
                      2
                    ),
                  ])
                : _c("p", [_vm._v("无")]),
              _vm.compareData.type == 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editSelfEvaluationIndex >= 0,
                          expression: "editSelfEvaluationIndex >= 0",
                        },
                      ],
                      staticClass: "form-wrap",
                    },
                    [
                      _c("candidate-resume-evaluation-form", {
                        ref: "candidateResumeEvaluationForm",
                        attrs: { "is-save-to-db": false },
                        on: {
                          "resume-evaluation-form-cancel": _vm.handleFormCancel,
                          "save-btn-callback": (val) =>
                            _vm.handleSaveBtnCallback(val, "selfEvaluation"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "content-block intention" },
            [
              _c(
                "p",
                {
                  staticClass: "block-header",
                  class: { "header-add": _vm.compareData.type == 1 },
                },
                [
                  _vm._v("期望工作\n                "),
                  _c(
                    "span",
                    {
                      staticClass: "add",
                      on: {
                        click: function ($event) {
                          return _vm.addItem("intention")
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@src/assets/images/jobCreateMulti/ic_add.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v("增加")]),
                    ]
                  ),
                ]
              ),
              _vm._l(_vm.data.intentions, function (item, index) {
                return _c("el-row", { key: index, staticClass: "cell-wrap" }, [
                  _c("div", { staticClass: "resume-filed" }, [
                    _vm._v(_vm._s(_vm.provinceCityMap[item.locationId])),
                  ]),
                  _c("div", { staticClass: "resume-filed" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", { staticClass: "resume-filed" }, [
                    _c("span", [_vm._v(_vm._s(item.minSalary) + "k")]),
                    _vm._v(" -\n                    "),
                    _c("span", [_vm._v(_vm._s(item.maxSalary) + "k")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "operate" },
                    [
                      _vm.compareData.type == 0
                        ? _c(
                            "div",
                            {
                              staticClass: "operate-cell",
                              on: {
                                click: function ($event) {
                                  return _vm.addToNewItem(
                                    item,
                                    index,
                                    "intentions"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@src/assets/images/jobCreateMulti/icon-excel-active.png"),
                                  alt: "",
                                },
                              }),
                              _c("span", [_vm._v("添加至新简历")]),
                            ]
                          )
                        : [
                            index != _vm.editIntentionsIndex
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.onIntentionEdit(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/candidateDetail/edit.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("编辑")]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "operate-cell",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteItem(
                                            index,
                                            "intentions"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@src/assets/images/program/delete_icon.png"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [_vm._v("删除")]),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                ])
              }),
              _vm.compareData.type == 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editIntentionsIndex >= 0,
                          expression: "editIntentionsIndex >= 0",
                        },
                      ],
                      staticClass: "form-wrap",
                    },
                    [
                      _c("candidate-resume-intention-form", {
                        ref: "candidateResumeIntentionForm",
                        attrs: { "is-save-to-db": false },
                        on: {
                          "resume-intention-form-cancel": _vm.handleFormCancel,
                          "save-btn-callback": (val) =>
                            _vm.handleSaveBtnCallback(val, "intentions"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.data.intentions || !_vm.data.intentions.length
                ? _c("p", [_vm._v("无")])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("base-info-edit-dialog", {
        ref: "baseInfoEditDialog",
        attrs: { "is-save-to-db": false, isRecommend: true },
        on: { "save-btn-callback": _vm.handleBaseInfoSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }