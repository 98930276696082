<template>
    <el-form 
        ref="compareResumeIntentionForm" 
        class="compare-resume-intention-form col-15"
        id="compare-resume-intention-form" 
        :model="formData"
        :rules="rules"
        label-width="80px"
        label-position="top"
    >
        <el-row>
            <el-col>
                <el-form-item label="期望职位" prop="title">
                    <el-input v-model="formData.title" maxlength="128"></el-input>
                </el-form-item>
            </el-col>
            <span class="space"></span>
            <el-col>
                <el-form-item label="期望城市" prop="locationId">
                    <city-select ref="citySelect" @citySelected="handleCitySelected"></city-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-form-item class="salary-item" label="薪资要求" prop="minSalary">
                    <!-- <el-input v-model="formData.minSalary" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input> -->
                    <el-input-number :controls="false" :precision="0" v-model="formData.minSalary"></el-input-number>
                    <span class="text-inline">K</span>
                </el-form-item>
            </el-col>
            <span class="space-lg">-</span>
            <el-col>
                <el-form-item class="salary-item" label=" " prop="maxSalary">
                    <!-- <el-input v-model="formData.maxSalary" onkeyup="value=(value.replace(/\D/g,'')==''?'':parseInt(value))"></el-input> -->
                    <el-input-number :controls="false" :precision="0" v-model="formData.maxSalary"></el-input-number>
                    <span class="text-inline">K</span>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item class="btn-items lg-btn-items">
            <el-button @click="_cancel">取消</el-button>
            <el-button type="primary" @click="submitForm('compareResumeIntentionForm')">保存</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { min as getMin, max as getMax, positiveInteger } from '#/js/util/validators.js';
    import CitySelect from '#/component/common/city-select.vue';

    let defaultFormObj = {
        "id": "",
        "title": "",
        "locationId": "",
        "minSalary": "",
        "maxSalary": ""
    }

    export default {
        components: {
            // Checkbox,
            CitySelect,
        },
        data () {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObj),
                type: resumeItemType.intentions,
                hasSetCityData: false,
                loading:false,
                rules: {
                    title: [
                        { required: true, message: '期望职位不能为空', trigger: ['blur', 'change'] },
                        { max: 128, message: '期望职位最长支持128个字符', trigger: ['blur', 'change'] },
                    ],
                    locationId: [
                        { required: true, message: '期望城市不能为空', trigger: ['blur', 'change'] },
                    ],
                    minSalary: [
                        { required: true, message: '薪资不能为空', trigger: ['blur', 'change'] },
                        { validator: this.validateMinSalary, trigger: ['blur', 'change'] }
                    ],
                    maxSalary: [
                        { validator: this.validateMaxSalary, trigger: ['blur', 'change'] }
                    ],
                },
            }
        },
        props: {
            canEdit: {
                type: Boolean,
                default: true
            },
            candidateId:String,
            editUrl:String,
            addUrl:String,
            isSaveToDb: {
                type: Boolean,
                default: true,
            },
            currentIntention: {
                type: Object,
                default: null
            }
        },
        mounted () {
            this.form = this.$refs.candidateResumeIntentionForm;
            this.citySelect = this.$refs.citySelect;

            this.initEvent();
        },
        methods: {
            initEvent(){
                if (this.currentIntention) {
                    Object.assign(this.formData, this.currentIntention);
                    this.citySelect.setDefaultCity(this.formData.locationId);
                }
            },
            validateMinSalary(rule, value, callback) {
                callback();
                if (this.formData.maxSalary) {
                    this.$refs['compareResumeIntentionForm'].validateField('maxSalary', valid => {});
                }
            },
            validateMaxSalary(rule, value, callback) {
                if (!value) {
                    callback(new Error("薪资不能为空"));
                }

                if (this.formData.minSalary > this.formData.maxSalary) {
                    callback(new Error("最大薪资不能小于最小薪资"));
                } else {
                    callback()
                }
            },
            _cancel () {
                if (this.currentIntention) {
                    this.citySelect.reset();
                    this.initEvent();
                } else {
                    this._reset();
                    this.$refs['compareResumeIntentionForm'].resetFields();
                }
                this.$emit('resume-intention-form-cancel');
            },

            _delete () {
                this._showConfirmDialog();
            },

            _reset () {
                this.formData = Object.assign({}, defaultFormObj);
                this.citySelect.reset();
            },

            _refresh () {
                this.$emit('candidate-detail-refresh')
            },

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this._submitCandidateEdit();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            // resetForm(formName) {
            //     this._reset();
            //     // this.$refs[formName].resetFields();
            // },

            _submitCandidateEdit () {
                let formData = this.formData;
                formData = this.trimValue(formData);

                let isEdit = formData.id ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                if(this.isSaveToDb) { 
                    _request({
                        url: postUrl,
                        method: 'POST',
                        data: {
                            id:this.candidateId,
                            type:this.type,
                            data:{
                                intention:formData
                            } 
                        }
                    }).then(res => {
                        this._cancel();
                        this._refresh();
                        shortTips(isEdit ? '编辑成功' : '添加成功');
                    }).finally(() =>{
                        this.loading=false;
                    });
                } else {
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.loading=false;

                    this.$emit('save-btn-callback', formData);
                }
                
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue (obj) {
                let newOjb = {};
                Object.keys(obj).forEach(key => {
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData (data) {
                if (data.id && data.isLatestWorkExperience) {
                    this.isCheckboxLocked = true;
                } else {
                    this.isCheckboxLocked = false;
                }

                Object.assign(this.formData, data);
                this.citySelect.setDefaultCity(this.formData.location);


                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? '': this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? '': this.formData.startDate;


                if(this.formData.tempCustomerId) {
                    this.formData.customerId = this.formData.tempCustomerId;
                }
                if(this.formData.customerId == '0') {
                    this.formData.customerId = null;
                }
                this.$nextTick(() => {
                    if(this.$refs.departmentSelect && this.formData.customerDepartmentId) {
                        this.$refs.departmentSelect.value = this.formData.customerDepartmentId;
                        this.$refs.departmentSelect.editInit({
                            id: this.formData.customerDepartmentId
                        });
                    }
                })
            },

            // initEvent () {
            //     let self = this;
            //     this.initAllDateRange();
            // },

            handleCitySelected(province, city) {
                this.formData.locationId = city;
            }
        }

    }
</script>

<style lang="scss" scoped>
    .compare-resume-intention-form{
        // display: none;
        background: #fff;
        // border: 1px solid #e6e6e6;
        // border-radius: 2px;
        // padding: 40px 20px;
        margin-top: 20px;
        margin-bottom: 12px;

        .space {
            display: inline-block;
            width: 28px;
            flex-shrink: 0;
        }
        .space-lg {
            position: relative;
            top: 26px;
            display: inline-block;
            width: 32px;
            height: 38px;
            line-height: 38px;
            color: #333;
            text-align: center;
            flex-shrink: 0;
        }

        .el-row {
            display: flex;
        }

        /deep/ .el-form-item {
            margin-bottom: 16px;

            &.is-success .el-input__inner {
                border: 0 none;
            }

            .el-form-item__error {
                white-space: nowrap;
            }
        }

        /deep/ .el-form-item__label {
            line-height: 20px;
            padding-bottom: 4px;
        }

        /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
            content: '';
            display: none;
        }

        /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
            content: '*';
            color: #F56C6C;
            margin-left: 4px;
        }

        /deep/ .el-form-item.end-date-item > .el-form-item__label:after {
            content: '*';
            color: #F56C6C;
            margin-left: 4px;
        }

        /deep/ .el-form-item__content .el-input__inner {
            height: 38px;
            line-height: 38px;
            border: 0 none;
            color: #333;
            background-color: #F6F6F6;
            border-radius: 3px;
        }

        /deep/ .el-form-item__content .el-textarea__inner {
            height: 208px;
            border: 0 none!important;
            color: #333;
            background-color: #F6F6F6;
            border-radius: 3px;
            resize: none;
        }

        /deep/ .el-date-editor {
            .el-input__inner {
                padding: 0 15px;
            }

            .el-input__prefix {
                display: none;
            }
        }

        /deep/ .salary-item .el-form-item__content {
            display: flex;
            justify-content: space-between;

            .text-inline {
                padding-left: 6px;
            }

            .el-input-number {
                width: 100%;
            }
        }

        .btn-items {
            margin-top: -10px;
            margin-bottom: 0;

            &.lg-btn-items {
                margin-top: 10px;
            }
            
            /deep/ .el-form-item__content {
                line-height: inherit;
                text-align: right;
            }
        }

        &:before,&:after{
            content: "";
            display: table;
            clear: both;
        }

        .last-experience-checkbox{
            margin-right: 10px;
            margin-bottom: 0;
            .el-checkbox__inner{
                width: 20px;
                height: 20px;
                &::after{
                    top: 4px;
                    left: 7px;
                    height: 10px;
                }
            }
            .is-disabled,.is-checked {
                .el-checkbox__inner{
                    border-color: #38bc9c;
                    background-color: #d3d3d3;
                    &::after{
                        border-color: #38bc9c;
                    }
                }
            }
        }
    }

    .department-tip{
        // display: block;
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin-top: 4px;
    }

    .compare-resume-intention-form {
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
