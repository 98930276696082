var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "compareResumeProjectForm",
      staticClass: "compare-resume-project-form col-15",
      attrs: {
        id: "compare-resume-project-form",
        model: _vm.formData,
        rules: _vm.rules,
        "label-width": "80px",
        "label-position": "top",
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "项目名称", prop: "projectName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "128" },
                    model: {
                      value: _vm.formData.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "projectName", $$v)
                      },
                      expression: "formData.projectName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space" }),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "项目角色", prop: "projectRole" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "128" },
                    model: {
                      value: _vm.formData.projectRole,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "projectRole", $$v)
                      },
                      expression: "formData.projectRole",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "开始时间", prop: "startDate" } },
                [
                  _c("el-date-picker", {
                    staticClass: "start-date-picker",
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "month",
                      placeholder: "如 2018-08",
                      "value-format": "yyyy-MM",
                      "picker-options": _vm.startDateOptions,
                    },
                    model: {
                      value: _vm.formData.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "startDate", $$v)
                      },
                      expression: "formData.startDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space-lg" }, [_vm._v("至")]),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "end-date-item",
                  attrs: { label: "结束时间", prop: "endDate" },
                },
                [
                  _c("el-date-picker", {
                    ref: "endDatePicker",
                    staticClass: "end-date-picker",
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "month",
                      placeholder: "如 2018-08",
                      "value-format": "yyyy-MM",
                      "picker-options": _vm.endDateOptions,
                    },
                    model: {
                      value: _vm.formData.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "endDate", $$v)
                      },
                      expression: "formData.endDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "项目职责", prop: "responsibility" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", maxlength: "2000" },
            model: {
              value: _vm.formData.responsibility,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "responsibility", $$v)
              },
              expression: "formData.responsibility",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "项目描述", prop: "description" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", maxlength: "8000" },
            model: {
              value: _vm.formData.description,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "btn-items" },
        [
          _c("el-button", { on: { click: _vm._cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("compareResumeProjectForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }