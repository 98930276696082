var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "compareResumeIntentionForm",
      staticClass: "compare-resume-intention-form col-15",
      attrs: {
        id: "compare-resume-intention-form",
        model: _vm.formData,
        rules: _vm.rules,
        "label-width": "80px",
        "label-position": "top",
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "期望职位", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "128" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space" }),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "期望城市", prop: "locationId" } },
                [
                  _c("city-select", {
                    ref: "citySelect",
                    on: { citySelected: _vm.handleCitySelected },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "salary-item",
                  attrs: { label: "薪资要求", prop: "minSalary" },
                },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0 },
                    model: {
                      value: _vm.formData.minSalary,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "minSalary", $$v)
                      },
                      expression: "formData.minSalary",
                    },
                  }),
                  _c("span", { staticClass: "text-inline" }, [_vm._v("K")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space-lg" }, [_vm._v("-")]),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                {
                  staticClass: "salary-item",
                  attrs: { label: " ", prop: "maxSalary" },
                },
                [
                  _c("el-input-number", {
                    attrs: { controls: false, precision: 0 },
                    model: {
                      value: _vm.formData.maxSalary,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "maxSalary", $$v)
                      },
                      expression: "formData.maxSalary",
                    },
                  }),
                  _c("span", { staticClass: "text-inline" }, [_vm._v("K")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "btn-items lg-btn-items" },
        [
          _c("el-button", { on: { click: _vm._cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("compareResumeIntentionForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }