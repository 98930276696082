<template>
    <div class="container" v-loading="loadingCheck || saveLoading">
        <div class="block-tab">
            <el-tabs :stretch="true" v-model="activeBlock" @tab-click="handleBlockTabClick">
                <el-tab-pane name="basic">
                    <span slot="label" class="tab-label">
                        <font-icon href="#icon-user1"></font-icon>
                        基本资料
                    </span>
                </el-tab-pane>
                <el-tab-pane name="work">
                    <span slot="label" class="tab-label">
                        <font-icon href="#icon-work"></font-icon>
                        工作经历
                    </span>
                </el-tab-pane>
                <el-tab-pane name="education">
                    <span slot="label" class="tab-label">
                        <font-icon href="#icon-time1"></font-icon>
                        教育经历
                    </span>
                </el-tab-pane>
                <el-tab-pane name="project">
                    <span slot="label" class="tab-label">
                        <font-icon href="#icon-xiangmujingli-04"></font-icon>
                        项目经历
                    </span>
                </el-tab-pane>
                <el-tab-pane label="自我评价" name="evaluate">
                    <span slot="label" class="tab-label">
                        <font-icon href="#icon-heart-hollow1"></font-icon>
                        自我评价
                    </span>
                </el-tab-pane>
                <el-tab-pane label="期望工作" name="intention">
                    <span slot="label" class="tab-label">
                        <font-icon href="#icon-survey"></font-icon>
                        期望工作
                    </span>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="page-content-wrapper">
            <div class="page-content" ref="pageContent" v-loading="loadingCheck">
                <div class="content-main" v-loading="saveLoading">
                    <div class="block-item basic">
                        <div class="block-item-left">
                            <div class="name">
                                <div class="name-left">
                                    <span 
                                        class="name-text" 
                                        :class="[getHighlightClass('realName'), highlightKey == 'realName' ? 'highlight-weight' : '']"
                                        @mouseenter="handleHighlightMouseEnter('realName')"
                                        @mouseleave="handleHighlightMouseLeave('realName')"
                                    >{{originalResumeData.realName}}</span>
                                    <a v-if="!!originalResumeData.id" :href="'/#/candidateDetail/' + originalResumeData.id" target="_blank" class="view">查看原简历</a>
                                </div>

                                <span @click="editBaseInfo">
                                    <font-icon href="#icon-bianji"></font-icon>
                                </span>
                            </div>
                            <div class="basic-grid">
                                <template  v-for="key in basicFieldsLess">
                                    <div class="basic-grid-item" :key="key">
                                        <span 
                                            :class="[getHighlightClass(key), highlightKey == key ? 'highlight-weight' : '']"
                                            @mouseenter="handleHighlightMouseEnter(key)"
                                            @mouseleave="handleHighlightMouseLeave(key)"
                                        >
                                            <template v-if="key == 'gender'">性别：{{genderMap[originalResumeData.gender]}}</template>
                                            <template v-if="key == 'yearOfExperience'">工作经验：{{originalResumeData.yearOfExperience}}年</template>
                                            <template v-if="key == 'age'">年龄：{{getAgeFromBirthday(originalResumeData.birthday) ? getAgeFromBirthday(originalResumeData.birthday) + '岁' : '未知'}}</template>
                                            <template v-if="key == 'company'">公司：{{originalResumeData.company || '未知'}}</template>
                                            <template v-if="key == 'title'">职位：{{originalResumeData.title || '未知'}}</template>
                                            <template v-if="key == 'city'">城市：{{provinceCityMap[originalResumeData.city] || '未知'}}</template>
                                            <template v-if="key == 'school'">学校：{{originalResumeData.school || '未知'}}</template>
                                            <template v-if="key == 'major'">专业：{{originalResumeData.major || '未知'}}</template>
                                            <template v-if="key == 'degree'">学历：{{degreeMap[originalResumeData.degree] || '未知'}}</template>
                                        </span>
                                    </div>
                                </template>
                                <div class="basic-grid-item">
                                    <span v-if="originalResumeData.mobileStatus == 1 || originalResumeData.mobileStatus == 2">手机：</span>
                                    <el-button
                                        ref="mobileViewButton"
                                        v-if="originalResumeData.mobileStatus == 1"
                                        class="info-block-btn"
                                        type="primary"
                                        size="mini"
                                        v-loading="mobileLoading"
                                        @click="applyView('mobile')">
                                        查看手机
                                    </el-button>
                                    <el-tooltip
                                        placement="bottom"
                                        effect="light"
                                        popper-class="concat-tooltip"
                                        :visible-arrow="false"
                                        v-if="originalResumeData.mobileStatus == 2">
                                        <div slot="content">
                                            候选人正在入职保证期中，暂不能查看其联<br/>系方式
                                        </div>
                                        <span class="info-lock-btn">
                                            查看手机
                                            <i class="icon-font icon-suo"></i>
                                        </span>
                                    </el-tooltip>
                                    <span
                                        :class="[originalResumeData.mobileStatus == 4 ? 'mobile-unable' : '', getHighlightClass('mobile'), highlightKey == 'mobile' ? 'highlight-weight' : '']"
                                        @mouseenter="handleHighlightMouseEnter('mobile')"
                                        @mouseleave="handleHighlightMouseLeave('mobile')"
                                        v-if="originalResumeData.mobileStatus == 3 || originalResumeData.mobileStatus == 4">
                                        手机：{{originalResumeData.mobile || 'xxxxxxxxxxx'}}
                                    </span>
                                </div>
                                <div class="basic-grid-item">
                                    <span v-if="originalResumeData.emailStatus == 1 || originalResumeData.emailStatus == 2">邮箱：</span>
                                    <el-button
                                        ref="emailViewButton"
                                        v-if="originalResumeData.emailStatus == 1"
                                        class="info-block-btn"
                                        plain
                                        type="primary"
                                        size="mini"
                                        v-loading="emailLoading"
                                        @click="applyView('email')">
                                        查看邮箱
                                    </el-button>
                                    <el-tooltip
                                        placement="bottom"
                                        effect="light"
                                        popper-class="concat-tooltip"
                                        :visible-arrow="false"
                                        v-if="originalResumeData.emailStatus == 2">
                                        <div slot="content">
                                            候选人正在入职保证期中，暂不能查看其联<br/>系方式
                                        </div>
                                        <span class="info-lock-btn">
                                            查看邮箱
                                            <i class="icon-font icon-suo"></i>
                                        </span>
                                    </el-tooltip>
                                    <span
                                        :class="[originalResumeData.emailStatus == 4 ? 'email-unable' : '', getHighlightClass('email'), highlightKey == 'email' ? 'highlight-weight' : '']"
                                        @mouseenter="handleHighlightMouseEnter('email')"
                                        @mouseleave="handleHighlightMouseLeave('email')"
                                        v-if="originalResumeData.emailStatus == 3 || originalResumeData.emailStatus == 4">
                                        邮箱：{{originalResumeData.email || 'xxx@xx.com'}}
                                    </span>
                                </div>
                                <div class="basic-grid-item">
                                    <span v-if="originalResumeData.wechatStatus == 1 || originalResumeData.wechatStatus == 2">微信：</span>
                                    <el-button
                                        ref="wechatViewButton"
                                        v-if="originalResumeData.wechatStatus == 1"
                                        class="info-block-btn"
                                        type="primary"
                                        size="mini"
                                        v-loading="wechatLoading"
                                        @click="applyView('wechat')">
                                        查看微信
                                    </el-button>
                                    <el-tooltip
                                        placement="bottom"
                                        effect="light"
                                        popper-class="concat-tooltip"
                                        :visible-arrow="false"
                                        v-if="originalResumeData.wechatStatus == 2">
                                        <div slot="content">
                                            候选人正在入职保证期中，暂不能查看其联<br/>系方式
                                        </div>
                                        <span class="info-lock-btn">
                                            查看微信
                                            <i class="icon-font icon-suo"></i>
                                        </span>
                                    </el-tooltip>
                                    <span
                                        :class="[originalResumeData.wechatStatus == 4 ? 'wechat-unable' : '', getHighlightClass('wechat'), highlightKey == 'wechat' ? 'highlight-weight' : '']"
                                        @mouseenter="handleHighlightMouseEnter('wechat')"
                                        @mouseleave="handleHighlightMouseLeave('wechat')"
                                        v-if="originalResumeData.wechatStatus == 3 || originalResumeData.wechatStatus == 4">
                                        微信：{{originalResumeData.wechat || 'xxxxxx'}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="block-item-right">
                            <div class="title">
                                基本资料
                            </div>
                            <div class="basic-items">
                                <template v-for="key in basicFields">
                                    <span 
                                        :key="key"
                                        v-if="resumeDataUpload[key == 'age' ? 'birthday' : key]"
                                        :class="[getHighlightClass(key), highlightKey == key ? 'highlight-weight' : '']"
                                        @mouseenter="handleHighlightMouseEnter(key)"
                                        @mouseleave="handleHighlightMouseLeave(key)"
                                    >
                                        <template v-if="key == 'realName'">姓名：{{resumeDataUpload.realName}}</template>
                                        <template v-if="key == 'gender'">性别：{{genderMap[resumeDataUpload.gender]}}</template>
                                        <template v-if="key == 'yearOfExperience'">工作经验：{{resumeDataUpload.yearOfExperience}}年</template>
                                        <template v-if="key == 'age'">年龄：{{getAgeFromBirthday(resumeDataUpload.birthday)}}岁</template>
                                        <template v-if="key == 'company'">公司：{{resumeDataUpload.company}}</template>
                                        <template v-if="key == 'title'">职位：{{resumeDataUpload.title}}</template>
                                        <template v-if="key == 'city'">城市：{{provinceCityMap[resumeDataUpload.city] || '未知'}}</template>
                                        <template v-if="key == 'school'">学校：{{resumeDataUpload.school}}</template>
                                        <template v-if="key == 'major'">专业：{{resumeDataUpload.major}}</template>
                                        <template v-if="key == 'degree'">学历：{{degreeMap[resumeDataUpload.degree] || '未知'}}</template>
                                        <template v-if="key == 'mobile'">手机：{{resumeDataUpload.mobile}}</template>
                                        <template v-if="key == 'email'">邮箱：{{resumeDataUpload.email}}</template>
                                        <template v-if="key == 'wechat'">微信：{{resumeDataUpload.wechat}}</template>
                                    </span>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="block-item experience-block work">
                        <div class="block-item-left">
                            <div class="block-item-list">
                                <div class="title">
                                    工作经历
                                    <span @click="addItem('workExperiences')"><font-icon href="#icon-tianjia"></font-icon>新增工作经历</span>
                                </div>

                                <template v-if="!(originalResumeData.workExperiences && originalResumeData.workExperiences.length > 0) && editWorkExperiencesIndex == -1">
                                    <div>无</div>
                                </template>

                                <div v-else class="normal-item" :key="index" v-for="(item,index) in originalResumeData.workExperiences">
                                    <div class="normal-item-title">
                                        <span>
                                            <span class="company-text">{{item.company}}</span>
                                            <span class="title-text" v-if="item.title">{{item.title}}</span>
                                            <i v-if="item.isNew" class="new-icon">新</i>
                                        </span>
                                        <div class="normal-btns" v-show="index != editWorkExperiencesIndex">
                                            <span v-if="item.isNew && !item.isReplaced" @click="deleteLeftWorkFromRight(item, index)"><font-icon class="replace-font-icon" href="#icon-shanchu"></font-icon></span>
                                            <el-tooltip v-if="item.isReplaced" class="item" effect="dark" content="撤销替换" placement="top">
                                                <span @click="revokeExperience(index, 'work')">
                                                    <font-icon class="replace-font-icon revoke-icon" href="#icon-chexiao"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip v-if="!item.isNew && !item.isReplaced && resumeDataUpload.workExperiences && resumeDataUpload.workExperiences.length > 0" class="item" effect="dark" content="替换为" placement="top">
                                                <span @click="replaceExperience(index, 'work')">
                                                    <font-icon class="replace-font-icon" href="#icon-tihuan3"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <span @click="handleWorkExperiencesEdit(index)"><font-icon href="#icon-bianji"></font-icon></span>
                                        </div>
                                    </div>
                                    <div class="normal-item-info">
                                        <!-- <span>{{item.title || '未知'}}</span> -->
                                        <!-- <i v-if="monthFormat(item)"></i> -->
                                        <span v-if="monthFormat(item)">{{monthFormat(item)}}</span>
                                        <i v-if="item.location"></i>
                                        <span v-if="item.location">{{provinceCityMap[item.location] || '未知'}}</span>
                                    </div>

                                    <div class="normal-item-expand" v-show="item.description">
                                        <span class="normal-item-expand-title">工作内容:</span>
                                        <span 
                                            v-show="item.descriptionOverflow && !item.descriptionExpand" 
                                            class="expand-icon"
                                            @click="expandWorkDescription(item)"
                                        ><font-icon href="#icon-arrow-down"></font-icon>展开</span>
                                        <span 
                                            v-show="item.descriptionOverflow && item.descriptionExpand" 
                                            class="fold-icon"
                                            @click="foldWorkDescription(item)"
                                        >
                                            <font-icon href="#icon-arrow-down"></font-icon>收起
                                        </span>
                                    </div>
                                    <div class="normal-item-expand" v-show="item.description">
                                        <div class="expand-content desc">
                                            <span class="expand-content-text" :class="item.descriptionOverflow && !item.descriptionExpand ? 'expand-text-fold' : ''">{{item.description}}</span>
                                        </div>
                                    </div>

                                    <compare-resume-work-form
                                        v-if="index == editWorkExperiencesIndex"
                                        ref="editResumeWorkForm"
                                        @resume-work-form-cancel="handleFormCancel"
                                        :is-save-to-db="false"
                                        :currentWork="mergeCurrentWork(index)"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'workExperiences')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>

                                <div class="form-wrap" v-show="editWorkExperiencesIndex == 10000">
                                    <compare-resume-work-form 
                                        ref="compareResumeWorkForm"
                                        @resume-work-form-cancel="handleFormCancel('work')"
                                        :is-save-to-db="false"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'workExperiences')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="block-item-right">
                            <div class="title">工作经历</div>
                            <template v-if="resumeDataUpload.workExperiences && resumeDataUpload.workExperiences.length > 0">
                                <div class="normal-item" :class="(item.hasAddToLeft || item.isReplaced) ? 'grey' : ''" :key="index" v-for="(item, index) in resumeDataUpload.workExperiences">
                                    <div class="normal-item-text">
                                        <div class="normal-item-title">
                                            <span><i class="desc">{{item.company}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                                        </div>
                                        <div class="normal-item-info">
                                            <span>{{item.title || '未知'}}</span>
                                            <i v-if="monthFormat(item)"></i>
                                            <span v-if="monthFormat(item)">{{monthFormat(item)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="item.hasAddToLeft" class="normal-item-operation">
                                        已补充至原简历
                                    </div>
                                    <div v-if="item.isReplaced" class="normal-item-operation">
                                        已被替换
                                    </div>
                                    <div v-if="!item.hasAddToLeft && !item.isReplaced && item.isNew" class="normal-item-operation" @click="handleAddWorkToLeft(item, index)">
                                        <font-icon href="#icon-tianjia1"></font-icon>
                                        添加至左侧
                                    </div>
                                </div>
                            </template>
                            <div v-else>无</div>

                            <!-- <div class="normal-item grey">
                                <div class="normal-item-text">
                                    <div class="normal-item-title">
                                        <span><i class="desc">广州一起飞国际机票网-长沙研发中心</i><i class="new-icon">新</i></span>
                                    </div>
                                    <div class="normal-item-info">
                                        <span>产品设计师</span>
                                        <i></i>
                                        <span>2020.05-2023.06</span>
                                    </div>
                                </div>
                                <div class="normal-item-operation">
                                    <font-icon href="#icon-tianjia1"></font-icon>
                                    添加至左侧
                                </div>
                            </div>
                            <div class="normal-item">
                                <div class="normal-item-text">
                                    <div class="normal-item-title">
                                        <span><i class="desc">广州一起飞国际机票网-长沙研发中心</i><i class="new-icon">新</i></span>
                                    </div>
                                    <div class="normal-item-info">
                                        <span>产品设计师</span>
                                        <i></i>
                                        <span>2020.05-2023.06</span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="block-item experience-block education">
                        <div class="block-item-left">
                            <div class="block-item-list">
                                <div class="title">
                                    教育经历
                                    <span @click="addItem('educationExperiences')"><font-icon href="#icon-tianjia"></font-icon>新增教育经历</span>
                                </div>

                                <template v-if="!(originalResumeData.educationExperiences && originalResumeData.educationExperiences.length > 0) && editEducationExperiencesIndex == -1">
                                    <div>无</div>
                                </template>

                                <div v-else class="normal-item" :key="index" v-for="(item,index) in originalResumeData.educationExperiences">
                                    <div class="normal-item-title">
                                        <span>{{item.school}} <i v-if="item.isNew" class="new-icon">新</i></span>
                                        <div class="normal-btns" v-show="index != editEducationExperiencesIndex">
                                            <span v-if="item.isNew && !item.isReplaced" @click="deleteLeftEducationFromRight(item, index)"><font-icon class="replace-font-icon" href="#icon-shanchu"></font-icon></span>
                                            <el-tooltip v-if="item.isReplaced" class="item" effect="dark" content="撤销替换" placement="top">
                                                <span @click="revokeExperience(index, 'education')">
                                                    <font-icon class="replace-font-icon revoke-icon" href="#icon-chexiao"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip v-if="!item.isNew && !item.isReplaced && resumeDataUpload.educationExperiences && resumeDataUpload.educationExperiences.length > 0" class="item" effect="dark" content="替换为" placement="top">
                                                <span @click="replaceExperience(index, 'education')">
                                                    <font-icon class="replace-font-icon" href="#icon-tihuan3"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <span @click="handleEducationExperiencesEdit(index)"><font-icon href="#icon-bianji"></font-icon></span>
                                        </div>
                                    </div>
                                    <div class="normal-item-info">
                                        <span>{{degreeMap[item.degree] || '未知'}}</span>
                                        <i></i>
                                        <span class="major-text" :title="item.major">{{item.major || '未知'}}</span>
                                        <i v-if="monthFormat(item)"></i>
                                        <span v-if="monthFormat(item)">{{monthFormat(item)}}</span>
                                    </div>

                                    <compare-resume-education-form
                                        v-if="index == editEducationExperiencesIndex"
                                        ref="editResumeEducationForm"
                                        @resume-education-form-cancel="handleFormCancel"
                                        :is-save-to-db="false"
                                        :currentEducation="originalResumeData.educationExperiences[index]"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'educationExperiences')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>

                                <div class="form-wrap" v-show="editEducationExperiencesIndex == 10000">
                                    <compare-resume-education-form 
                                        ref="compareResumeEducationForm"
                                        @resume-education-form-cancel="handleFormCancel('education')"
                                        :is-save-to-db="false"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'educationExperiences')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="block-item-right">
                            <div class="title">教育经历</div>
                            <template v-if="resumeDataUpload.educationExperiences && resumeDataUpload.educationExperiences.length > 0">
                                <div 
                                    class="normal-item" 
                                    :class="(item.hasAddToLeft || item.isReplaced) ? 'grey' : ''" 
                                    :key="index" 
                                    v-for="(item, index) in resumeDataUpload.educationExperiences"
                                >
                                    <div class="normal-item-text">
                                        <div class="normal-item-title">
                                            <span><i class="desc">{{item.school}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                                        </div>
                                        <div class="normal-item-info">
                                            <span>{{degreeMap[item.degree]}}</span>
                                            <i></i>
                                            <span class="major-text" :title="item.major">{{item.major || '未知'}}</span>
                                            <i v-if="monthFormat(item)"></i>
                                            <span v-if="monthFormat(item)">{{monthFormat(item)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="item.hasAddToLeft" class="normal-item-operation">
                                        已补充至原简历
                                    </div>
                                    <div v-if="item.isReplaced" class="normal-item-operation">
                                        已被替换
                                    </div>
                                    <div v-if="!item.hasAddToLeft && !item.isReplaced && item.isNew" class="normal-item-operation" @click="handleAddEducationToLeft(item, index)">
                                        <font-icon href="#icon-tianjia1"></font-icon>
                                        添加至左侧
                                    </div>
                                </div>
                            </template>
                            <div v-else>无</div>
                        </div>
                    </div>

                    <div class="block-item experience-block project">
                        <div class="block-item-left">
                            <div class="block-item-list">
                                <div class="title">
                                    项目经历
                                    <span @click="addItem('projectExperiences')"><font-icon href="#icon-tianjia"></font-icon>新增项目经历</span>
                                </div>

                                <template v-if="!(originalResumeData.projectExperiences && originalResumeData.projectExperiences.length > 0) && editProjectExperiencesIndex == -1">
                                    <div>无</div>
                                </template>

                                <div v-else class="normal-item" :key="index" v-for="(item,index) in originalResumeData.projectExperiences">
                                    <div class="normal-item-title">
                                        <span>{{item.projectName}} <i v-if="item.isNew" class="new-icon">新</i></span>
                                        <div class="normal-btns" v-show="index != editProjectExperiencesIndex">
                                            <span v-if="item.isNew && !item.isReplaced" @click="deleteLeftProjectFromRight(item, index)"><font-icon class="replace-font-icon" href="#icon-shanchu"></font-icon></span>
                                            <el-tooltip v-if="item.isReplaced" class="item" effect="dark" content="撤销替换" placement="top">
                                                <span @click="revokeExperience(index, 'project')">
                                                    <font-icon class="replace-font-icon revoke-icon" href="#icon-chexiao"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip v-if="!item.isNew && !item.isReplaced && resumeDataUpload.projectExperiences && resumeDataUpload.projectExperiences.length > 0" class="item" effect="dark" content="替换为" placement="top">
                                                <span @click="replaceExperience(index, 'project')">
                                                    <font-icon class="replace-font-icon" href="#icon-tihuan3"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <span @click="handleProjectExperiencesEdit(index)"><font-icon href="#icon-bianji"></font-icon></span>
                                        </div>
                                    </div>
                                    <div class="normal-item-info">
                                        <span>{{item.projectRole || '未知'}}</span>
                                        <i v-if="monthFormat(item)"></i>
                                        <span v-if="monthFormat(item)">{{monthFormat(item)}}</span>
                                    </div>

                                    <div class="normal-item-expand" v-show="item.responsibility">
                                        <span class="normal-item-expand-title">项目职责:</span>
                                        <span 
                                            v-show="item.responsibilityOverflow && item.responsibilityExpand" 
                                            class="fold-icon"
                                            @click="foldProjectResponsibility(item)"
                                        ><font-icon href="#icon-arrow-down"></font-icon>收起</span>
                                        <span 
                                            v-show="item.responsibilityOverflow && !item.responsibilityExpand" 
                                            class="expand-icon"
                                            @click="expandProjectResponsibility(item)"
                                        ><font-icon href="#icon-arrow-down"></font-icon>展开</span>
                                    </div>
                                    <div class="normal-item-expand" v-show="item.responsibility">
                                        <div class="expand-content responsibility">
                                            <span class="expand-content-text" :class="item.responsibilityOverflow && !item.responsibilityExpand ? 'expand-text-fold' : ''">
                                                <span>{{item.responsibility}}</span>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="normal-item-expand" v-show="item.description">
                                        <span class="normal-item-expand-title">项目描述:</span>
                                        <span 
                                            v-show="item.descriptionOverflow && item.descriptionExpand" 
                                            class="fold-icon"
                                            @click="foldProjectDescription(item)"
                                        ><font-icon href="#icon-arrow-down"></font-icon>收起</span>
                                        <span 
                                            v-show="item.descriptionOverflow && !item.descriptionExpand" 
                                            class="expand-icon"
                                            @click="expandProjectDescription(item)"
                                        ><font-icon href="#icon-arrow-down"></font-icon>展开</span>
                                    </div>
                                    <div class="normal-item-expand" v-show="item.description">
                                        <div class="expand-content desc">
                                            <span class="expand-content-text" :class="item.descriptionOverflow && !item.descriptionExpand ? 'expand-text-fold' : ''">
                                                <span>{{item.description}}</span>
                                            </span>
                                        </div>
                                    </div>

                                    <compare-resume-project-form
                                        v-if="index == editProjectExperiencesIndex"
                                        ref="editResumeProjectForm"
                                        @resume-project-form-cancel="handleFormCancel"
                                        :is-save-to-db="false"
                                        :is-edit="true"
                                        :currentProject="originalResumeData.projectExperiences[index]"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'projectExperiences')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>

                                <div class="form-wrap" v-show="editProjectExperiencesIndex == 10000">
                                    <compare-resume-project-form 
                                        ref="compareResumeProjectForm"
                                        @resume-project-form-cancel="handleFormCancel('project')"
                                        :is-save-to-db="false"
                                        :is-edit="false"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'projectExperiences')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="block-item-right">
                            <div class="title">项目经历</div>
                            <template v-if="resumeDataUpload.projectExperiences && resumeDataUpload.projectExperiences.length > 0">
                                <div 
                                    class="normal-item" 
                                    :class="(item.hasAddToLeft || item.isReplaced) ? 'grey' : ''" 
                                    :key="index" 
                                    v-for="(item, index) in resumeDataUpload.projectExperiences"
                                >
                                    <div class="normal-item-text">
                                        <div class="normal-item-title">
                                            <span><i class="desc">{{item.projectName}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                                        </div>
                                        <div class="normal-item-info">
                                            <span>{{item.projectRole || '未知'}}</span>
                                            <i v-if="monthFormat(item)"></i>
                                            <span v-if="monthFormat(item)">{{monthFormat(item)}}</span>
                                        </div>
                                    </div>
                                    <div v-if="item.hasAddToLeft" class="normal-item-operation">
                                        已补充至原简历
                                    </div>
                                    <div v-if="item.isReplaced" class="normal-item-operation">
                                        已被替换
                                    </div>
                                    <div v-if="!item.hasAddToLeft && !item.isReplaced && item.isNew" class="normal-item-operation" @click="handleAddProjectToLeft(item, index)">
                                        <font-icon href="#icon-tianjia1"></font-icon>
                                        添加至左侧
                                    </div>
                                </div>
                            </template>
                            <div v-else>无</div>
                        </div>
                    </div>

                    <div class="block-item experience-block evaluate">
                        <div class="block-item-left">
                            <div class="block-item-list">
                                <div class="title">
                                    自我评价
                                    <span v-if="!originalResumeData.selfEvaluation" @click="addItem('selfEvaluation')"><font-icon href="#icon-tianjia"></font-icon>新增自我评价</span>

                                    <div v-else class="evaluate-add-btn">
                                        <el-tooltip v-if="!showSelfEvaluateEdit && resumeDataUpload.selfEvaluation && replaceSelfEvaluateBtnVisible" effect="dark" content="替换为" placement="top">
                                            <span @click="replaceSelfEvaluate(index, 'project')">
                                                <font-icon class="replace-font-icon" href="#icon-tihuan3"></font-icon>
                                            </span>
                                        </el-tooltip>
                                        <!-- <span v-if="originalResumeData.selfEvaluation && !showSelfEvaluateEdit" @click="handleDeleteSelfEvaluate">
                                            <font-icon class="replace-font-icon" href="#icon-shanchu"></font-icon>
                                        </span> -->
                                        <span @click="handleSelfEvaluateEdit"><font-icon href="#icon-bianji"></font-icon></span>
                                    </div>

                                </div>

                                <!-- <div class="operation-list">
                                    <el-tooltip v-if="!showSelfEvaluateEdit && resumeDataUpload.selfEvaluation && replaceSelfEvaluateBtnVisible" effect="dark" content="替换为" placement="top">
                                        <span @click="replaceSelfEvaluate(index, 'project')">
                                            <font-icon class="replace-font-icon" href="#icon-tihuan3"></font-icon>
                                        </span>
                                    </el-tooltip>
                                    <span @click="handleSelfEvaluateEdit"><font-icon href="#icon-bianji"></font-icon></span>
                                </div> -->

                                <div v-if="originalResumeData.selfEvaluation && !showSelfEvaluateEdit" class="self-evaluate-text">{{originalResumeData.selfEvaluation}}</div>
                                <div v-if="!originalResumeData.selfEvaluation && !showSelfEvaluateEdit">无</div>

                                <el-input 
                                    class="self-evaluate-input" 
                                    v-show="showSelfEvaluateEdit" 
                                    type="textarea" 
                                    maxlength="4000"
                                    v-model="originalResumeData.selfEvaluation"
                                ></el-input>
                                <div class="self-evaluate-btns" v-show="showSelfEvaluateEdit">
                                    <el-button @click="handleCancelEditSelfEvaluate">取消</el-button>
                                    <el-button type="primary" @click="handleSaveSelfEvaluate">保存</el-button>
                                </div>
                            </div>
                        </div>
                        <div class="block-item-right">
                            <div class="title self-evaluate-title">
                                自我评价
                                <div v-if="resumeDataUpload.selfEvaluation && replaceSelfEvaluateBtnVisible" class="normal-item-operation" @click="handleAddSelfEvaluateToLeft">
                                    <font-icon href="#icon-tianjia1"></font-icon>
                                    添加至左侧
                                </div>
                            </div>
                            <template v-if="resumeDataUpload.selfEvaluation">
                                <div class="evaluate-item">
                                    {{resumeDataUpload.selfEvaluation}}
                                </div>
                            </template>
                            <div v-else>无</div>
                        </div>
                    </div>

                    <div class="block-item experience-block intention">
                        <div class="block-item-left">
                            <div class="block-item-list">
                                <div class="title">
                                    期望工作
                                    <span @click="addItem('intention')"><font-icon href="#icon-tianjia"></font-icon>新增期望工作</span>
                                </div>

                                <template v-if="!(originalResumeData.intentions && originalResumeData.intentions.length > 0) && editIntentionsIndex == -1">
                                    <div>无</div>
                                </template>

                                <div v-else class="normal-item intention-item" :key="index" v-for="(item,index) in originalResumeData.intentions">
                                    <div class="normal-item-info">
                                        <span>
                                            <span v-if="item.locationId">{{provinceCityMap[item.locationId] || '未知'}}</span>
                                            <i v-if="item.locationId"></i>
                                            <span>{{item.title}}</span>
                                            <i></i>
                                            <span>{{item.minSalary}}-{{item.maxSalary}}K</span>
                                        </span>
                                        <div class="normal-btns" v-show="index != editIntentionsIndex">
                                            <span v-if="item.isNew && !item.isReplaced" @click="deleteLeftIntentionFromRight(item, index)"><font-icon class="replace-font-icon" href="#icon-shanchu"></font-icon></span>
                                            <el-tooltip v-if="item.isReplaced" class="item" effect="dark" content="撤销替换" placement="top">
                                                <span @click="revokeExperience(index, 'intention')">
                                                    <font-icon class="replace-font-icon revoke-icon" href="#icon-chexiao"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip v-if="!item.isNew && !item.isReplaced && resumeDataUpload.intentions && resumeDataUpload.intentions.length > 0" class="item" effect="dark" content="替换为" placement="top">
                                                <span @click="replaceExperience(index, 'intention')">
                                                    <font-icon class="replace-font-icon" href="#icon-tihuan3"></font-icon>
                                                </span>
                                            </el-tooltip>
                                            <span @click="handleIntentionEdit(index)"><font-icon href="#icon-bianji"></font-icon></span>
                                        </div>
                                    </div>

                                    <div class="form-wrap" v-if="index == editIntentionsIndex">
                                        <compare-resume-intention-form
                                            ref="editResumeIntentionForm"
                                            @resume-intention-form-cancel="handleFormCancel"
                                            :is-save-to-db="false"
                                            :is-edit="true"
                                            :currentIntention="originalResumeData.intentions[index]"
                                            @save-btn-callback="(val) => handleSaveBtnCallback(val, 'intentions')"

                                            :candidate-id="candidateId"
                                            :edit-url="editUrl"
                                            :add-url="addUrl"
                                        />
                                    </div>
                                </div>

                                <div class="form-wrap" v-show="editIntentionsIndex == 10000">
                                    <compare-resume-intention-form 
                                        ref="compareResumeIntentionForm"
                                        @resume-intention-form-cancel="handleFormCancel"
                                        :is-save-to-db="false"
                                        :is-edit="false"
                                        @save-btn-callback="(val) => handleSaveBtnCallback(val, 'intentions')"

                                        :candidate-id="candidateId"
                                        :edit-url="editUrl"
                                        :add-url="addUrl"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="block-item-right">
                            <div class="title">期望工作</div>
                            <template v-if="resumeDataUpload.intentions && resumeDataUpload.intentions.length > 0">
                                <div class="normal-item intention-item" 
                                    :class="(item.hasAddToLeft || item.isReplaced) ? 'grey' : ''" 
                                    :key="index" 
                                    v-for="(item, index) in resumeDataUpload.intentions"
                                >
                                    <div class="normal-item-text">
                                        <div class="normal-item-info">
                                            <span v-if="item.locationId">{{provinceCityMap[item.locationId] || '未知'}}</span>
                                            <i v-if="item.locationId"></i>
                                            <span>{{item.title}}</span>
                                            <i></i>
                                            <span>{{item.minSalary}}-{{item.maxSalary}}K</span>
                                        </div>
                                    </div>
                                    <div v-if="item.hasAddToLeft" class="normal-item-operation">
                                        已补充至原简历
                                    </div>
                                    <div v-if="item.isReplaced" class="normal-item-operation">
                                        已被替换
                                    </div>
                                    <div v-if="!item.hasAddToLeft && !item.isReplaced && item.isNew" class="normal-item-operation" @click="handleAddIntentionToLeft(item, index)">
                                        <font-icon href="#icon-tianjia1"></font-icon>
                                        添加至左侧
                                    </div>
                                </div>
                            </template>
                            <div v-else>无</div>
                        </div>
                    </div>
                </div>

                <!-- <el-dialog
                    :visible.sync="updateTipDialogShow"
                    title="更新提醒"
                    custom-class="update-tip-dialog"
                    :close-on-click-modal="false"
                    :close-on-press-escape="false"
                    :show-close="false"
                >
                    <div class="update-tip" v-html="updateTip"></div>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="hideUpdateTipDialog">放弃</el-button>
                        <el-button type="primary" @click="continueEdit(true)">去编辑补充</el-button>
                    </span>
                </el-dialog> -->
            </div>
        </div>


        <div class="page-footer">
            <div class="page-footer-content">
                <p>简历已存在，请将右侧你上传的简历内容补充</p>
                <div class="page-footer-btns">
                    <el-button type="plain" :loading="saveLoading" @click="giveUpEdit">放弃</el-button>
                    <el-button
                        type="primary"
                        style="width: 80px;"
                        :loading="saveLoading"
                        @click="handleSave"
                    >保存</el-button>
                </div>
            </div>
        </div>

        <replace-experience
            ref="replaceExperienceDialog"
            @replace-selected="handleReplaceSelected"
        >
        </replace-experience>

        <update-base-info-dialog
            ref="updateBaseInfoDialog"
            :is-save-to-db="false"
            :isRecommend="false"
            @save-btn-callback="handleBaseInfoSave"
        ></update-base-info-dialog>

        <el-dialog
            :visible.sync="revokeDialogVisible"
            title="提示"
            width="480px"
            custom-class="revoke-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <p>您确定要撤销当前内容吗？撤销后将放回右侧</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancelRevoke">取消</el-button>
                <el-button type="primary" @click="handleRevokeExperience">确认</el-button>
            </div>
        </el-dialog>

        <!-- <el-dialog
            :visible.sync="deleteDialogVisible"
            title="提示"
            custom-class="revoke-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <p>您确定要删除当前内容吗？删除后将放回右侧</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deleteDialogVisible = false">取消</el-button>
                <el-button type="primary" @click="handleDelete">确认</el-button>
            </div>
        </el-dialog> -->

        <el-dialog
            :visible.sync="quitEditDialogVisible"
            title="提示"
            width="480px"
            custom-class="quit-edit-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <p>有内容没有保存，确定退出编辑吗？</p>
            <p class="desc-text">退出编辑后，更新的内容不会自动保存</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="quitEditDialogVisible = false;">取消</el-button>
                <el-button type="primary" @click="handleQuitEdit">确认</el-button>
            </div>
        </el-dialog>

        <el-dialog
            :visible.sync="replaceSelfEvaluateDialogVisible"
            title="提示"
            width="480px"
            custom-class="replace-self-evaluate-dialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
        >
            <p>是否替换为新上传简历中的自我评价？</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="replaceSelfEvaluateDialogVisible = false;">取消</el-button>
                <el-button type="primary" @click="handleReplaceSelfEvaluate">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import candidateRawDetail from './candidateRawDetail.vue';
import PublicJobService from '#/js/service/publicJobService.js';
import provinceCityMap from '#/js/config/provinceCity.json';

import CompareResumeWorkForm from './form/compare-resume-work-form.vue';
import CompareResumeEducationForm from './form/compare-resume-education-form.vue';
import CompareResumeProjectForm from './form/compare-resume-project-form.vue';
import CompareResumeIntentionForm from './form/compare-resume-intention-form.vue';

import ReplaceExperience from './dialog/replaceExperience.vue';
import UpdateBaseInfoDialog from './dialog/updateBaseInfoDialog.vue';

import moment from 'moment';

const fieldMap = {
    realName: "姓名",
    gender: "性别",
    mobile: "手机",
    email: "邮箱",
    wechat: "微信",
    city: "城市",
    yearOfExperience: "工作年限",
    company: "公司",
    title: "职位",
    school: "学校",
    degree: "学历",
    major: "专业",
    workExperiences: "工作经历",
    educationExperiences: "教育经历",
    projectExperiences: "项目经验",
    projectName: '项目名称',
    selfEvaluation: "自我评价",
    intentions: "期望工作"
}
export default {
    name: 'candidate-updatecheck',
	// components: {
    //     candidateRawDetail
	// },
    components: {
        CompareResumeWorkForm,
        CompareResumeEducationForm,
        CompareResumeProjectForm,
        CompareResumeIntentionForm,
        ReplaceExperience,
        UpdateBaseInfoDialog,
    },
	data() {
		return {
            basicFieldsLess: ['gender', 'yearOfExperience', 'age', 'company', 'title', 'city', 'school', 'major', 'degree'],
            basicFields: ['realName', 'gender', 'yearOfExperience', 'age', 'company', 'title', 'city', 'school', 'major', 'degree', 'mobile', 'email', 'wechat'],
            provinceCityMap,
            blockTabList: ['basic', 'work', 'education', 'project', 'evaluate', 'intention'],
            activeBlock: 'basic',
            activeBlockIndex: 0,
            editWorkExperiencesIndex: -1,
            editIntentionsIndex: -1,
            editEducationExperiencesIndex: -1,
            editProjectExperiencesIndex: -1,
            editSelfEvaluationIndex: -1,
            workDescriptionOverflow: false,
            workDescriptionExpand: false,
            projectDescriptionOverflow: false,
            projectDescriptionExpand: false,
            projectResponsibilityOverflow: false,
            projectResponsibilityExpand: false,

            genderMap: ['未知','男','女'],
            degreeMap: ['无',"高中及以下","大专","本科","硕士","MBA","博士"],

            fileName:"",
            resumeId: "",
            originalResumeId: "",
            checkTitle:[
                '简历已存在，你可以覆盖一份更新、更完整的简历！',
                '简历已存在，你的上传的这份还不够新哦~',
                '简历已存在，你的上传的这份还不够完整哦~',
                '简历已存在，不能进行覆盖'
                // '简历已存在，你可快速对比和编辑你上传的简历'
            ],
            resumeDataUpload: {},
            originalResumeData: {},
            isFresher:false,
            moreComplete:false,
            extensionMode:false,
            updateTipDialogShow: false,
            updateTip: "",
            uncompleteArr: [],
            requiredArr: [],
            isXlsAnonymous: false,
            isXLSEdit: false,
            loadingCheck: false,
            editUrl: '',
            addUrl: '',
            candidateId: '',

            saveLoading: false,
            saveBtnClick: false,
            highlightList: [],
            highlightKey: '',
            workListFromRight: [],
            replaceOriginIndex: -1,
            replacedWorkMap: {},
            replacedEducationMap: {},
            replacedProjectMap: {},
            replacedIntentionMap: {},
            revokeDialogVisible: false,
            revokeType: '',
            revokeIndex: -1,
            quitEditDialogVisible: false,
            quitEditCb: () => {},

            showSelfEvaluateEdit: false,
            replaceSelfEvaluateBtnVisible: true,
            replaceSelfEvaluateDialogVisible: false,
            mobileLoading: false,
            emailLoading: false,
            wechatLoading: false,
        };
    },
    created() {
        this.extensionMode = location.href.indexOf('file=') > -1 ? true : false;
    },
	mounted() {
        window.moment = moment;
        let self = this;
        this.fileName = encodeURI(decodeURI(this.$route.query.file));
        
        if((window.top !== window.self) && window.parent && window.parent[0] && window.parent[0].location.href.indexOf('liebide.com') > -1) {
            if(sessionStorage.getItem(this.fileName)) {
                this.init();
            }else {
                window.parent.postMessage(JSON.stringify({
                    from: 'liebide',
                    event: 'updateCheckPageReady',
                    code: 1
                }), '*')

                window.addEventListener('message', function(event){
                    try {
                        if(event.data) {
                            let params = JSON.parse(event.data);
                            console.log(params)
                            sessionStorage.setItem(self.fileName, JSON.stringify(params || {}));
                            self.init();
                        }
                    } catch (error) {
                        console.log(error)
                    }
                });
            }
        }else {
            this.init();
        }

        let scrollEl = document.querySelector('.page-content-wrapper');
        scrollEl.addEventListener('scroll', this.handleScrollEvent);
	},
    beforeDestroy() {
        let scrollEl = document.querySelector('.page-content-wrapper');
        scrollEl && scrollEl.removeEventListener('scroll', this.handleScrollEvent);
    },
	computed: {
        duplicateType(){
            if(this.isFresher && this.moreComplete){
                return 0;
            }else if(this.moreComplete){
                return 1;
            }else if(this.isFresher){
                return 2;
            }else{
                return 3;
            }
        }
    },
    methods: {
        handleScrollEvent() {
            for (let i = this.blockTabList.length - 1; i >= 0; i--) {
                let height = document.querySelector(`.page-content .block-item.${this.blockTabList[i]}`).getBoundingClientRect().top;
                let compareHeight = document.querySelector('.page-content-wrapper').getBoundingClientRect().top;

                if ((height - 1) < compareHeight) {
                    this.activeBlock = this.blockTabList[i];
                    this.activeBlockIndex = i;
                    break;
                }
            }
        },
        getHighlightClass(key) {
            if (this.highlightList.indexOf(key) > -1) {
                return `highlight-light highlight-${key}`;
            }
            return '';
        },
        highlightCheck() {
            let list = [];
            let replaceCheckArr = ['realName', 'gender', 'yearOfExperience', 'city', 'company', 'title', 'school', 'major', 'degree'];

            replaceCheckArr.forEach(item => {
                if (this.resumeDataUpload[item] && this.resumeDataUpload[item] != this.originalResumeData[item]) {
                    if ((item == 'gender' || item == 'yearOfExperience') && this.resumeDataUpload[item] == 0) {
                    } else {
                        list.push(item);
                    }
                }
            });

            if (this.getAgeFromBirthday(this.resumeDataUpload.birthday) && this.getAgeFromBirthday(this.resumeDataUpload.birthday) != this.getAgeFromBirthday(this.originalResumeData.birthday)) {
                list.push('age');
            }
            if ((this.originalResumeData.mobileStatus == 3 || this.originalResumeData.mobileStatus == 4) && this.resumeDataUpload.mobile && (this.resumeDataUpload.mobile != this.originalResumeData.mobile)) {
                list.push('mobile');
            }
            if ((this.originalResumeData.emailStatus == 3 || this.originalResumeData.emailStatus == 4) && this.resumeDataUpload.email && (this.resumeDataUpload.email != this.originalResumeData.email)) {
                list.push('email');
            }
            if ((this.originalResumeData.wechatStatus == 3 || this.originalResumeData.wechatStatus == 4) && this.resumeDataUpload.wechat && (this.resumeDataUpload.wechat != this.originalResumeData.wechat)) {
                list.push('wechat');
            }

            this.highlightList = list;
        },
        handleHighlightMouseEnter(key) {
            console.log(key);
            if (this.highlightList.indexOf(key) > -1) {
                this.highlightKey = key;
            }
        },
        handleHighlightMouseLeave(key) {
            console.log(key);
            if (this.highlightKey == key) {
                this.highlightKey = '';
            }
        },
        monthFormat(item) {
            if ((!item.startDate || (item.startDate == '0001-01-01T00:00:00')) 
                && (!item.endDate || (item.endDate == '0001-01-01T00:00:00'))){
                return '';
            }

            let startStr = '';
            if (!item.startDate || (item.startDate == '0001-01-01T00:00:00')) {
                startStr = '未知';
            } else if (typeof item.startDate == 'object') {
                startStr = moment(item.startDate).format('yyyy.MM');
            } else if (item.startDate.indexOf('9999') == 0) {
                startStr = '至今';
            }else {
                startStr = item.startDate.substring(0, 7).replace('-', '.');
            }

            let endStr = '';
            if (!item.endDate || (item.endDate == '0001-01-01T00:00:00')) {
                endStr = '未知';
            } else if (typeof item.endDate == 'object') {
                endStr = moment(item.endDate).format('yyyy.MM');
            } else if (item.endDate.indexOf('9999') == 0) {
                endStr = '至今';
            }else {
                endStr = item.endDate.substring(0, 7).replace('-', '.');
            }

            return `${startStr}-${endStr}`;
        },
        getAgeFromBirthday(birthday) {
            if(!birthday || birthday == '' || birthday.indexOf('0001') == 0) {
                return '';
            }
            return (new Date()).getFullYear() - birthday.slice(0, 4);
        },
        goAnchor(selector, behavior = 'smooth'){
            this.$nextTick(() => {
                let anchor = this.$el.querySelector(selector).scrollIntoView({ behavior: behavior, block: 'start' });
            })
        },
        goAnchorByEl(el){
            this.$nextTick(() => {
                let anchor = el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            })
        },
        applyView(type) {
            let _self = this,
                url = `/My/Candidate/${this.originalResumeData.id}/LogViewContact`,
                data = {
                    type: 0,
                    __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                };
            if(type === 'mobile') {
                data.type = 1;
                this.mobileLoading = true;
            } else if(type === 'email'){
                data.type = 2;
                this.emailLoading = true;
            } else if(type === 'wechat'){
                this.wechatLoading = true;
                data.type = 3;
            }
            // request(url, data, "post", true, true).then(res => {
                _request({
                method: 'POST',
                url: url,
                data: data,
            })
            .then(res => {
                _self.originalResumeData[type] = res.contact;
                _self.originalResumeData[type + 'Status'] = res.isValid ? 3 : 4;

                _self.highlightCheck();
            })
            .catch(err => {
                if(err.message || err.errorMeaasge) {
                    shortTip(err.message || err.errorMeaasge)
                } else {
                    shortTip('系统异常，请稍后重试')
                }
                console.log(err);
            }).finally(() => {
                this.mobileLoading = false;
                this.emailLoading = false;
                this.wechatLoading = false;
                // loading finish
            });
        },
        sortWrokExperiences() {
            if (!(this.originalResumeData.workExperiences && this.originalResumeData.workExperiences.length > 0)) {
                return false;
            }
            let list = this.originalResumeData.workExperiences;
            list.sort((a, b) => {
                let result = 0;
                if ((a.isLatestWorkExperience && b.isLatestWorkExperience) || (!a.isLatestWorkExperience && !b.isLatestWorkExperience)) {
                    result = moment(a.startDate).isSameOrAfter(b.startDate) ? -1 : 1;
                } else if (a.isLatestWorkExperience && !b.isLatestWorkExperience) {
                    result = -1;
                } else {
                    result = 1;
                }
                // return moment(a.startDate).isSameOrAfter(b.startDate) ? -1 : 1;
                // return if (moment(work.startDate).isSameOrAfter(item.startDate)) {
                //     insertIndex = index;
                //     return true;
                // }
                return result;
            });
            this.originalResumeData.workExperiences = list;

            this.syncBasicWorkInfo();
        },
        sortEducationExperiences() {
            if (!(this.originalResumeData.educationExperiences && this.originalResumeData.educationExperiences.length > 0)) {
                return false;
            }
            let list = this.originalResumeData.educationExperiences;
            list.sort((a, b) => {
                let result = 0;
                if (a.degree != b.degree) {
                    result = b.degree - a.degree;
                } else {
                    result = moment(a.startDate).isSameOrAfter(b.startDate) ? -1 : 1;
                }
                return result;
            });
            this.originalResumeData.educationExperiences = list;

            this.syncBasicEducationInfo();
        },
        sortProjectExperiences() {
            if (!(this.originalResumeData.projectExperiences && this.originalResumeData.projectExperiences.length > 0)) {
                return false;
            }
            let list = this.originalResumeData.projectExperiences;
            list.sort((a, b) => {
                return moment(a.startDate).isSameOrAfter(b.startDate) ? -1 : 1;
            });
            this.originalResumeData.projectExperiences = list;
        },
        syncBasicWorkInfo() {
            if (this.originalResumeData.workExperiences && this.originalResumeData.workExperiences.length > 0) {
                this.originalResumeData.company = this.originalResumeData.workExperiences[0].company;
                this.originalResumeData.title = this.originalResumeData.workExperiences[0].title;
            }
        },
        syncBasicEducationInfo() {
            if (this.originalResumeData.educationExperiences && this.originalResumeData.educationExperiences.length > 0) {
                this.originalResumeData.school = this.originalResumeData.educationExperiences[0].school;
                this.originalResumeData.major = this.originalResumeData.educationExperiences[0].major;
                this.originalResumeData.degree = this.originalResumeData.educationExperiences[0].degree;
            }
        },
        editBaseInfo() {
            this.$refs.updateBaseInfoDialog.show({
                ...this.originalResumeData,
                degree: +this.originalResumeData.degree,
                // mobileStatus: 3,
                // emailStatus: 3,
                // wechatStatus: 3,
                isFirmResume: false,

                openFrom: 'compare',
            }, this.resumeDataUpload);
        },
        handleBaseInfoSave(data) {
            console.log(`data:`, data)
            Object.assign(this.originalResumeData, data);
            if (data.age) {
                this.originalResumeData.birthday = (new Date().getFullYear() - data.age) + '-01-01T00:00:00';
            }

            if (data.company) {
                if (this.originalResumeData.workExperiences && this.originalResumeData.workExperiences.length > 0) {
                    this.originalResumeData.workExperiences[0].company = data.company;
                    this.originalResumeData.workExperiences[0].title = data.title;
                    this.originalResumeData.workExperiences[0].startDate = moment(data.workStartDate).format('YYYY-MM') + '-01T00:00:00';
                    this.originalResumeData.workExperiences[0].endDate = moment(data.workEndDate).format('YYYY-MM') + '-01T00:00:00';
                } else {
                    this.originalResumeData.workExperiences = [{
                        created: moment().format('YYYY-MM') + '-01T00:00:00',
                        company: data.company,
                        created: null,
                        description: '',
                        endDate: moment(data.workEndDate).format('YYYY-MM') + '-01T00:00:00',
                        id: '',
                        isLatestWorkExperience: true,
                        location: 0,
                        startDate: moment(data.workStartDate).format('YYYY-MM') + '-01T00:00:00',
                        title: data.title,
                        updated: null
                    }];
                }
            }

            if (this.originalResumeData.school) {
                if (this.originalResumeData.educationExperiences && this.originalResumeData.educationExperiences.length > 0) {
                    this.originalResumeData.educationExperiences[0].school = data.school;
                    this.originalResumeData.educationExperiences[0].major = data.major;
                    this.originalResumeData.educationExperiences[0].degree = data.degree;
                    this.originalResumeData.educationExperiences[0].startDate = moment(data.educationStartDate).format('YYYY-MM') + '-01T00:00:00';
                    this.originalResumeData.educationExperiences[0].endDate = moment(data.educationEndDate).format('YYYY-MM') + '-01T00:00:00';
                } else {
                    this.originalResumeData.educationExperiences = [{
                        created: null,
                        degree: data.degree,
                        endDate: moment(data.educationEndDate).format('YYYY-MM') + '-01T00:00:00',
                        id: '',
                        major: data.major,
                        school: data.school,
                        startDate: moment(data.educationStartDate).format('YYYY-MM') + '-01T00:00:00',
                        updated: null
                    }];
                }
            }

            this.highlightCheck();
        },
        addItem(type) {
            // this.handleFormCancel();
            switch(type) {
                case 'workExperiences':
                    if (this.editWorkExperiencesIndex >= 0 && this.editWorkExperiencesIndex != 10000) {
                        let isWorkEdit = this.checkWorkEdit();
                        if (isWorkEdit) {
                            this.quitEditCb = () => {
                                this.$refs.compareResumeWorkForm._reset();
                                this.editWorkExperiencesIndex = 10000;
                                this.goAnchor('#compare-resume-work-form');
                            };
                            this.quitEditDialogVisible = true;
                        } else {
                            this.$refs.compareResumeWorkForm._reset();
                            this.editWorkExperiencesIndex = 10000;
                            this.goAnchor('#compare-resume-work-form');
                        }
                    } else {
                        this.$refs.compareResumeWorkForm._reset();
                        this.editWorkExperiencesIndex = 10000;
                        this.goAnchor('#compare-resume-work-form');
                    }

                    break;
                case 'educationExperiences':
                    if (this.editEducationExperiencesIndex >= 0 && this.editEducationExperiencesIndex != 10000) {
                        let isEducationEdit = this.checkEducationEdit();
                        if (isEducationEdit) {
                            this.quitEditCb = () => {
                                this.$refs.compareResumeEducationForm._reset();
                                this.editEducationExperiencesIndex = 10000;
                                this.goAnchor('#compare-resume-education-form');
                            };
                            this.quitEditDialogVisible = true;
                        } else {
                            this.$refs.compareResumeEducationForm._reset();
                            this.editEducationExperiencesIndex = 10000;
                            this.goAnchor('#compare-resume-education-form');
                        }
                    } else {
                        this.$refs.compareResumeEducationForm._reset();
                        this.editEducationExperiencesIndex = 10000;
                        this.goAnchor('#compare-resume-education-form');
                    }


                    // this.$refs.compareResumeEducationForm._reset();
                    // this.editEducationExperiencesIndex = 10000;
                    // this.goAnchor('#compare-resume-education-form');
                    break;
                case 'projectExperiences':
                    if (this.editProjectExperiencesIndex >= 0 && this.editProjectExperiencesIndex != 10000) {
                        let isProjectEdit = this.checkProjectEdit();
                        if (isProjectEdit) {
                            this.quitEditCb = () => {
                                this.$refs.compareResumeProjectForm._reset();
                                this.editProjectExperiencesIndex = 10000;
                                this.goAnchor('#compare-resume-project-form');
                            };
                            this.quitEditDialogVisible = true;
                        } else {
                            this.$refs.compareResumeProjectForm._reset();
                            this.editProjectExperiencesIndex = 10000;
                            this.goAnchor('#compare-resume-project-form');
                        }
                    } else {
                        this.$refs.compareResumeProjectForm._reset();
                        this.editProjectExperiencesIndex = 10000;
                        this.goAnchor('#compare-resume-project-form');
                    }
                    break;
                case 'selfEvaluation':
                    this.showSelfEvaluateEdit = true;
                    this.editSelfEvaluationIndex = 10000;
                    this.$nextTick(() => {
                        this.goAnchor('.block-item .self-evaluate-input');
                    })
                    break;
                case 'intention':
                    this.$refs.compareResumeIntentionForm._reset();
                    this.editIntentionsIndex = 10000;
                    this.goAnchor('#compare-resume-intention-form');
                    break;
                default: 
                    break;
            }
        },
        mergeCurrentWork(index) {
            return Object.assign(
                this.originalResumeData.workExperiences[index], 
                {
                    customerId: this.originalResumeData.company == this.originalResumeData.workExperiences[index].company ? this.originalResumeData.customerId: null,
                    currentCompany: this.originalResumeData.company,
                    tempCustomerId: this.originalResumeData.workExperiences[index].tempCustomerId || null,
                }
            )
        },
        expandWorkDescription(item) {
            item.descriptionExpand = true;
        },
        foldWorkDescription(item) {
            item.descriptionExpand = false;
        },
        expandProjectDescription(item) {
            item.descriptionExpand = true;
        },
        foldProjectDescription(item) {
            item.descriptionExpand = false;
        },
        expandProjectResponsibility(item) {
            item.responsibilityExpand = true;
        },
        foldProjectResponsibility(item) {
            item.responsibilityExpand = false;
        },
        replaceExperience(index, type) {
            this.replaceOriginIndex = index;

            switch (type) {
                case 'work':
                    this.$refs.replaceExperienceDialog.show({
                        type: 'work',
                        newList: this.resumeDataUpload.workExperiences
                    });
                    break;
                case 'education':
                    this.$refs.replaceExperienceDialog.show({
                        type: 'education',
                        newList: this.resumeDataUpload.educationExperiences
                    });
                    break;
                case 'project':
                    this.$refs.replaceExperienceDialog.show({
                        type: 'project',
                        newList: this.resumeDataUpload.projectExperiences
                    });
                    break;
                case 'intention':
                    this.$refs.replaceExperienceDialog.show({
                        type: 'intention',
                        newList: this.resumeDataUpload.intentions
                    });
                    break;
                default:
                    break;
            }            
        },
        handleReplaceSelected(index, type) {
            switch (type) {
                case 'work':
                    this.editWorkExperiencesIndex = -1;

                    let oldWork = this.originalResumeData.workExperiences[this.replaceOriginIndex];
                    this.replacedWorkMap[oldWork.id] = {
                        oldWork: oldWork,
                        newWorkIndex: index,
                        newWork: this.resumeDataUpload.workExperiences[index],
                    };
                    this.$set(this.resumeDataUpload.workExperiences[index], 'isReplaced', true);
                    // this.resumeDataUpload.workExperiences[index].isReplaced = true;
                    this.originalResumeData.workExperiences[this.replaceOriginIndex] = Object.assign({}, {
                        descriptionOverflow: false,
                        descriptionExpand: false
                    }, { replaceId: oldWork.id }, this.resumeDataUpload.workExperiences[index]);

                    this.sortWrokExperiences();

                    this.initExpandStatus(true);

                    break;
                case 'education':
                    this.editEducationExperiencesIndex = -1;

                    let oldEducation = this.originalResumeData.educationExperiences[this.replaceOriginIndex];

                    this.replacedEducationMap[oldEducation.id] = {
                        oldEducation: oldEducation,
                        newEducationIndex: index,
                        newEducation: this.resumeDataUpload.educationExperiences[index],
                    };
                    this.$set(this.resumeDataUpload.educationExperiences[index], 'isReplaced', true);
                    // this.resumeDataUpload.workExperiences[index].isReplaced = true;
                    this.originalResumeData.educationExperiences[this.replaceOriginIndex] = Object.assign({}, { replaceId: oldEducation.id }, this.resumeDataUpload.educationExperiences[index]);

                    this.sortEducationExperiences();
                    break;
                case 'project':
                    this.editProjectExperiencesIndex = -1;
                    this.projectItemExpandIndex = -1;

                    let oldProject = this.originalResumeData.projectExperiences[this.replaceOriginIndex];

                    this.replacedProjectMap[oldProject.id] = {
                        oldProject: oldProject,
                        newProjectIndex: index,
                        newProject: this.resumeDataUpload.projectExperiences[index],
                    };
                    this.$set(this.resumeDataUpload.projectExperiences[index], 'isReplaced', true);
                    // this.resumeDataUpload.workExperiences[index].isReplaced = true;
                    this.originalResumeData.projectExperiences[this.replaceOriginIndex] = Object.assign({
                        descriptionOverflow: false,
                        descriptionExpand: false,
                        responsibilityOverflow: false,
                        responsibilityExpand: false
                    }, { replaceId: oldProject.id }, this.resumeDataUpload.projectExperiences[index]);

                    this.sortProjectExperiences();

                    this.initExpandStatus(true);

                    break;
                case 'intention':
                    this.editIntentionsIndex = -1;

                    let oldIntention = this.originalResumeData.intentions[this.replaceOriginIndex];

                    this.replacedIntentionMap[oldIntention.id] = {
                        oldIntention: oldIntention,
                        newIntentionIndex: index,
                        newIntention: this.resumeDataUpload.intentions[index],
                    };
                    this.$set(this.resumeDataUpload.intentions[index], 'isReplaced', true);
                    // this.resumeDataUpload.workExperiences[index].isReplaced = true;
                    this.originalResumeData.intentions[this.replaceOriginIndex] = Object.assign({}, { replaceId: oldIntention.id }, this.resumeDataUpload.intentions[index]);

                    break;
                default:
                    break;
            }
        },
        revokeExperience(index, type) {
            this.revokeDialogVisible = true;
            this.revokeType = type;
            this.revokeIndex = index;
        },
        handleCancelRevoke() {
            this.revokeIndex = -1;
            this.revokeDialogVisible = false;
        },
        handleRevokeExperience() {
            let type = this.revokeType;
            let index = this.revokeIndex;
            let id;

            switch(type) {
                case 'work':
                    id = this.originalResumeData.workExperiences[index].replaceId;
                    if (this.replacedWorkMap[id]) {
                        let replaceMap = this.replacedWorkMap[id];
                        this.$set(this.resumeDataUpload.workExperiences[replaceMap.newWorkIndex], 'isReplaced', false);
                        this.originalResumeData.workExperiences[index] = replaceMap.oldWork;
                    }
                    this.revokeDialogVisible = false;
                    this.sortWrokExperiences();
                    shortTips('已撤销替换');
        
                    delete this.replacedWorkMap[id];
                    break;
                case 'education':
                    id = this.originalResumeData.educationExperiences[index].replaceId;
                    if (this.replacedEducationMap[id]) {
                        let replaceMap = this.replacedEducationMap[id];
                        this.$set(this.resumeDataUpload.educationExperiences[replaceMap.newEducationIndex], 'isReplaced', false);
                        this.originalResumeData.educationExperiences[index] = replaceMap.oldEducation;
                    }
                    this.revokeDialogVisible = false;
                    this.sortEducationExperiences();
                    shortTips('已撤销替换');

                    delete this.replacedEducationMap[id];
                    break;
                case 'project':
                    id = this.originalResumeData.projectExperiences[index].replaceId;
                    if (this.replacedProjectMap[id]) {
                        let replaceMap = this.replacedProjectMap[id];
                        this.$set(this.resumeDataUpload.projectExperiences[replaceMap.newProjectIndex], 'isReplaced', false);
                        this.originalResumeData.projectExperiences[index] = replaceMap.oldProject;
                    }
                    this.revokeDialogVisible = false;
                    this.sortProjectExperiences();
                    shortTips('已撤销替换');

                    delete this.replacedProjectMap[id];
                    break;
                case 'intention':
                    id = this.originalResumeData.intentions[index].replaceId;
                    if (this.replacedIntentionMap[id]) {
                        let replaceMap = this.replacedIntentionMap[id];
                        this.$set(this.resumeDataUpload.intentions[replaceMap.newIntentionIndex], 'isReplaced', false);
                        this.originalResumeData.intentions[index] = replaceMap.oldIntention;
                    }
                    this.revokeDialogVisible = false;
                    shortTips('已撤销替换');

                    delete this.replacedProjectMap[id];
                    break;
                default:
                    break;
            }

        },
        handleWorkExperiencesEdit(index) {
            if (this.editWorkExperiencesIndex != index) {
                let isWorkEdit = this.checkWorkEdit();
                if (isWorkEdit) {
                    this.quitEditCb = () => {
                        this.editWorkExperiencesIndex = index;
                        
                        let targetEl = this.$el.querySelectorAll('.work .block-item-left .normal-item')[index];
                        targetEl && this.goAnchorByEl(targetEl);
                    };
                    this.quitEditDialogVisible = true;
                } else {
                    this.editWorkExperiencesIndex = index;

                    let targetEl = this.$el.querySelectorAll('.work .block-item-left .normal-item')[index];
                    targetEl && this.goAnchorByEl(targetEl);
                    // this.goAnchor('#compare-resume-work-form');
                }
            }
        },
        checkWorkEdit() {
            let result = false;

            if (this.editWorkExperiencesIndex >= 0 && this.$refs.editResumeWorkForm && this.$refs.editResumeWorkForm.length > 0) {
                let originWork = this.originalResumeData.workExperiences[this.editWorkExperiencesIndex];
                let editWork = this.$refs.editResumeWorkForm[0].formData;

                if (originWork['company'] != editWork['company']) result = true;
                if (originWork['title'] != editWork['title']) result = true;
                if (originWork['startDate'] != editWork['startDate']) result = true;
                if (originWork['location'] != editWork['location']) result = true;
                if (originWork['description'] != editWork['description']) result = true;
                // let compareArr = ['comapny', 'title', 'startDate', 'location', 'description'];
                // compareArr.some(item => {
                //     console.log(originWork[item]);
                //     if (originWork[item] != editWork[item]) {
                //         result = true;
                //         return true;
                //     }
                // });
                if (!result 
                    && originWork['endDate'] != editWork['endDate']
                    && !(originWork['endDate'] == '9999-12-31T00:00:00' && editWork['endDate'] == '')
                ) {
                    result = true;
                }
            }

            return result;
        },
        // handleCancelQuitEditWork() {
        //     this.quitEditDialogVisible = false;
        // },
        handleQuitEdit() {
            this.quitEditCb();
            this.quitEditDialogVisible = false;
        },
        handleAddWorkToLeft(work, index) {
            work.rightListIndex = index;

            // this.originalResumeData.workExperiences.push(work);

            this.originalResumeData.workExperiences.push(Object.assign({}, {
                descriptionOverflow: false,
                descriptionExpand: false
            }, work));
            this.sortWrokExperiences();
            this.initExpandStatus(true);
            work.hasAddToLeft = true;
        },
        deleteLeftWorkFromRight(work, index) {

            this.originalResumeData.workExperiences.splice(index, 1);

            this.syncBasicWorkInfo();

            this.resumeDataUpload.workExperiences[work.rightListIndex].hasAddToLeft = false;
        },
        handleAddEducationToLeft(education, index) {
            education.rightListIndex = index;

            this.originalResumeData.educationExperiences.push(Object.assign({}, education));
            this.sortEducationExperiences();

            education.hasAddToLeft = true;
        },
        deleteLeftEducationFromRight(education, index) {
            this.originalResumeData.educationExperiences.splice(index, 1);

            this.resumeDataUpload.educationExperiences[education.rightListIndex].hasAddToLeft = false;

            this.syncBasicEducationInfo();
        },
        handleEducationExperiencesEdit(index) {
            if (this.editEducationExperiencesIndex != index) {
                let isEducationEdit = this.checkEducationEdit();
                if (isEducationEdit) {
                    this.quitEditCb = () => {
                        this.editEducationExperiencesIndex = index;

                        let targetEl = this.$el.querySelectorAll('.education .block-item-left .normal-item')[index];
                        targetEl && this.goAnchorByEl(targetEl);
                        // this.goAnchor('#compare-resume-education-form');
                    };
                    this.quitEditDialogVisible = true;
                } else {
                    this.editEducationExperiencesIndex = index;

                    let targetEl = this.$el.querySelectorAll('.education .block-item-left .normal-item')[index];
                    targetEl && this.goAnchorByEl(targetEl);
                    // this.goAnchor('#compare-resume-education-form');
                }
            }
        },
        checkEducationEdit() {
            let result = false;

            if (this.editEducationExperiencesIndex >= 0 && this.$refs.editResumeEducationForm && this.$refs.editResumeEducationForm.length > 0) {
                let originEducation = this.originalResumeData.educationExperiences[this.editEducationExperiencesIndex];
                let editEducation = this.$refs.editResumeEducationForm[0].formData;

                if (originEducation['school'] != editEducation['school']) result = true;
                if (originEducation['degree'] != editEducation['degree']) result = true;
                if (originEducation['startDate'] != editEducation['startDate']) result = true;
                if (originEducation['major'] != editEducation['major']) result = true;
                if (!result 
                    && originEducation['endDate'] != editEducation['endDate']
                    && !(originEducation['endDate'] == '9999-12-31T00:00:00' && editEducation['endDate'] == '')
                ) {
                    result = true;
                }
            }

            return result;
        },
        handleAddProjectToLeft(project, index) {
            project.rightListIndex = index;

            this.originalResumeData.projectExperiences.push(Object.assign({}, {
                descriptionOverflow: false,
                descriptionExpand: false,
                responsibilityOverflow: false,
                responsibilityExpand: false
            }, project));
            this.sortProjectExperiences();
            this.initExpandStatus(true);
            project.hasAddToLeft = true;
        },
        deleteLeftProjectFromRight(project, index) {
            this.originalResumeData.projectExperiences.splice(index, 1);

            this.resumeDataUpload.projectExperiences[project.rightListIndex].hasAddToLeft = false;
        },
        handleProjectExperiencesEdit(index) {
            if (this.editProjectExperiencesIndex != index) {
                let isProjectEdit = this.checkProjectEdit();
                if (isProjectEdit) {
                    this.quitEditCb = () => {
                        this.editProjectExperiencesIndex = index;

                        let targetEl = this.$el.querySelectorAll('.project .block-item-left .normal-item')[index];
                        targetEl && this.goAnchorByEl(targetEl);
                        // this.goAnchor('#compare-resume-project-form');
                    };
                    this.quitEditDialogVisible = true;
                } else {
                    this.editProjectExperiencesIndex = index;

                    let targetEl = this.$el.querySelectorAll('.project .block-item-left .normal-item')[index];
                    targetEl && this.goAnchorByEl(targetEl);
                    // this.goAnchor('#compare-resume-project-form');
                }
            }
        },
        checkProjectEdit() {
            let result = false;

            if (this.editProjectExperiencesIndex >= 0 && this.$refs.editResumeProjectForm && this.$refs.editResumeProjectForm.length > 0) {
                let originProject = this.originalResumeData.projectExperiences[this.editProjectExperiencesIndex];
                let editProject = this.$refs.editResumeProjectForm[0].formData;

                if (originProject['projectName'] != editProject['projectName']) result = true;
                if (originProject['projectRole'] != editProject['projectRole']) result = true;
                if (originProject['startDate'] != editProject['startDate']) result = true;
                if (originProject['responsibility'] != editProject['responsibility']) result = true;
                if (originProject['description'] != editProject['description']) result = true;
                if (!result 
                    && originProject['endDate'] != editProject['endDate']
                    && !(originProject['endDate'] == '9999-12-31T00:00:00' && editProject['endDate'] == '')
                ) {
                    result = true;
                }
            }

            return result;
        },
        handleSelfEvaluateEdit() {
            this.originalSelfEvaluate = this.originalResumeData.selfEvaluation;
            this.showSelfEvaluateEdit = true;
            this.$nextTick(() => {
                this.goAnchor('.page-content .self-evaluate-input');
                document.querySelector('.page-content .self-evaluate-input textarea') && document.querySelector('.page-content .self-evaluate-input textarea').focus();
            });

        },
        handleCancelEditSelfEvaluate() {
            this.originalResumeData.selfEvaluation = this.originalSelfEvaluate;
            this.showSelfEvaluateEdit = false;
        },
        handleSaveSelfEvaluate() {
            this.showSelfEvaluateEdit = false;
            this.originalResumeData.selfEvaluation = this.originalResumeData.selfEvaluation.trim();
        },
        replaceSelfEvaluate() {
            this.replaceSelfEvaluateDialogVisible = true;
        },
        handleReplaceSelfEvaluate() {
            this.originalResumeData.selfEvaluation = this.resumeDataUpload.selfEvaluation;
            this.replaceSelfEvaluateDialogVisible = false;
            this.replaceSelfEvaluateBtnVisible = false;
        },
        handleAddSelfEvaluateToLeft() {
            this.showSelfEvaluateEdit = false;
            this.replaceSelfEvaluateBtnVisible = false;
            this.originalResumeData.selfEvaluation = this.resumeDataUpload.selfEvaluation;
        },
        handleDeleteSelfEvaluate() {
            this.showSelfEvaluateEdit = false;
            this.originalResumeData.selfEvaluation = '';
        },
        handleIntentionEdit(index) {
            if (this.activeBlockIndex != 5) {
                this.activeBlock = 'intention';
                this.activeBlockIndex = 5;
            }

            if (this.editIntentionsIndex != index) {
                let isIntentionEdit = this.checkIntentionEdit();
                if (isIntentionEdit) {
                    this.quitEditCb = () => {
                        this.editIntentionsIndex = index;
                        this.goAnchor('#compare-resume-intention-form');
                    };
                    this.quitEditDialogVisible = true;
                } else {
                    this.editIntentionsIndex = index;
                    this.goAnchor('#compare-resume-intention-form');
                }
            }
        },
        checkIntentionEdit() {
            let result = false;

            if (this.editIntentionsIndex >= 0 && this.$refs.editResumeIntentionForm && this.$refs.editResumeIntentionForm.length > 0) {
                let originIntention = this.originalResumeData.intentions[this.editIntentionsIndex];
                let editIntention = this.$refs.editResumeIntentionForm[0].formData;

                if (originIntention['title'] != editIntention['title']) result = true;
                if (originIntention['locationId'] != editIntention['locationId']) result = true;
                if (originIntention['minSalary'] != editIntention['minSalary']) result = true;
                if (originIntention['maxSalary'] != editIntention['maxSalary']) result = true;
            }

            return result;
        },
        handleAddIntentionToLeft(intention, index) {
            intention.rightListIndex = index;

            if (this.activeBlockIndex != 5) {
                this.activeBlock = 'intention';
                this.activeBlockIndex = 5;
            }
            // this.originalResumeData.workExperiences.push(work);

            let curList = this.originalResumeData.intentions;
            curList.splice(0, 0, intention);
            this.originalResumeData.intentions = curList;
            intention.hasAddToLeft = true;
        },
        deleteLeftIntentionFromRight(intention, index) {
            this.originalResumeData.intentions.splice(index, 1);

            this.resumeDataUpload.intentions[intention.rightListIndex].hasAddToLeft = false;
        },
        handleFormCancel(key) {
            this.editWorkExperiencesIndex = -1;

            this.editIntentionsIndex = -1;
            this.editEducationExperiencesIndex= -1;
            // editWorkExperiencesIndex
            // editWorkExperiencesIndex
            this.editProjectExperiencesIndex= -1;
            this.editSelfEvaluationIndex= -1;

            if (key) {
                let targetEl = this.$el.querySelector(`.page-content .block-item.${key}`);
                targetEl && this.goAnchorByEl(targetEl);
            }
        },
        handleSaveBtnCallback(val, type) {
            console.log(arguments)

            const _name = type.slice(0,1).toUpperCase() + type.slice(1);
            const idx = this.$data[`edit${_name}Index`];

            this.editItem(val, idx, type);

            setTimeout(() => {
                this.handleFormCancel();
            }, 300);
        },
        handleBlockTabClick(tab) {
            console.log(tab);
            this.activeBlock = tab.name;
            this.goAnchor(`.page-content .block-item.${tab.name}`, 'instant');

            let scrollEl = document.querySelector('.page-content-wrapper');
            scrollEl.removeEventListener('scroll', this.handleScrollEvent);

            setTimeout(() => {
                let scrollEl = document.querySelector('.page-content-wrapper');
                scrollEl.addEventListener('scroll', this.handleScrollEvent);
            }, 300);

            switch (tab.name) {
                case 'basic':
                    this.activeBlockIndex = 0;
                    break;
                case 'work':
                    this.activeBlockIndex = 1;
                    break;
                case 'education':
                    this.activeBlockIndex = 2;
                    break;
                case 'project':
                    this.activeBlockIndex = 3;
                    break;
                case 'evaluate':
                    this.activeBlockIndex = 4;
                    break;
                case 'intention':
                    this.activeBlockIndex = 5;
                    break;
                default:
                    this.activeBlockIndex = 0;
                    break;
            }
        },
        init(){
            this.originalResumeId = this.$route.query.originalResumeId;
            this.isXlsAnonymous = this.$route.query.isAnonymous === 'true' ? true : false;
            this.isExcelUpload = this.$route.query.isExcelUpload == '1' ? true : false;
            this.isXLSEdit = encodeURI(decodeURI(this.$route.query.file)).indexOf('xlsEditFile') > -1 ? true : false;
            if(!this.extensionMode){
                this.CompareResume();
            } else {
                // window.onload = this.handleUploadData;
                this.handleUploadData();
            }
        },
        handleUploadData(){
            //是否为补充简历
            // this.fileName = encodeURI(decodeURI(getUrlParam('file')));
            this.fileName = encodeURI(decodeURI(this.$route.query.file));
            if(this.fileName !== ''){
                let fileData = JSON.parse(sessionStorage.getItem(this.fileName) || sessionStorage.getItem(encodeURIComponent(this.fileName)));
                if(fileData.workExperiences && fileData.workExperiences.length) {
                    fileData.company = fileData.company ? fileData.company : fileData.workExperiences[0].company;
                    fileData.title = fileData.title ? fileData.title : fileData.workExperiences[0].title;
                }
                if(fileData.educationExperiences && fileData.educationExperiences.length) {
                    fileData.school = fileData.school ? fileData.school : fileData.educationExperiences[0].school;
                    fileData.degree = fileData.degree && fileData.degree !== '0' ? fileData.degree : fileData.educationExperiences[0].degree;
                    fileData.major = fileData.major ? fileData.major : fileData.educationExperiences[0].major;
                } else {
                    if(!this.isExcelUpload) {
                        fileData.school = "";
                        fileData.degree = null;
                        fileData.major = "";
                    }
                }
                this.$set(this,'resumeDataUpload', fileData);
                if(this.extensionMode){
                    this.CompareResume();
                }
            }
        },

        CompareResume(){
            this.loadingCheck = true;
            if(this.extensionMode) {
                const params = {
                    ...this.resumeDataUpload,
                    resumeId: this.originalResumeId
                };
                _request({
                    method: 'POST',
                    url: `/My/Candidate/CompareResume`,
                    data: params,
                })
                .then((r) =>{
                    r.editResume.workExperiences = r.editResume.workExperiences ? r.editResume.workExperiences : [];
                    r.editResume.projectExperiences = r.editResume.projectExperiences ? r.editResume.projectExperiences : [];
                    r.editResume.workExperiences.forEach(item => {
                        item.descriptionOverflow = false;
                        item.descriptionExpand = false;
                    });
                    r.editResume.projectExperiences.forEach(item => {
                        item.descriptionOverflow = false;
                        item.descriptionExpand = false;
                        item.responsibilityOverflow = false;
                        item.responsibilityExpand = false;
                    });

                    r.createResume.workExperiences && r.createResume.workExperiences.forEach(item => {
                        item.hasAddToLeft = false;
                    });
                    r.createResume.selfEvaluation = r.createResume.selfEvaluation ? r.createResume.selfEvaluation.trim() : '';
                    r.editResume.realName = r.editResume.realName.trim();
                    r.editResume.selfEvaluation = r.editResume.selfEvaluation ? r.editResume.selfEvaluation.trim() : '';
                    this.resumeDataUpload = r.createResume;
                    this.originalResumeData = r.editResume;
                    if (r.createResume.fileId) {
                        this.originalResumeData.fileId = r.createResume.fileId;
                    }
                    if (r.createResume.fileName) {
                        this.originalResumeData.fileName = r.createResume.fileName;
                    }
                    this.moreComplete = r.createResumeIsMoreComplete;
                    this.isFresher = r.createResumeIsFresher;
                    this.uncompleteArr = r.differentFields || [];
                    this.requiredArr = r.requiredFields || [];

                    this.deleteEmptyItem();
                    this.initExpandStatus();
                    this.getCompareResult();
                }).finally(() => {
                    this.loadingCheck = false;
                })
            } else {
                // this.resumeId = getUrlParam('resumeId');
                // request(`/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`, {}, 'get', true, true)

                this.resumeId = this.$route.query.resumeId;
                _request({
                    method: 'GET',
                    url: `/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`
                })
                .then((r) => {
                    r.editResume.workExperiences = r.editResume.workExperiences ? r.editResume.workExperiences : [];
                    r.editResume.projectExperiences = r.editResume.projectExperiences ? r.editResume.projectExperiences : [];
                    r.editResume.workExperiences.forEach(item => {
                        item.descriptionOverflow = false;
                        item.descriptionExpand = false;
                    });
                    r.editResume.projectExperiences.forEach(item => {
                        item.descriptionOverflow = false;
                        item.descriptionExpand = false;
                        item.responsibilityOverflow = false;
                        item.responsibilityExpand = false;
                    });

                    r.createResume.workExperiences && r.createResume.workExperiences.forEach(item => {
                        item.hasAddToLeft = false;
                    });
                    r.createResume.selfEvaluation = r.createResume.selfEvaluation ? r.createResume.selfEvaluation.trim() : '';
                    r.editResume.realName = r.editResume.realName.trim();
                    r.editResume.selfEvaluation = r.editResume.selfEvaluation ? r.editResume.selfEvaluation.trim() : '';

                    this.resumeDataUpload = r.createResume;
                    this.originalResumeData = r.editResume;
                    if (r.createResume.fileId) {
                        this.originalResumeData.fileId = r.createResume.fileId;
                    }
                    if (r.createResume.fileName) {
                        this.originalResumeData.fileName = r.createResume.fileName;
                    }
                    this.moreComplete = r.createResumeIsMoreComplete;
                    this.isFresher = r.createResumeIsFresher;
                    this.uncompleteArr = r.differentFields || [];
                    this.requiredArr = r.requiredFields || [];

                    this.deleteEmptyItem();
                    this.initExpandStatus();
                    this.getCompareResult();
                }).finally(() => {
                    this.loadingCheck = false;
                })
            }
        },

        deleteEmptyItem() {
            this.resumeDataUpload.workExperiences = this.resumeDataUpload.workExperiences ? this.resumeDataUpload.workExperiences.filter(it => it.company) : [];
            this.resumeDataUpload.educationExperiences = this.resumeDataUpload.educationExperiences ? this.resumeDataUpload.educationExperiences.filter(it => it.school) : [];
            this.resumeDataUpload.projectExperiences = this.resumeDataUpload.projectExperiences ? this.resumeDataUpload.projectExperiences.filter(it => it.projectName) : [];
            this.resumeDataUpload.intentions = this.resumeDataUpload.intentions ? this.resumeDataUpload.intentions.filter(it => it.title) : [];
        },

        initExpandStatus(isEdit) {
            if (this.originalResumeData.workExperiences && this.originalResumeData.workExperiences.length > 0) {
                this.$nextTick(() => {
                    let workDescEls = document.querySelectorAll('.work .normal-item-expand .expand-content') || [];
                    console.log(workDescEls);
                    workDescEls.forEach((descEl, index) => {
                        if (isEdit && this.originalResumeData.workExperiences[index].descriptionOverflow) {
                            return false;
                        }
                        if (descEl && descEl.getBoundingClientRect().height > 30) {
                            this.originalResumeData.workExperiences[index].descriptionOverflow = true;
                            this.originalResumeData.workExperiences[index].descriptionExpand = false;
                        } else {
                            this.originalResumeData.workExperiences[index].descriptionOverflow = false;
                            this.originalResumeData.workExperiences[index].descriptionExpand = false;
                        }
                    })
                });
            }

            if (this.originalResumeData.projectExperiences && this.originalResumeData.projectExperiences.length > 0) {
                this.$nextTick(() => {
                    let projectDescEls = document.querySelectorAll('.project .normal-item-expand .desc');
                    console.log(projectDescEls);
                    projectDescEls.forEach((descEl, index) => {
                        if (isEdit && this.originalResumeData.projectExperiences[index].descriptionOverflow) {
                            return false;
                        }
                        if (descEl && descEl.getBoundingClientRect().height > 30) {
                            this.originalResumeData.projectExperiences[index].descriptionOverflow = true;
                            this.originalResumeData.projectExperiences[index].descriptionExpand = false;
                        } else {
                            this.originalResumeData.projectExperiences[index].descriptionOverflow = false;
                            this.originalResumeData.projectExperiences[index].descriptionExpand = false;
                        }
                    });

                    let responsibilityEls = document.querySelectorAll('.project .normal-item-expand .responsibility');
                    console.log(responsibilityEls);
                    responsibilityEls.forEach((responsibilityEl, index) => {
                        if (isEdit && this.originalResumeData.projectExperiences[index].responsibilityOverflow) {
                            return false;
                        }
                        if (responsibilityEl && responsibilityEl.getBoundingClientRect().height > 30) {
                            this.originalResumeData.projectExperiences[index].responsibilityOverflow = true;
                            this.originalResumeData.projectExperiences[index].responsibilityExpand = false;
                        } else {
                            this.originalResumeData.projectExperiences[index].responsibilityOverflow = false;
                            this.originalResumeData.projectExperiences[index].responsibilityExpand = false;
                        }
                    })
                });
            }
        },

        getCompareResult(){
            //判断是否解析出必填项
            if(!this.resumeDataUpload.company && this.resumeDataUpload.workExperiences && this.resumeDataUpload.workExperiences.length > 0){
                this.$set(this.resumeDataUpload,'company',this.resumeDataUpload.workExperiences[0].company);
                this.$set(this.resumeDataUpload.workExperiences[0],'isLatestWorkExperience',true);
            }
            if(!this.resumeDataUpload.title && this.resumeDataUpload.workExperiences && this.resumeDataUpload.workExperiences.length > 0){
                this.$set(this.resumeDataUpload,'title',this.resumeDataUpload.workExperiences[0].title);
            }
            // if(this.resumeDataUpload.yearOfExperience>=10){
            //     this.$set(this.resumeDataUpload,'yearOfExperience',10);
            // }
            this.$set(this.resumeDataUpload, 'isXLSEdit', this.isXLSEdit);

            this.checkIfExperiencesExists();
            this.highlightCheck();
            if(this.saveBtnClick) {
                this.beforeUpdateRecume2();
            }
        },
        checkIfExperiencesExists() {
            this.resumeDataUpload.workExperiences.forEach((item, index) => {
                let isNew = true;
                this.originalResumeData.workExperiences.some(work => {
                    if (item.company == work.company) {
                        isNew = false;
                        return true;
                    }
                });
                this.$set(this.resumeDataUpload.workExperiences[index], 'isNew', isNew);
            });

            this.resumeDataUpload.educationExperiences.forEach((item, index) => {
                let isNew = true;
                this.originalResumeData.educationExperiences.some(education => {
                    if ((item.school == education.school) && (item.degree == education.degree) && (item.major == education.major)) {
                        isNew = false;
                        return true;
                    }
                });
                this.$set(this.resumeDataUpload.educationExperiences[index], 'isNew', isNew);
            });

            this.resumeDataUpload.projectExperiences.forEach((item, index) => {
                let isNew = true;
                this.originalResumeData.projectExperiences.some(project => {
                    if ((item.projectName == project.projectName)) {
                        isNew = false;
                        return true;
                    }
                });
                this.$set(this.resumeDataUpload.projectExperiences[index], 'isNew', isNew);
            });

            this.resumeDataUpload.intentions.forEach((item, index) => {
                let isNew = true;
                this.originalResumeData.intentions.some(intention => {
                    if ((item.title == intention.title) 
                        && (item.locationId == intention.locationId)
                        && (item.minSalary == intention.minSalary)
                        && (item.maxSalary == intention.maxSalary)
                    ) {
                        isNew = false;
                        return true;
                    }
                });
                this.$set(this.resumeDataUpload.intentions[index], 'isNew', isNew);
            });
        },
        continueEdit() {
            this.updateTipDialogShow = false;
        },

        continueEdit2(isUncompleteTip){
            // ga('send', 'event', "猎头端-更新简历", "继续编辑", `${userID}-${userFirmShortName}-${userRealName}`);
            let resumeData = Object.assign({}, this.resumeDataUpload);
            let resumeBasicInformation = {
                "isExisted":false,
                "existedId":null,
                "realName": "",
                "gender": 0,
                "mobile": "",
                "title": "",
                "email": "",
                "wechat": "",
                "source": null,
                "company": "",
                "yearOfExperience": 0,
                "province": "",
                "city": null,
                "content": "",
                "extraInfo": null,
                "jobHoppingOccasion": null,
                "employerInfo": null,
                "expectSalary": null,
                "school": "",
                "major": "",
                "degree": null,
                "fileId": "",
                "autoApprovelTrading": false,
                "selfEvaluation": '',
                "workExperiences": [],
                "educationExperiences": [],
                "projectExperiences": [],
                "intentions": [],
                "isPersonalCreate": false
            };
            Object.assign(resumeBasicInformation, resumeData);
            if(!resumeBasicInformation.workExperiences || (resumeBasicInformation.workExperiences && resumeBasicInformation.workExperiences.length == 0)) {
                resumeBasicInformation.workExperiences = [];
                resumeBasicInformation.workExperiences.push({
                    "company": resumeData.company || "",
                    "title": resumeData.title || "",
                    "description": null,
                    "isLatestWorkExperience": true,
                    "location": 0,
                    "startDate": null,
                    "endDate": null,
                    "created": "",
                    "updated": "",
                    //"showPopover": false,
                    "locationArr": [],
                    "startDateError": false,
                    "endDateError": false,
                    "dateRange":[]
                });
            }
            if(!resumeBasicInformation.educationExperiences || (resumeBasicInformation.educationExperiences && resumeBasicInformation.educationExperiences.length == 0)) {
                resumeBasicInformation.educationExperiences = [];
                resumeBasicInformation.educationExperiences.push({
                    "school": resumeData.school || "",
                    "major": "",
                    "degree": resumeData.degree || null,
                    "startDate": null,
                    "endDate": null,
                    "created": "",
                    "updated": "",
                    //"showPopover": false,
                    "startDateError": false,
                    "endDateError": false,
                    "dateRange":[]
                });
            }
            if(!this.fileName) {
                this.fileName = this.isXLSEdit ? 'xlsEditFile' : 'singleEditFile';
            }
            if(this.duplicateType === 2 && this.uncompleteArr.length > 0 && isUncompleteTip) {
                resumeBasicInformation.unCompleteFields = this.uncompleteArr;
                if(this.uncompleteArr.indexOf('degree') > -1) {
                    resumeBasicInformation.educationExperiences.forEach(item => {
                        if(!item.degree) {
                            item.degree = null;
                        }
                    })
                }
            }
            sessionStorage.setItem(this.fileName, JSON.stringify(resumeBasicInformation));
            // location.href = `/Headhunting/MyCompany.html?type=1&file=${this.fileName}#/resumeUpload/singleUpload`;
            location.href = `/Headhunting/MyCompany.html?type=1&file=${this.fileName}#/resumeUpload/singleUpload`;
        },

        giveUpEdit(){
            // ga('send', 'event', "猎头端-更新简历", "放弃更新", `${userID}-${userFirmShortName}-${userRealName}`);
            // window.close();
            location.href = `/Headhunting/MyCompany.html#/candidateDetail/${this.originalResumeData.id}`;
        },

        beforeUpdateRecume() {
            if(this.duplicateType === 2 && this.uncompleteArr.length > 0) {
                let uncompleteTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.uncompleteArr.length; i++) {
                    toLowerCaseTxt = this.uncompleteArr[i].charAt(0).toLowerCase() + this.uncompleteArr[i].slice(1);
                    this.uncompleteArr[i] = toLowerCaseTxt;
                    if(fieldMap[toLowerCaseTxt]) {
                        uncompleteTextArr.push(fieldMap[toLowerCaseTxt])
                    }
                }
                this.updateTip = `你上传的简历，对比原简历，缺失字段：<span class="text-orange">${uncompleteTextArr.join("、")}</span><br/>请补充完整后再更新。`
                this.updateTipDialogShow = true;
            } else if((this.duplicateType === 0 || this.duplicateType === 2 ) && this.requiredArr.length > 0) {
                let requiredTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.requiredArr.length; i++) {
                    toLowerCaseTxt = this.requiredArr[i].charAt(0).toLowerCase() + this.requiredArr[i].slice(1);
                    this.requiredArr[i] = toLowerCaseTxt;
                    if(fieldMap[toLowerCaseTxt]) {
                        requiredTextArr.push(fieldMap[toLowerCaseTxt])
                    }
                }
                this.updateTip = `你上传的简历，缺失必填项：<span class="text-orange">${requiredTextArr.join("、")}</span><br/>请补充后再更新。`
                this.updateTipDialogShow = true;
            } else if(this.duplicateType === 2) {
                this.$confirm('你上传的简历没有原简历完整，确定直接更新吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '放 弃',
                    type: 'warning',
                    center: false
                }).then(({ value }) => {
                    this.confirmAndSubmit();
                }).catch(() => {
                    this.hideUpdateTipDialog();       
                });
            } else {
                this.confirmAndSubmit();
            }
        },
        confirmAndSubmit(){
            // let loadingInstance = Loading.service({
            //     target: this.$refs.pageContent,
            //     customClass: 'page-loading'
            // });
            this.loadingCheck = true;
            let _self = this;
            this.$set(this.resumeDataUpload, 'id', this.originalResumeData.id);
            this.$set(this.resumeDataUpload, 'isUploadResume', true);
            // request('/My/Candidate/' + this.originalResumeData.id + '/Edit', this.resumeDataUpload, 'post', true, true)
            _request({
                method: 'POST',
                url: '/My/Candidate/' + this.originalResumeData.id + '/Edit',
                data: this.resumeDataUpload,
            })
            .then((r) => {
                if(_self.duplicateType === 2 && !_self.uncompleteArr.length) {
                    let today = new Date();
                    let month = today.getMonth() + 1,
                        date = today.getDate(),
                        hour = today.getHours(),
                        minutes = today.getMinutes(),
                        seconds = today.getSeconds();
                    month = month > 9 ? month : '0' + month;
                    date = date > 9 ? date : '0' + date;
                    hour = hour > 9 ? hour : '0' + hour
                    minutes = minutes > 9 ? minutes : '0' + minutes
                    seconds = seconds > 9 ? seconds : '0' + seconds
                    let timeText = `${today.getFullYear()}/${month}/${date} ${hour}:${minutes}:${seconds}`
                    this.upaRequest(timeText, () => {
                        shortTips('更新成功！');
                        setTimeout(() => {
                            // location.href = '/Headhunting/MyCompany.html#/candidateDetail/' + this.originalResumeData.id;
                            location.href = '/Headhunting/MyCompany.html#/candidateDetail/' + this.originalResumeData.id;
                        }, 1000)
                        // ga('send', 'event', "猎头端-更新简历", "简历覆盖", `${userID}-${userFirmShortName}-${userRealName}`);
                    });
                } else {
                    shortTips('更新成功！');
                    setTimeout(() => {
                        // location.href = '/Headhunting/MyCompany.html#/candidateDetail/' + this.originalResumeData.id;
                        location.href = '/Headhunting/MyCompany.html#/candidateDetail/' + this.originalResumeData.id;
                    }, 1000)
                    // ga('send', 'event', "猎头端-更新简历", "简历覆盖", `${userID}-${userFirmShortName}-${userRealName}`);
                }
            }).finally(() => {
                this.loadingCheck = false;
            })
        },
        hideUpdateTipDialog() {
            this.updateTipDialogShow = false;
        },
        upaRequest(timeText, cb){
            const params = {
                eventCode: 'UpdateUnCompleteResume',
                eventData: JSON.stringify({
                    id: this.originalResumeData.id,
                    updateTime: timeText
                }),
                pageCode: location.pathname,
                pageViewId: $('[name=pageViewId]').val() || window._pageViewId || ""
            };
            _request({
                method: 'POST',
                url: '/UPA/events',
                data: params,
            }).then((r) => {
                cb && cb();
            })
        },


        addToNewItem (item, type) {
            console.log('kk---------1', arguments)
            switch (type) {
                case 'workExperiences':
                    if(this.resumeDataUpload.workExperiences) {
                        this.resumeDataUpload.workExperiences.unshift(item);
                    } else {
                        this.resumeDataUpload.workExperiences = [item];
                    }
                    break;
                case 'educationExperiences':
                    if(this.resumeDataUpload.educationExperiences) {
                        this.resumeDataUpload.educationExperiences.unshift(item);
                    } else {
                        this.resumeDataUpload.educationExperiences = [item];
                    }
                    break;
                case 'projectExperiences':
                    if(this.resumeDataUpload.projectExperiences) {
                        this.resumeDataUpload.projectExperiences.unshift(item);
                    } else {
                        this.resumeDataUpload.projectExperiences = [item];
                    }
                    break;
                case 'selfEvaluation':
                    this.resumeDataUpload.selfEvaluation = item;
                    break;
                case 'intentions':
                    if(this.resumeDataUpload.intentions) {
                        this.resumeDataUpload.intentions.unshift(item);
                    } else {
                        this.resumeDataUpload.intentions = [item];
                    }
                    break;
                default:
                    break;
            }
            // shortTips(`原简历内容成功添加到上传简历中！`);
        },
        deleteItem (index, type) {
            switch (type) {
                case 'workExperiences':
                    this.resumeDataUpload.workExperiences.splice(index, 1);
                    break;
                case 'educationExperiences':
                    this.resumeDataUpload.educationExperiences.splice(index, 1);
                    break;
                case 'projectExperiences':
                    this.resumeDataUpload.projectExperiences.splice(index, 1);
                    break;
                case 'selfEvaluation':
                    this.resumeDataUpload.selfEvaluation = '';
                    break;
                case 'intentions':
                    this.resumeDataUpload.intentions.splice(index, 1);
                    break;
                default:
                    break;
            }
            // shortTips(`删除成功！`);
        },
        editItem (val, idx, type) {
            console.log(`argggg:`, val, idx, type);
            switch (type) {
                case 'workExperiences':
                    val.descriptionOverflow = false;
                    val.descriptionExpand = false;

                    if (idx == 10000) { //10000为增加
                        if(this.originalResumeData.workExperiences) {
                            this.originalResumeData.workExperiences.unshift(val);
                        } else {
                            this.originalResumeData.workExperiences = [val];
                        }
                    } else {
                        this.originalResumeData.workExperiences.splice(idx, 1, val);
                    }

                    this.initExpandStatus(true);

                    this.sortWrokExperiences();

                    break;
                case 'educationExperiences':
                    if (idx == 10000) { //10000为增加
                        if(this.originalResumeData.educationExperiences) {
                            this.originalResumeData.educationExperiences.unshift(val);
                        } else {
                            this.originalResumeData.educationExperiences = [val];
                        }
                    } else {
                        this.originalResumeData.educationExperiences.splice(idx, 1, val);
                    }

                    this.sortEducationExperiences();

                    break;
                case 'projectExperiences':
                    val.descriptionOverflow = false;
                    val.descriptionExpand = false;
                    val.responsibilityOverflow = false;
                    val.responsibilityExpand = false;

                    if (idx == 10000) { //10000为增加
                        if(this.originalResumeData.projectExperiences) {
                            this.originalResumeData.projectExperiences.unshift(val);
                        } else {
                            this.originalResumeData.projectExperiences = [val];
                        }
                    } else {
                        this.originalResumeData.projectExperiences.splice(idx, 1, val);
                    }

                    this.initExpandStatus(true);

                    this.sortProjectExperiences();

                    break;
                case 'selfEvaluation':
                    this.originalResumeData.selfEvaluation = val;
                    break;
                case 'intentions':
                    if (idx == 10000) { //10000为增加
                        if(this.originalResumeData.intentions) {
                            this.originalResumeData.intentions.unshift(val);
                        } else {
                            this.originalResumeData.intentions = [val];
                        }
                    } else {
                        this.originalResumeData.intentions.splice(idx, 1, val);
                    }
                    break;
                default:
                    break;
            }
            // shortTips(`内容成功添加至简历`);
        },
        holdValidate () {
            return new Promise((resolve, reject) => {
                console.log(this.originalResumeData);
                if (this.originalResumeData.workExperiences?.length == 0) {
                    reject({ type: 'validateError', text: '工作经验不能为空' });
                }
                if (this.originalResumeData.educationExperiences?.length == 0) {
                    reject({ type: 'validateError', text: '教育经历不能为空' });
                }

                if (!this.originalResumeData.company || this.originalResumeData.company.trim() == '') {
                    reject({ type: 'validateError', text: '请补充公司信息' });
                }
                if (!this.originalResumeData.title || this.originalResumeData.title.trim() == '') {
                    reject({ type: 'validateError', text: '请补充职位信息' });
                }
                if (!this.originalResumeData.school || this.originalResumeData.school.trim() == '') {
                    reject({ type: 'validateError', text: '请补充学校信息' });
                }
                if (!this.originalResumeData.major || this.originalResumeData.major.trim() == '') {
                    reject({ type: 'validateError', text: '请补充专业信息' });
                }
                if (!this.originalResumeData.degree) {
                    reject({ type: 'validateError', text: '请补充学历信息' });
                }
                // if (this.resumeDataUpload.projectExperiences?.length == 0) {
                //     reject({ type: 'validateError', text: '项目经历不能为空' });
                // }
                // if (this.resumeDataUpload.intentions && this.resumeDataUpload.intentions?.length == 0) {
                //     reject({ type: 'validateError', text: '求职意向不能为空' });
                // }
                // if (this.resumeDataUpload.selfEvaluation?.trim() == '') {
                //     reject({ type: 'validateError', text: '自我评价不能为空' });
                // }

                // if(!this.resumeDataUpload.email) {
                //     reject({ type: 'validateError', text: '邮箱不能为空' });
                // }
                // let emailReg = /(^\s*[a-zA-Z0-9_%+#&'*/=^`{|}~-](?:\.?[a-zA-Z0-9_%+#&'*/=^`{|}~-])*@(?:[a-zA-Z0-9_](?:(?:\.?|-*)[a-zA-Z0-9_])*\.[a-zA-Z]{2,9}|\[(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)])\s*$)|(^(1[3-9])\d{9}$)/;
                // if(!emailReg.test(this.resumeDataUpload.email)) {
                //     reject({ type: 'validateError', text: '请输入正确的电子邮箱' });
                // }

                resolve(true);
            });
        },
        async handleSave() {
            try {
                this.saveBtnClick = true;
                await this.holdValidate();
                this.CompareResume2();
            } catch (e) {
                console.log(e)
                if (e.type == 'validateError') {
                    shortTips(e.text);
                }
            }
        },
        submitSave() {
            this.saveLoading = true;

            let resumeBasicInformation = {
                "isExisted":false,
                "existedId":null,
                "realName": "",
                "gender": 0,
                "mobile": "",
                "title": "",
                "email": "",
                "wechat": "",
                "source": null,
                "company": "",
                "yearOfExperience": 0,
                "province": "",
                "city": null,
                "content": "",
                "extraInfo": null,
                "jobHoppingOccasion": null,
                "employerInfo": null,
                "expectSalary": null,
                "school": "",
                "major": "",
                "degree": null,
                "fileId": "",
                "autoApprovelTrading": false,
                "selfEvaluation": '',
                "workExperiences": [],
                "educationExperiences": [],
                "projectExperiences": [],
                "intentions": [],
                "isPersonalCreate": false
            };
            let resumeData = Object.assign({}, resumeBasicInformation, this.resumeDataUpload);
            resumeData = Object.assign({}, resumeData, this.originalResumeData);
            resumeData.yearOfExperience = resumeData.yearOfExperience>100 ? 0: resumeData.yearOfExperience;

            let candidateId = this.originalResumeData.id;
            resumeData.id = candidateId;
            
            // let candidateId = this.originalResumeData.id,
            //     resumeData = {
            //         ...this.originalResumeData,
            //         id: candidateId,

            //     };
            PublicJobService
                .resumeSave(candidateId, resumeData)
                .then(res => {
                    shortTips(`简历保存成功！`);
                    setTimeout(() => {
                        location.href = `/Headhunting/MyCompany.html#/candidateDetail/${candidateId}`;
                    }, 2000);
                })
                .finally(() => {
                    this.saveLoading = false;
                });
        },

        // CompareResume
        // 调对比接口判断简历能不能修改
        CompareResume2(){
            this.saveLoading = true;
            if(this.extensionMode) {
                // if (this.mobileStatus == 1) {
                //     shortTip('请先查看手机号！');
                //     return false;
                // }
                // if (this.emailStatus == 1) {
                //     shortTip('请先查看邮箱！');
                //     return false;
                // }
                // if (this.wechatStatus == 1) {
                //     shortTip('请先查看微信！');
                //     return false;
                // }

                this.submitSave();

                // const params = {
                //     ...this.originalResumeData,
                //     resumeId: this.originalResumeId
                // };

                // _request({
                //     method: 'POST',
                //     url: `/My/Candidate/CompareResume`,
                //     data: params,
                // })
                // .then((r) =>{
                //     // this.resumeDataUpload = r.createResume;
                //     // this.originalResumeData = r.editResume;
                //     this.moreComplete = r.createResumeIsMoreComplete;
                //     this.isFresher = r.createResumeIsFresher;

                //     this.uncompleteArr = r.differentFields || [];
                //     this.requiredArr = r.requiredFields || [];
                //     // let hideFieldArr = ['Mobile', 'Email', 'Wechat'];
                //     // hideFieldArr.forEach(item => {
                //     //     if (this.uncompleteArr.indexOf(item) > -1) {
                //     //         let uncompleteIndex = this.uncompleteArr.indexOf(item);
                //     //         this.uncompleteArr.splice(uncompleteIndex, 1);
                //     //     }

                //     //     if (this.requiredArr.indexOf(item) > -1) {
                //     //         let requiredIndex = this.requiredArr.indexOf(item);
                //     //         this.requiredArr.splice(requiredIndex, 1);
                //     //     }
                //     // });

                //     this.saveLoading = false;
                //     this.getCompareResult();
                // }).catch((err) => {
                //     this.saveLoading = false;
                // })
            } else {
                // this.resumeId = getUrlParam('resumeId');
                // request(`/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`, {}, 'get', true, true)

                this.resumeId = this.$route.query.resumeId;
                _request({
                    method: 'GET',
                    url: `/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`
                })
                .then((r) => {
                    this.resumeDataUpload = r.createResume;
                    this.originalResumeData = r.editResume;
                    this.moreComplete = r.createResumeIsMoreComplete;
                    this.isFresher = r.createResumeIsFresher;
                    this.uncompleteArr = r.differentFields || [];
                    this.requiredArr = r.requiredFields || [];
                   
                    this.saveLoading = false;
                    this.getCompareResult();
                }).catch((err) => {
                    this.saveLoading = false;
                })
            }
        },

        beforeUpdateRecume2() {
            if(this.duplicateType === 2 && this.uncompleteArr.length > 0) {
                let uncompleteTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.uncompleteArr.length; i++) {
                    toLowerCaseTxt = this.uncompleteArr[i].charAt(0).toLowerCase() + this.uncompleteArr[i].slice(1);
                    this.uncompleteArr[i] = toLowerCaseTxt;
                    if(fieldMap[toLowerCaseTxt]) {
                        uncompleteTextArr.push(fieldMap[toLowerCaseTxt])
                    }
                }
                this.updateTip = `你上传的简历，对比原简历，缺失字段：<span class="text-orange">${uncompleteTextArr.join("、")}</span><br/>请补充完整后再更新。`
                this.updateTipDialogShow = true;
            } else if((this.duplicateType === 0 || this.duplicateType === 2 ) && this.requiredArr.length > 0) {
                let requiredTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.requiredArr.length; i++) {
                    toLowerCaseTxt = this.requiredArr[i].charAt(0).toLowerCase() + this.requiredArr[i].slice(1);
                    this.requiredArr[i] = toLowerCaseTxt;
                    if(fieldMap[toLowerCaseTxt]) {
                        requiredTextArr.push(fieldMap[toLowerCaseTxt])
                    }
                }
                this.updateTip = `你上传的简历，缺失必填项：<span class="text-orange">${requiredTextArr.join("、")}</span><br/>请补充后再更新。`
                this.updateTipDialogShow = true;
            } else if(this.duplicateType === 2) {
                this.$confirm('你上传的简历没有原简历完整，确定直接更新吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '放 弃',
                    type: 'warning',
                    center: false
                }).then(({ value }) => {
                    // this.confirmAndSubmit();
                    this.submitSave();
                }).catch(() => {
                    this.hideUpdateTipDialog();       
                });
            } else {
                // this.confirmAndSubmit();
                if(this.duplicateType === 0) {
                    this.submitSave();
                }else if(this.duplicateType === 3) {
                    shortTips(`简历已存在，不能进行覆盖`);
                } else {
                    shortTips(`简历非最新鲜，不能进行覆盖`);
                }
            }
        },

        baseInfoEditCallback(data) {
            Object.assign(this.resumeDataUpload, data);
            // shortTips(`基本信息编辑成功！`);
        },
        setHighlightKey(key) {
            this.highlightKey = key;
        },
        clearHighlightKey() {
            this.highlightKey = '';
        }
    }
}
</script>

<style lang="scss">
.container {
    .page-content {
        .el-dialog.update-tip-dialog {
            width: 480px;
        }
    }
}
</style>
<style lang="scss" scoped>
.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 0;
    overflow-y: auto;
    // background: #f6f6f6;

    .block-tab {
        box-shadow: 0 1px 2px 0px #ccc;
        z-index: 3;

        .tab-label {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            .font-icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }

        /deep/ .el-tabs__header {
            margin-bottom: 0;
            background-color: #fff;
        }

        /deep/ .el-tabs__nav-wrap {
            max-width: 1200px;
            margin: 0 auto;
        }

        /deep/ .el-tabs__nav-wrap::after {
            background-color: #fff;
        }

        /deep/ .el-tabs__active-bar {
            // width: 96px!important;
            // left: calc(10% - 48px);
            display: none;
        }

        /deep/ .el-tabs__item {
            height: 52px;
            line-height: 52px;
            font-size: 16px;
            color: #666;
            text-align: center;

            &.is-active {
                color: $primary;

                .tab-label::after {
                    content: '';
                    position: absolute;
                    left: -2px;
                    right: -2px;
                    bottom: 0;
                    height: 2px;
                    background-color: $primary;
                }
            }
        }
    }

    .page-content-wrapper {
        overflow-y: auto;
    }

    .page-content {
        flex: 1;
        max-width: 1200px;
        margin: 14px auto 54px;
        border-radius: 10px;
        border: 1px solid rgba(235,235,235,0.92);
        background-color: #fff;
        // overflow-y: auto;

        .content-main {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .raw-detail-main{
                // width: 480px;
                flex: 1;
                background:#ffffff;
                border-radius: 8px;
                &.pull-left {
                    margin-right: 20px;
                }
            }
        }

        .block-item {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background-color: #F9FBFA;
            border-bottom: 1px solid rgba(235,235,235,0.92);

            &:last-child {
                border: 0 none;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                overflow: hidden;
            }

            &.basic {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                overflow: hidden;

                .block-item-left {
                    padding: 16px 20px 24px;
                    border-right: 1px solid rgba(235,235,235,0.92);
                }

                .block-item-right {
                    padding: 6px 20px;
                }
            }

            // &.experience-block {
            //     height: 100%;

            //     &.scroll-block {
            //         overflow-y: auto;
            //     }
                
            //     // .block-item-left {
            //     //     padding-bottom: 24px;
            //     // }
            // }

            span.highlight-light.highlight-weight {
                color: #333;
                border-radius: 5px;
                background: #FFC8C8;
            }
            span.highlight-light {
                border-radius: 5px;
                background: #FFE6E6;
            }

            &-left {
                flex: 1;
                background-color: #fff;
                overflow: hidden;

                .block-item-list {
                    min-height: 100%;
                    padding: 0 22px 24px;
                    background-color: #fff;
                    border-right: 1px solid rgba(235,235,235,0.92);
                    overflow: hidden;

                    .operation-list {
                        display: flex;
                        justify-content: end;

                        .font-icon {
                            width: 18px;
                            height: 18px;
                            margin-left: 12px;
                            cursor: pointer;
                        }
                    }

                    .self-evaluate-text {
                        max-height: 200px;
                        margin-top: 8px;
                        padding: 16px 20px;
                        line-height: 18px;
                        background: #F6F6F6;
                        border-radius: 8px;
                        white-space: pre-line;
                        overflow-y: auto;
                    }

                    /deep/ .self-evaluate-input .el-textarea__inner {
                        margin-top: 8px;
                        height: 208px;
                        border: 0 none!important;
                        color: #333;
                        background-color: #F6F6F6;
                        border-radius: 3px;
                        resize: none;
                    }

                    .self-evaluate-btns {
                        display: flex;
                        justify-content: end;
                        margin-top: 6px;
                    }
                }

                .name {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    line-height: 34px;

                    .name-left {
                        vertical-align: top;

                        .view {
                            position: relative;
                            top: 6px;
                            margin-left: 20px;
                            color: $primary;

                            &:hover {
                                color: #7dceb8;
                            }
                        }
                    }

                    span.name-text {
                        font-size: 24px;
                        color: #333;
                        padding: 0 6px;
                        font-weight: bold;
                        cursor: default;
                    }

                    .font-icon {
                        width: 18px;
                        height: 18px;
                        margin-right: 6px;
                        fill: #A8A8A8;
                        cursor: pointer;
                    }
                }

                .title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    font-size: 20px;
                    color: #333;
                    font-weight: bold;
                    line-height: 28px;
                    margin: 12px 0;
                    padding-left: 16px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        width: 4px;
                        height: 20px;
                        margin-top: -10px;
                        background-color: $primary;
                    }

                    &:hover > span {
                        display: inline-flex;
                    }

                    &:hover .evaluate-add-btn {
                        display: inline-flex;
                    }

                    > span {
                        display: none;
                        align-items: center;
                        font-size: 14px;
                        color: $primary;
                        cursor: pointer;

                        .font-icon {
                            margin-right: 6px;
                        }
                    }

                    .evaluate-add-btn {
                        display: none;
                        font-size: 18px;;

                        .font-icon {
                            width: 18px;
                            height: 18px;
                            margin-left: 12px;
                            cursor: pointer;
                            color: #666;
                        }
                    }
                }

                .basic-grid {
                    display: grid;
                    grid-template-columns: 41.7% 33.4% 24.9%;
                    grid-row-gap: 6px;
                    margin-top: 10px;

                    &-item {
                        padding-right: 10px;
                        height: 26px;
                        line-height: 26px;

                        > span {
                            display: inline-block;
                            max-width: 100%;
                            padding: 0 6px;
                            font-size: 14px;
                            color: #666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: default;
                        }

                        .info-block-btn.el-button {
                            height: 26px;
                            line-height: 26px;
                            vertical-align: top;
                            background-color: #fff;
                            color: $primary;
                            border: 1px solid $primary;
                            width: 78px;
                            min-width: auto;
                            border-radius: 5px;
                        }
                        .mobile-unable, .email-unable, .wechat-unable {
                            text-decoration: line-through;
                        }

                        .info-lock-btn {
                            width: 100px;
                            height: 20px;
                            line-height: 20px;
                            border-radius: 4px;
                            margin-right: 10px;
                            color: #fff;
                            display: inline-block;
                            text-align: center;
                            background-color: #38bc9c;
                            cursor: default;
                            .icon-font {
                                color: #fff;
                            }
                        }
                    }
                }

                .form-wrap {
                    width: 100%;
                    margin-bottom: 12px;
                    padding: 16px 20px 0;
                    border: 1px solid #DCDCDC;
                    border-radius: 8px;
                    overflow: hidden;
                }

                .normal-item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    min-height: 84px;
                    margin-bottom: 12px;
                    padding: 16px 20px 0;
                    border: 1px solid #DCDCDC;
                    border-radius: 8px;
                    overflow: hidden;

                    // &:last-child {
                    //     margin-bottom: 24px;
                    // }

                    &:hover {
                        border-color: #FF4B4B;

                        .normal-btns {
                            display: inline-flex;
                        }
                    }

                    .normal-btns {
                        display: none;
                        align-items: center;
                    }

                    &-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 4px;
                        height: 24px;

                        > span {
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            color: #333;
                            line-height: 22px;
                            font-weight: bold;

                            span {
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 220px;

                                &.title-text {
                                    margin-left: 20px;
                                    max-width: 200px;
                                }
                            }

                            .new-icon {
                                display: inline-block;
                                margin-left: 6px;
                                width: 20px;
                                height: 20px;
                                line-height: 20px;
                                text-align: center;
                                font-size: 12px;
                                color: #EFAC16;
                                border-radius: 3px;
                                border: 1px solid #FFBF30;
                            }
                        }

                        .font-icon {
                            width: 18px;
                            height: 18px;
                            margin-left: 12px;
                            cursor: pointer;

                            &.replace-font-icon {
                                margin-left: 0;

                                &.revoke-icon {
                                    fill: $primary;
                                }
                            }
                        }

                        .work-expand-icon.expanded .font-icon {
                            transform: rotate(180deg);
                        }
                    }

                    &-info {
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                        align-self: flex-start;
                        font-size: 14px;
                        color: #999;
                        line-height: 20px;
                        margin-bottom: 4px;

                        i {
                            display: inline-block;
                            width: 1px;
                            height: 12px;
                            margin: 0 8px;
                            background-color: #ccc;
                            flex-shrink: 0;
                        }

                        span {
                            flex-shrink: 0;

                            &.major-text {
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }

                    &-expand {
                        display: flex;
                        justify-content: space-between;

                        &-title {
                            flex-shrink: 0;
                            margin-right: 6px;
                            color: #333;
                            font-weight: bold;
                            line-height: 20px;
                        }

                        .expand-content {
                            position: relative;
                            width: 100%;
                            flex: 1;
                            line-height: 20px;
                            margin-bottom: 12px;

                            &.project-desc {
                                margin-bottom: 6px;
                            }

                            &-text {
                                display: inline-block;
                                text-align: justify;
                                vertical-align: top;
                                white-space: pre-line;
                                word-break: break-word;

                                &.expand-text-fold {
                                    width: 100%;
                                    max-height: 20px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }

                            // .expand-icon {
                            //     position: absolute;
                            //     top: 20px;
                            //     right: 0;
                            //     color: $primary;
                            //     padding-left: 100px;
                            //     background: linear-gradient(to right, rgba(255,255,255,0.3), #fff 100px, #fff);

                            //     .font-icon {
                            //         margin-right: 6px;
                            //     }
                            // }

                            // .fold-icon {
                            //     color: $primary;

                            //     .font-icon {
                            //         margin-right: 6px;
                            //         margin-top: -4px;
                            //         transform: rotate(180deg);
                            //     }
                            // }
                        }

                        .expand-icon, .fold-icon {
                            font-size: 12px;
                            color: $primary;
                            margin-left: 10px;
                            cursor: pointer;

                            .font-icon {
                                margin-right: 6px;
                            }
                        }

                        .fold-icon {

                            .font-icon {
                                margin-right: 6px;
                                margin-top: -4px;
                                transform: rotate(180deg);
                            }
                        }
                    }

                    &.intention-item {
                        min-height: auto;
                        padding: 0;
                        border: 0 none;

                        .normal-item-info {
                            margin-bottom: 0;
                            line-height: 40px;
                            justify-content: space-between;

                            .font-icon {
                                width: 18px;
                                height: 18px;
                                margin-left: 12px;
                                fill: #666;
                                cursor: pointer;

                                &.replace-font-icon {
                                    margin-left: 0;

                                    &.revoke-icon {
                                        fill: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &-right {
                width: 454px;
                padding: 0 20px;
                background-color: #F9FBFA;
                flex-shrink: 0;
                // border-left: 1px solid rgba(235,235,235,0.92);

                .title {
                    font-size: 18px;
                    color: #333;
                    font-weight: bold;
                    line-height: 26px;
                    margin: 12px 0;

                    &.self-evaluate-title {
                        display: flex;
                        justify-content: space-between;

                        .normal-item-operation {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            color: #999;
                            cursor: pointer;

                            .font-icon {
                                width: 18px;
                                height: 18px;
                                fill: $primary;
                                margin-right: 6px;
                            }
                        }
                    }
                }

                .basic-items {
                    padding-top: 2px;

                    span {
                        display: inline-block;
                        height: 26px;
                        line-height: 26px;
                        margin-right: 8px;
                        margin-bottom: 4px;
                        padding: 0 6px;
                        font-size: 14px;
                        color: #666;
                        border-radius: 5px;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: top;
                        cursor: default;
                    }
                }

                .normal-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 78px;
                    padding: 0 16px;
                    margin-bottom: 12px;
                    background: #FFFFFF;
                    border-radius: 8px;
                    border: 1px solid #DCDCDC;
                    overflow: hidden;

                    &:hover {
                        border-color: #FF4B4B;
                    }

                    &-text {
                        .normal-item-title {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 4px;

                            > span {
                                display: flex;
                                align-items: center;
                                font-size: 16px;
                                color: #333;
                                line-height: 22px;
                                font-weight: bold;

                                .desc {
                                    display: inline-block;
                                    max-width: 240px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                .new-icon {
                                    display: inline-block;
                                    margin-left: 6px;
                                    width: 20px;
                                    height: 20px;
                                    line-height: 20px;
                                    text-align: center;
                                    font-size: 12px;
                                    color: #EFAC16;
                                    border-radius: 3px;
                                    border: 1px solid #FFBF30;
                                }
                            }
                        }

                        .normal-item-info {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: #999;
                            line-height: 20px;

                            i {
                                display: inline-block;
                                width: 1px;
                                height: 12px;
                                margin: 0 8px;
                                background-color: #ccc;
                                flex-shrink: 0;
                            }

                            span {
                                flex-shrink: 0;

                                &.major-text {
                                    max-width: 96px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }

                    &-operation {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        color: #999;
                        cursor: pointer;

                        .font-icon {
                            width: 18px;
                            height: 18px;
                            fill: $primary;
                            margin-right: 6px;
                        }
                    }

                    &.grey {
                        background-color: #F9FBFA;
                        border-color: #DCDCDC;
                        cursor: not-allowed;

                        .normal-item-text {
                            .normal-item-title > span {
                                color: #D8D8D8;

                                .new-icon {
                                    color: #D8D8D8;
                                    border-color: #D8D8D8;
                                }
                            }
                            .normal-item-info {
                                color: #D8D8D8;
                            }
                        }

                        .normal-item-operation {
                            color: #D8D8D8;
                            cursor: not-allowed;

                            .font-icon {
                                fill: #D8D8D8;
                            }
                        }
                    }

                    &.intention-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: auto;
                        padding: 0;
                        border: 0 none;
                        background-color: unset;
                    }
                }
            }
        }
    }

    .page-footer {
        flex-shrink: 0;
        height: 56px;
        padding-right: 15px;
        font-size: 16px;
        color: #333;
        background-color: #fff;
        box-shadow: 0 0px 2px 0px #ccc;

        &-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 1200px;
            height: 100%;
            margin: 0 auto;
        }

        p {
            margin: 0;
        }
    }
}

.el-dialog.update-tip-dialog {
    width: 480px !important;
    .el-dialog__title {
        font-weight: normal;
    }
    .update-tip {
        color: #444;
        line-height: 28px;
        .text-orange {
            color: #fa765d;
        }
    }
    .el-dialog__footer {
        padding: 0 20px 20px;
        .el-button{
            min-width: 0px;
            width: 84px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            border-radius: 2px;
        }
        .el-button + .el-button {
            margin-left: 10px;
        }
    }
}
.el-message-box.update-confirm-dialog {
    width: 480px;
    height: 180px;
    .el-message-box__header {
        display: none;
    }
    .el-message-box__content {
        font-size: 16px;
        color: #444;
        line-height: 24px;
        padding: 40px 20px;
    }
    .el-message-box__btns {
        text-align: center;
    }
}
.el-loading-mask.page-loading {
    .el-loading-spinner {
        top: 300px;
    }
}
</style>

<style lang="scss">
.quit-edit-dialog {
    .el-dialog__body p {
        font-size: 14px;
        color: #666;
        margin: 0;

        &.desc-text {
            font-size: 12px;
            color: #999;
        }
    }
}
</style>