var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "replace-experience-dialog",
      attrs: {
        title: "请选择替换内容",
        visible: _vm.dialogVisible,
        width: "480px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        closed: _vm.handleCancel,
      },
    },
    [
      _c(
        "div",
        [
          _vm._l(_vm.list, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "normal-item",
                class: item.hasAddToLeft || item.isReplaced ? "grey" : "",
              },
              [
                _vm.type == "work"
                  ? _c("div", { staticClass: "normal-item-text" }, [
                      _c("div", { staticClass: "normal-item-title" }, [
                        _c("span", [
                          _c("i", { staticClass: "desc" }, [
                            _vm._v(_vm._s(item.company)),
                          ]),
                          item.isNew
                            ? _c("i", { staticClass: "new-icon" }, [
                                _vm._v("新"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "normal-item-info" }, [
                        _c("span", [_vm._v(_vm._s(item.title || "未知"))]),
                        _c("i"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.monthFormat(item.startDate)) +
                              "-" +
                              _vm._s(_vm.monthFormat(item.endDate))
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.type == "education"
                  ? _c("div", { staticClass: "normal-item-text" }, [
                      _c("div", { staticClass: "normal-item-title" }, [
                        _c("span", [
                          _c("i", { staticClass: "desc" }, [
                            _vm._v(_vm._s(item.school)),
                          ]),
                          item.isNew
                            ? _c("i", { staticClass: "new-icon" }, [
                                _vm._v("新"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "normal-item-info" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.degreeMap[item.degree] || "未知")),
                        ]),
                        _c("i"),
                        _c("span", [_vm._v(_vm._s(item.major || "未知"))]),
                        _c("i"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.monthFormat(item.startDate)) +
                              "-" +
                              _vm._s(_vm.monthFormat(item.endDate))
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.type == "project"
                  ? _c("div", { staticClass: "normal-item-text" }, [
                      _c("div", { staticClass: "normal-item-title" }, [
                        _c("span", [
                          _c("i", { staticClass: "desc" }, [
                            _vm._v(_vm._s(item.projectName)),
                          ]),
                          item.isNew
                            ? _c("i", { staticClass: "new-icon" }, [
                                _vm._v("新"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _c("div", { staticClass: "normal-item-info" }, [
                        _c("span", [
                          _vm._v(_vm._s(item.projectRole || "未知")),
                        ]),
                        _c("i"),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.monthFormat(item.startDate)) +
                              "-" +
                              _vm._s(_vm.monthFormat(item.endDate))
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.type == "intention"
                  ? _c("div", { staticClass: "normal-item-text" }, [
                      _c("div", { staticClass: "normal-item-info" }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.provinceCityMap[item.locationId] || "未知"
                            )
                          ),
                        ]),
                        _c("i"),
                        _c("span", [_vm._v(_vm._s(item.title))]),
                        _c("i"),
                        _c("span", [
                          _vm._v(
                            _vm._s(item.minSalary) +
                              "-" +
                              _vm._s(item.maxSalary) +
                              "K"
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                item.hasAddToLeft
                  ? _c("div", { staticClass: "normal-item-operation" }, [
                      _vm._v("\n              已补充至原简历\n          "),
                    ])
                  : _vm._e(),
                item.isReplaced
                  ? _c("div", { staticClass: "normal-item-operation" }, [
                      _vm._v("\n              已被替换\n          "),
                    ])
                  : _vm._e(),
                !item.hasAddToLeft && !item.isReplaced
                  ? _c("div", { staticClass: "normal-item-operation" }, [
                      index != _vm.checkedIndex
                        ? _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCheck(index)
                                },
                              },
                            },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-danxuan" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      index == _vm.checkedIndex
                        ? _c(
                            "span",
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-chenggong" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHandle },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }