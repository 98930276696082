<template>
    <el-form 
        ref="compareResumeProjectForm" 
        class="compare-resume-project-form col-15"
        id="compare-resume-project-form" 
        :model="formData"
        :rules="rules"
        label-width="80px"
        label-position="top"
    >
        <el-row>
            <el-col>
                <el-form-item label="项目名称" prop="projectName">
                    <el-input v-model="formData.projectName" maxlength="128"></el-input>
                </el-form-item>
            </el-col>
            <span class="space"></span>
            <el-col>
                <el-form-item label="项目角色" prop="projectRole">
                    <el-input v-model="formData.projectRole" maxlength="128"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-form-item label="开始时间" prop="startDate">
                    <el-date-picker
                        class="start-date-picker"
                        style="width: 100%"
                        v-model="formData.startDate"
                        type="month"
                        placeholder="如 2018-08"
                        value-format="yyyy-MM"
                        :picker-options="startDateOptions"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-col>
            <span class="space-lg">至</span>
            <el-col>
                <el-form-item class="end-date-item" label="结束时间" prop="endDate">
                    <el-date-picker
                        ref="endDatePicker"
                        class="end-date-picker"
                        style="width: 100%"
                        v-model="formData.endDate"
                        type="month"
                        placeholder="如 2018-08"
                        value-format="yyyy-MM"
                        :picker-options="endDateOptions"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="项目职责" prop="responsibility">
            <el-input type="textarea" v-model="formData.responsibility" maxlength="2000"></el-input>
        </el-form-item>
        <el-form-item label="项目描述" prop="description">
            <el-input type="textarea" v-model="formData.description" maxlength="8000"></el-input>
        </el-form-item>
        <el-form-item class="btn-items">
            <el-button @click="_cancel">取消</el-button>
            <el-button type="primary" @click="submitForm('compareResumeProjectForm')">保存</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import { candidate as candidateConfig } from '#/js/config/api.json';
    import resumeItemType from '#/js/config/resumeItemType.json';
    import { isNaN } from '#/js/util/tools.js';
    import { min as getMin, max as getMax} from '#/js/util/validators.js';
    import moment from 'moment';

    import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
    import CustomerSelectOrWrite from '#/component/common/customer-select-or-write.vue';

    let defaultFormObj = {
        "id": "",
        "projectName": "",
        "projectRole": "",
        "startDate": "",
        "endDate": "",
        "description": "",
        "responsibility": ""
    }

    export default {
        components: {
            // Checkbox,
            CustomerDepartmentSelect,
            CustomerSelectOrWrite,
        },
        data () {
            let self = this;
            return {
                formData: Object.assign({}, defaultFormObj),
                type: resumeItemType.projectExperiences,
                loading: false,
                startDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    }
                },
                endDateOptions: {
                    disabledDate(date) {
                        return date.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '至今',
                        onClick(picker) {
                            self.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                            self.$refs.endDatePicker.handleClose();
                        }
                    }]
                },
                rules: {
                    projectName: [
                        { required: true, message: '项目名称不能为空', trigger: ['blur', 'change'] },
                        { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                        { max: 128, message: '项目名称最长支持128个字符', trigger: ['blur', 'change'] },
                    ],
                    projectRole: [
                        { min: 2, message: '不能少于 2 个字符', trigger: ['blur'] },
                        { max: 128, message: '项目角色最长支持128个字符', trigger: ['blur', 'change'] },
                    ],
                    responsibility: [
                        { min: 20, message: '不能少于 20 个字符', trigger: ['blur'] },
                        { max: 2000, message: '项目职责最长支持2000个字符', trigger: ['blur', 'change'] },
                    ],
                    description: [
                        { min: 20, message: '不能少于 20 个字符', trigger: ['blur'] },
                        { max: 8000, message: '项目描述最长支持8000个字符', trigger: ['blur', 'change'] },
                    ],
                    startDate: [
                        { validator: this.validateStartDate, trigger: ['blur', 'change'] }
                    ],
                    endDate: [
                        { validator: this.validateEndDate, trigger: ['blur', 'change'] }
                    ],
                },
            }
        },
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            canEdit: {
                type: Boolean,
                default: true
            },
            candidateId:String,
            editUrl:String,
            addUrl:String,
            isSaveToDb: {
                type: Boolean,
                default: true,
            },
            currentProject: {
                type: Object,
                default: null
            }
        },
        mounted () {

            this.initEvent();
        },
        methods: {
            initEvent(){
                if (this.currentProject) {
                    Object.assign(this.formData, this.currentProject);

                    if (typeof this.formData.startDate == 'object') {
                        this.formData.startDate = moment(this.formData.startDate).format('yyyy-MM-DD');
                    }
                    if (typeof this.formData.endDate == 'object') {
                        this.formData.endDate = moment(this.formData.endDate).format('yyyy-MM-DD');
                    }
    
                    if(this.formData.endDate.indexOf('9999') > -1) {
                        this.formData.endDate = '';
                        this.$nextTick(() => {
                            this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                        })
                    }
                    this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? '': this.formData.endDate;
                    this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? '': this.formData.startDate;
                }
            },
            validateStartDate(rule, value, callback) {
                if (!value) {
                    if (this.formData.endDate) {
                        callback(new Error('开始时间不能为空'));
                    } else {
                        this.$refs['compareResumeProjectForm'].clearValidate('endDate');
                        callback();
                    }
                } else {
                    if (isNaN(new Date(value).getTime())) {
                        callback(new Error('开始时间日期格式不符'));
                    } else {
                        callback();
                        let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                        if (this.formData.endDate || endDateText == '至今') {
                            this.$refs['compareResumeProjectForm'].validateField('endDate', valid => {});
                        }
                    }
                }
            },
            validateEndDate(rule, value, callback) {
                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                if(endDateText != '至今') {
                    if (!value) {
                        if (this.formData.startDate) {
                            callback(new Error('结束时间不能为空'));
                            return;
                        } else {
                            this.$refs['compareResumeProjectForm'].clearValidate('startDate');
                            callback();
                            return;
                        }
                    } else {
                        if (isNaN(new Date(value).getTime())) {
                            callback(new Error('结束时间日期格式不符'));
                        } else if (new Date(value).getTime() < new Date(this.formData.startDate).getTime()) {
                            callback(new Error('结束时间应大于开始时间'));
                        } else if (this.formData.startDate && (new Date(value).getTime() - new Date(this.formData.startDate).getTime() > 45*365*24*60*60*1000)) {
                            callback(new Error("请输入真实的项目经历时间"));
                        } else {
                            callback();
                        }
                    }

                } else {
                    if (!this.formData.startDate) {
                        this.$refs['compareResumeProjectForm'].validateField('startDate', valid => {});
                        return;
                    }
                    if (this.formData.startDate && (new Date().getTime() - new Date(this.formData.startDate).getTime() > 45*365*24*60*60*1000)) {
                        callback(new Error("请输入真实的项目经历时间"));
                    } else {
                        callback();
                    }
                }
            },
            _cancel () {
                if (this.currentProject) {
                    this.initEvent();
                } else {
                    this._reset();
                    this.$refs['compareResumeProjectForm'].resetFields();
                }
                this.$emit('resume-project-form-cancel');
            },

            _delete () {
                this._showConfirmDialog();
            },

            _reset () {
                this.formData = Object.assign({}, defaultFormObj);
            },

            _refresh () {
                this.$emit('candidate-detail-refresh')
            },

            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this._submitCandidateEdit();
                } else {
                    console.log('error submit!!');
                    return false;
                }
                });
            },

            // resetForm(formName) {
            //     this._reset();
            //     // this.$refs[formName].resetFields();
            // },

            _submitCandidateEdit () {
                let formData = this.formData;
                formData = this.trimValue(formData);

                let isEdit = (this.isEdit || formData.id) ? true : false;
                let postUrl = isEdit ? this.editUrl : this.addUrl;

                let endDateText = this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value;
                let projectExperience = {
                    ...formData
                };
                if(endDateText == '至今') {
                    projectExperience.endDate = '9999-12-31';
                }
                if(this.isSaveToDb) {
                    _request({
                        url: postUrl,
                        method: 'POST',
                        data: {
                            id:this.candidateId,
                            type:this.type,
                            data:{
                                projectExperience
                            }
                        }
                    }).then(res => {
                        this._cancel();
                        this._refresh();
                        shortTips(isEdit ? '编辑成功' : '添加成功');
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    shortTips(isEdit ? '编辑成功' : '添加成功');
                    this.loading = false;

                    this.$emit('save-btn-callback', projectExperience);
                }
            },

            _showConfirmDialog(){
                this.$emit("show-delete-dialog", this.type, this.formData.id);
            },

            trimValue (obj) {
                let newOjb = {};
                Object.keys(obj).forEach(key => {
                    newOjb[key] = (typeof(obj[key]) == 'string') ? obj[key].trim() : obj[key];
                })
                return newOjb;
            },

            mergeFormData (data) {
                Object.assign(this.formData, data);

                if(this.formData.endDate.indexOf('9999') > -1) {
                    this.formData.endDate = '';
                    this.$nextTick(() => {
                        this.$refs.endDatePicker.$el.querySelector('.el-input__inner').value = '至今';
                    })
                }
                this.formData.endDate = this.formData.endDate =="0001-01-01T00:00:00" ? '': this.formData.endDate;
                this.formData.startDate = this.formData.startDate =="0001-01-01T00:00:00" ? '': this.formData.startDate;


                if(this.formData.tempCustomerId) {
                    this.formData.customerId = this.formData.tempCustomerId;
                }
                if(this.formData.customerId == '0') {
                    this.formData.customerId = null;
                }
                this.$nextTick(() => {
                    if(this.$refs.departmentSelect && this.formData.customerDepartmentId) {
                        this.$refs.departmentSelect.value = this.formData.customerDepartmentId;
                        this.$refs.departmentSelect.editInit({
                            id: this.formData.customerDepartmentId
                        });
                    }
                })
            },

            // initEvent () {
            //     let self = this;
            //     this.initAllDateRange();
            // },
        }

    }
</script>

<style lang="scss" scoped>
    .compare-resume-project-form{
        // display: none;
        background: #fff;
        // border: 1px solid #e6e6e6;
        // border-radius: 2px;
        // padding: 40px 20px;
        margin-top: 20px;
        margin-bottom: 12px;

        .space {
            display: inline-block;
            width: 28px;
            flex-shrink: 0;
        }
        .space-lg {
            position: relative;
            top: 26px;
            display: inline-block;
            width: 32px;
            height: 38px;
            line-height: 38px;
            color: #333;
            text-align: center;
            flex-shrink: 0;
        }

        .el-row {
            display: flex;
        }

        /deep/ .el-form-item {
            margin-bottom: 16px;

            &.is-success .el-input__inner {
                border: 0 none;
            }

            .el-form-item__error {
                white-space: nowrap;
            }

            .el-autocomplete, .el-select {
                width: 100%;
            }
        }

        /deep/ .el-form-item__label {
            line-height: 20px;
            padding-bottom: 4px;
        }

        /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
            content: '';
            display: none;
        }

        /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
            content: '*';
            color: #F56C6C;
            margin-left: 4px;
        }

        // /deep/ .el-form-item.end-date-item > .el-form-item__label:after {
        //     content: '*';
        //     color: #F56C6C;
        //     margin-left: 4px;
        // }

        /deep/ .el-form-item__content .el-input__inner {
            height: 38px;
            line-height: 38px;
            border: 0 none;
            color: #333;
            background-color: #F6F6F6;
            border-radius: 3px;
        }

        /deep/ .el-form-item__content .el-textarea__inner {
            // height: 208px;
            height: 128px;
            border: 0 none!important;
            color: #333;
            background-color: #F6F6F6;
            border-radius: 3px;
            resize: none;
        }

        /deep/ .el-date-editor {
            .el-input__inner {
                padding: 0 15px;
            }

            .el-input__prefix {
                display: none;
            }
        }

        .btn-items {
            margin-top: -10px;
            margin-bottom: 0;
            
            /deep/ .el-form-item__content {
                line-height: inherit;
                text-align: right;
            }
        }

        &:before,&:after{
            content: "";
            display: table;
            clear: both;
        }

        .last-experience-checkbox{
            margin-right: 10px;
            margin-bottom: 0;
            .el-checkbox__inner{
                width: 20px;
                height: 20px;
                &::after{
                    top: 4px;
                    left: 7px;
                    height: 10px;
                }
            }
            .is-disabled,.is-checked {
                .el-checkbox__inner{
                    border-color: #38bc9c;
                    background-color: #d3d3d3;
                    &::after{
                        border-color: #38bc9c;
                    }
                }
            }
        }
    }

    .department-tip{
        // display: block;
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin-top: 4px;
    }

    .compare-resume-project-form {
        &.col-15, .col-14 {
            width: 100% !important;
        }
        .col-7 {
            width: 50% !important;
        }
    }
</style>
