<template>
  <el-dialog
    title="请选择替换内容"
    :visible.sync="dialogVisible"
    width="480px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="replace-experience-dialog"
    @closed="handleCancel"
  >
    <div>
        <div class="normal-item" :class="(item.hasAddToLeft || item.isReplaced) ? 'grey' : ''" v-for="(item, index) in list" :key="index">
            <div v-if="type == 'work'" class="normal-item-text">
                <div class="normal-item-title">
                    <span><i class="desc">{{item.company}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                </div>
                <div class="normal-item-info">
                    <span>{{item.title || '未知'}}</span>
                    <i></i>
                    <span>{{monthFormat(item.startDate)}}-{{monthFormat(item.endDate)}}</span>
                </div>
            </div>

            <div v-if="type == 'education'" class="normal-item-text">
                <div class="normal-item-title">
                    <span><i class="desc">{{item.school}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                </div>
                <div class="normal-item-info">
                    <span>{{degreeMap[item.degree] || '未知'}}</span>
                    <i></i>
                    <span>{{item.major || '未知'}}</span>
                    <i></i>
                    <span>{{monthFormat(item.startDate)}}-{{monthFormat(item.endDate)}}</span>
                </div>
            </div>

            <div v-if="type == 'project'" class="normal-item-text">
                <div class="normal-item-title">
                    <span><i class="desc">{{item.projectName}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                </div>
                <div class="normal-item-info">
                    <span>{{item.projectRole || '未知'}}</span>
                    <i></i>
                    <span>{{monthFormat(item.startDate)}}-{{monthFormat(item.endDate)}}</span>
                </div>
            </div>

            <div v-if="type == 'intention'" class="normal-item-text">
                <!-- <div class="normal-item-title">
                    <span><i class="desc">{{item.projectName}}</i><i v-if="item.isNew" class="new-icon">新</i></span>
                </div> -->
                <div class="normal-item-info">
                    <span>{{provinceCityMap[item.locationId] || '未知'}}</span>
                    <i></i>
                    <span>{{item.title}}</span>
                    <i></i>
                    <span>{{item.minSalary}}-{{item.maxSalary}}K</span>
                </div>
            </div>

            <div v-if="item.hasAddToLeft" class="normal-item-operation">
                已补充至原简历
            </div>
            <div v-if="item.isReplaced" class="normal-item-operation">
                已被替换
            </div>
            <div v-if="!item.hasAddToLeft && !item.isReplaced" class="normal-item-operation">
                <span v-if="index != checkedIndex" @click="handleCheck(index)"><font-icon href="#icon-danxuan"></font-icon></span>
                <span v-if="index == checkedIndex"><font-icon href="#icon-chenggong"></font-icon></span>
                
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="handleCancel">取消</el-button>
            <el-button type="primary" @click="confirmHandle">保存</el-button>
        </div>
    </div>
  </el-dialog>
</template>

<script>
import provinceCityMap from '#/js/config/provinceCity.json';

export default {
    components: {
    },
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            checkedIndex: -1,
            list: [],
            type: '',
            provinceCityMap: provinceCityMap,
            degreeMap: ['无',"高中及以下","大专","本科","硕士","MBA","博士"],
        }
    },
    methods: {
        show(json) {
            this.list = json.newList || [];
            this.type = json.type || '';
            this.dialogVisible = true;
        },
        monthFormat(date) {
            if (!date) {
                return '未知';
            }
            if (date.indexOf('9999') == 0) {
                return '至今';
            }
            return date.substring(0, 7).replace('-', '.');
        },
        handleCheck(index) {
            this.checkedIndex = index;
        },
        handleCancel() {
            this.checkedIndex = -1;
            this.dialogVisible = false;
        },
        confirmHandle() {
            if (this.checkedIndex == -1) {
                shortTips('请选择替换内容');
                return false;
            }
            this.$emit('replace-selected', this.checkedIndex, this.type);
            this.dialogVisible = false;
        }
    }
}
</script>

<style lang="scss" scope>

.replace-experience-dialog {
    .normal-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 78px;
        padding: 0 16px;
        margin-bottom: 12px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #DCDCDC;

        &-text {
            .normal-item-title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;

                > span {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: #333;
                    line-height: 22px;
                    font-weight: bold;

                    .desc {
                        display: inline-block;
                        max-width: 240px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .new-icon {
                        display: inline-block;
                        margin-left: 6px;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        font-size: 12px;
                        color: #EFAC16;
                        border-radius: 3px;
                        border: 1px solid #FFBF30;
                    }
                }
            }

            .normal-item-info {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #999;
                line-height: 20px;

                i {
                    display: inline-block;
                    width: 1px;
                    height: 12px;
                    margin: 0 8px;
                    background-color: #ccc;
                }
            }
        }

        &-operation {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #999;
            cursor: pointer;

            .font-icon {
                width: 22px;
                height: 22px;
                fill: $primary;
                margin-right: 6px;
            }
        }

        &.grey {
            background-color: #F9FBFA;
            border-color: #DCDCDC;
            cursor: not-allowed;

            .normal-item-text {
                .normal-item-title > span {
                    color: #D8D8D8;

                    .new-icon {
                        color: #D8D8D8;
                        border-color: #D8D8D8;
                    }
                }
                .normal-item-info {
                    color: #D8D8D8;
                }
            }

            .normal-item-operation {
                color: #D8D8D8;
                cursor: not-allowed;

                .font-icon {
                    fill: #D8D8D8;
                }
            }
        }
    }
}
</style>