var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingCheck || _vm.saveLoading,
          expression: "loadingCheck || saveLoading",
        },
      ],
      staticClass: "container",
    },
    [
      _c(
        "div",
        { staticClass: "block-tab" },
        [
          _c(
            "el-tabs",
            {
              attrs: { stretch: true },
              on: { "tab-click": _vm.handleBlockTabClick },
              model: {
                value: _vm.activeBlock,
                callback: function ($$v) {
                  _vm.activeBlock = $$v
                },
                expression: "activeBlock",
              },
            },
            [
              _c("el-tab-pane", { attrs: { name: "basic" } }, [
                _c(
                  "span",
                  {
                    staticClass: "tab-label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("font-icon", { attrs: { href: "#icon-user1" } }),
                    _vm._v("\n                    基本资料\n                "),
                  ],
                  1
                ),
              ]),
              _c("el-tab-pane", { attrs: { name: "work" } }, [
                _c(
                  "span",
                  {
                    staticClass: "tab-label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("font-icon", { attrs: { href: "#icon-work" } }),
                    _vm._v("\n                    工作经历\n                "),
                  ],
                  1
                ),
              ]),
              _c("el-tab-pane", { attrs: { name: "education" } }, [
                _c(
                  "span",
                  {
                    staticClass: "tab-label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("font-icon", { attrs: { href: "#icon-time1" } }),
                    _vm._v("\n                    教育经历\n                "),
                  ],
                  1
                ),
              ]),
              _c("el-tab-pane", { attrs: { name: "project" } }, [
                _c(
                  "span",
                  {
                    staticClass: "tab-label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("font-icon", {
                      attrs: { href: "#icon-xiangmujingli-04" },
                    }),
                    _vm._v("\n                    项目经历\n                "),
                  ],
                  1
                ),
              ]),
              _c(
                "el-tab-pane",
                { attrs: { label: "自我评价", name: "evaluate" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "tab-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("font-icon", {
                        attrs: { href: "#icon-heart-hollow1" },
                      }),
                      _vm._v(
                        "\n                    自我评价\n                "
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "期望工作", name: "intention" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "tab-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("font-icon", { attrs: { href: "#icon-survey" } }),
                      _vm._v(
                        "\n                    期望工作\n                "
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "page-content-wrapper" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingCheck,
                expression: "loadingCheck",
              },
            ],
            ref: "pageContent",
            staticClass: "page-content",
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.saveLoading,
                    expression: "saveLoading",
                  },
                ],
                staticClass: "content-main",
              },
              [
                _c("div", { staticClass: "block-item basic" }, [
                  _c("div", { staticClass: "block-item-left" }, [
                    _c("div", { staticClass: "name" }, [
                      _c("div", { staticClass: "name-left" }, [
                        _c(
                          "span",
                          {
                            staticClass: "name-text",
                            class: [
                              _vm.getHighlightClass("realName"),
                              _vm.highlightKey == "realName"
                                ? "highlight-weight"
                                : "",
                            ],
                            on: {
                              mouseenter: function ($event) {
                                return _vm.handleHighlightMouseEnter("realName")
                              },
                              mouseleave: function ($event) {
                                return _vm.handleHighlightMouseLeave("realName")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.originalResumeData.realName))]
                        ),
                        !!_vm.originalResumeData.id
                          ? _c(
                              "a",
                              {
                                staticClass: "view",
                                attrs: {
                                  href:
                                    "/#/candidateDetail/" +
                                    _vm.originalResumeData.id,
                                  target: "_blank",
                                },
                              },
                              [_vm._v("查看原简历")]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "span",
                        { on: { click: _vm.editBaseInfo } },
                        [_c("font-icon", { attrs: { href: "#icon-bianji" } })],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "basic-grid" },
                      [
                        _vm._l(_vm.basicFieldsLess, function (key) {
                          return [
                            _c(
                              "div",
                              { key: key, staticClass: "basic-grid-item" },
                              [
                                _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.getHighlightClass(key),
                                      _vm.highlightKey == key
                                        ? "highlight-weight"
                                        : "",
                                    ],
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.handleHighlightMouseEnter(
                                          key
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.handleHighlightMouseLeave(
                                          key
                                        )
                                      },
                                    },
                                  },
                                  [
                                    key == "gender"
                                      ? [
                                          _vm._v(
                                            "性别：" +
                                              _vm._s(
                                                _vm.genderMap[
                                                  _vm.originalResumeData.gender
                                                ]
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "yearOfExperience"
                                      ? [
                                          _vm._v(
                                            "工作经验：" +
                                              _vm._s(
                                                _vm.originalResumeData
                                                  .yearOfExperience
                                              ) +
                                              "年"
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "age"
                                      ? [
                                          _vm._v(
                                            "年龄：" +
                                              _vm._s(
                                                _vm.getAgeFromBirthday(
                                                  _vm.originalResumeData
                                                    .birthday
                                                )
                                                  ? _vm.getAgeFromBirthday(
                                                      _vm.originalResumeData
                                                        .birthday
                                                    ) + "岁"
                                                  : "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "company"
                                      ? [
                                          _vm._v(
                                            "公司：" +
                                              _vm._s(
                                                _vm.originalResumeData
                                                  .company || "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "title"
                                      ? [
                                          _vm._v(
                                            "职位：" +
                                              _vm._s(
                                                _vm.originalResumeData.title ||
                                                  "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "city"
                                      ? [
                                          _vm._v(
                                            "城市：" +
                                              _vm._s(
                                                _vm.provinceCityMap[
                                                  _vm.originalResumeData.city
                                                ] || "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "school"
                                      ? [
                                          _vm._v(
                                            "学校：" +
                                              _vm._s(
                                                _vm.originalResumeData.school ||
                                                  "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "major"
                                      ? [
                                          _vm._v(
                                            "专业：" +
                                              _vm._s(
                                                _vm.originalResumeData.major ||
                                                  "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "degree"
                                      ? [
                                          _vm._v(
                                            "学历：" +
                                              _vm._s(
                                                _vm.degreeMap[
                                                  _vm.originalResumeData.degree
                                                ] || "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        }),
                        _c(
                          "div",
                          { staticClass: "basic-grid-item" },
                          [
                            _vm.originalResumeData.mobileStatus == 1 ||
                            _vm.originalResumeData.mobileStatus == 2
                              ? _c("span", [_vm._v("手机：")])
                              : _vm._e(),
                            _vm.originalResumeData.mobileStatus == 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.mobileLoading,
                                        expression: "mobileLoading",
                                      },
                                    ],
                                    ref: "mobileViewButton",
                                    staticClass: "info-block-btn",
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyView("mobile")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    查看手机\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originalResumeData.mobileStatus == 2
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      effect: "light",
                                      "popper-class": "concat-tooltip",
                                      "visible-arrow": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        候选人正在入职保证期中，暂不能查看其联"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "系方式\n                                    "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "info-lock-btn" },
                                      [
                                        _vm._v(
                                          "\n                                        查看手机\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass: "icon-font icon-suo",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originalResumeData.mobileStatus == 3 ||
                            _vm.originalResumeData.mobileStatus == 4
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.originalResumeData.mobileStatus == 4
                                        ? "mobile-unable"
                                        : "",
                                      _vm.getHighlightClass("mobile"),
                                      _vm.highlightKey == "mobile"
                                        ? "highlight-weight"
                                        : "",
                                    ],
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.handleHighlightMouseEnter(
                                          "mobile"
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.handleHighlightMouseLeave(
                                          "mobile"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    手机：" +
                                        _vm._s(
                                          _vm.originalResumeData.mobile ||
                                            "xxxxxxxxxxx"
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "basic-grid-item" },
                          [
                            _vm.originalResumeData.emailStatus == 1 ||
                            _vm.originalResumeData.emailStatus == 2
                              ? _c("span", [_vm._v("邮箱：")])
                              : _vm._e(),
                            _vm.originalResumeData.emailStatus == 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.emailLoading,
                                        expression: "emailLoading",
                                      },
                                    ],
                                    ref: "emailViewButton",
                                    staticClass: "info-block-btn",
                                    attrs: {
                                      plain: "",
                                      type: "primary",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyView("email")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    查看邮箱\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originalResumeData.emailStatus == 2
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      effect: "light",
                                      "popper-class": "concat-tooltip",
                                      "visible-arrow": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        候选人正在入职保证期中，暂不能查看其联"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "系方式\n                                    "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "info-lock-btn" },
                                      [
                                        _vm._v(
                                          "\n                                        查看邮箱\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass: "icon-font icon-suo",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originalResumeData.emailStatus == 3 ||
                            _vm.originalResumeData.emailStatus == 4
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.originalResumeData.emailStatus == 4
                                        ? "email-unable"
                                        : "",
                                      _vm.getHighlightClass("email"),
                                      _vm.highlightKey == "email"
                                        ? "highlight-weight"
                                        : "",
                                    ],
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.handleHighlightMouseEnter(
                                          "email"
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.handleHighlightMouseLeave(
                                          "email"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    邮箱：" +
                                        _vm._s(
                                          _vm.originalResumeData.email ||
                                            "xxx@xx.com"
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "basic-grid-item" },
                          [
                            _vm.originalResumeData.wechatStatus == 1 ||
                            _vm.originalResumeData.wechatStatus == 2
                              ? _c("span", [_vm._v("微信：")])
                              : _vm._e(),
                            _vm.originalResumeData.wechatStatus == 1
                              ? _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.wechatLoading,
                                        expression: "wechatLoading",
                                      },
                                    ],
                                    ref: "wechatViewButton",
                                    staticClass: "info-block-btn",
                                    attrs: { type: "primary", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyView("wechat")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    查看微信\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originalResumeData.wechatStatus == 2
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "bottom",
                                      effect: "light",
                                      "popper-class": "concat-tooltip",
                                      "visible-arrow": false,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        候选人正在入职保证期中，暂不能查看其联"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "系方式\n                                    "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "info-lock-btn" },
                                      [
                                        _vm._v(
                                          "\n                                        查看微信\n                                        "
                                        ),
                                        _c("i", {
                                          staticClass: "icon-font icon-suo",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.originalResumeData.wechatStatus == 3 ||
                            _vm.originalResumeData.wechatStatus == 4
                              ? _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.originalResumeData.wechatStatus == 4
                                        ? "wechat-unable"
                                        : "",
                                      _vm.getHighlightClass("wechat"),
                                      _vm.highlightKey == "wechat"
                                        ? "highlight-weight"
                                        : "",
                                    ],
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.handleHighlightMouseEnter(
                                          "wechat"
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.handleHighlightMouseLeave(
                                          "wechat"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    微信：" +
                                        _vm._s(
                                          _vm.originalResumeData.wechat ||
                                            "xxxxxx"
                                        ) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                  _c("div", { staticClass: "block-item-right" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "\n                            基本资料\n                        "
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "basic-items" },
                      [
                        _vm._l(_vm.basicFields, function (key) {
                          return [
                            _vm.resumeDataUpload[
                              key == "age" ? "birthday" : key
                            ]
                              ? _c(
                                  "span",
                                  {
                                    key: key,
                                    class: [
                                      _vm.getHighlightClass(key),
                                      _vm.highlightKey == key
                                        ? "highlight-weight"
                                        : "",
                                    ],
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.handleHighlightMouseEnter(
                                          key
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.handleHighlightMouseLeave(
                                          key
                                        )
                                      },
                                    },
                                  },
                                  [
                                    key == "realName"
                                      ? [
                                          _vm._v(
                                            "姓名：" +
                                              _vm._s(
                                                _vm.resumeDataUpload.realName
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "gender"
                                      ? [
                                          _vm._v(
                                            "性别：" +
                                              _vm._s(
                                                _vm.genderMap[
                                                  _vm.resumeDataUpload.gender
                                                ]
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "yearOfExperience"
                                      ? [
                                          _vm._v(
                                            "工作经验：" +
                                              _vm._s(
                                                _vm.resumeDataUpload
                                                  .yearOfExperience
                                              ) +
                                              "年"
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "age"
                                      ? [
                                          _vm._v(
                                            "年龄：" +
                                              _vm._s(
                                                _vm.getAgeFromBirthday(
                                                  _vm.resumeDataUpload.birthday
                                                )
                                              ) +
                                              "岁"
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "company"
                                      ? [
                                          _vm._v(
                                            "公司：" +
                                              _vm._s(
                                                _vm.resumeDataUpload.company
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "title"
                                      ? [
                                          _vm._v(
                                            "职位：" +
                                              _vm._s(_vm.resumeDataUpload.title)
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "city"
                                      ? [
                                          _vm._v(
                                            "城市：" +
                                              _vm._s(
                                                _vm.provinceCityMap[
                                                  _vm.resumeDataUpload.city
                                                ] || "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "school"
                                      ? [
                                          _vm._v(
                                            "学校：" +
                                              _vm._s(
                                                _vm.resumeDataUpload.school
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "major"
                                      ? [
                                          _vm._v(
                                            "专业：" +
                                              _vm._s(_vm.resumeDataUpload.major)
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "degree"
                                      ? [
                                          _vm._v(
                                            "学历：" +
                                              _vm._s(
                                                _vm.degreeMap[
                                                  _vm.resumeDataUpload.degree
                                                ] || "未知"
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "mobile"
                                      ? [
                                          _vm._v(
                                            "手机：" +
                                              _vm._s(
                                                _vm.resumeDataUpload.mobile
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "email"
                                      ? [
                                          _vm._v(
                                            "邮箱：" +
                                              _vm._s(_vm.resumeDataUpload.email)
                                          ),
                                        ]
                                      : _vm._e(),
                                    key == "wechat"
                                      ? [
                                          _vm._v(
                                            "微信：" +
                                              _vm._s(
                                                _vm.resumeDataUpload.wechat
                                              )
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "block-item experience-block work" }, [
                  _c("div", { staticClass: "block-item-left" }, [
                    _c(
                      "div",
                      { staticClass: "block-item-list" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            "\n                                工作经历\n                                "
                          ),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addItem("workExperiences")
                                },
                              },
                            },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-tianjia" },
                              }),
                              _vm._v("新增工作经历"),
                            ],
                            1
                          ),
                        ]),
                        !(
                          _vm.originalResumeData.workExperiences &&
                          _vm.originalResumeData.workExperiences.length > 0
                        ) && _vm.editWorkExperiencesIndex == -1
                          ? [_c("div", [_vm._v("无")])]
                          : _vm._l(
                              _vm.originalResumeData.workExperiences,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "normal-item" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "normal-item-title" },
                                      [
                                        _c("span", [
                                          _c(
                                            "span",
                                            { staticClass: "company-text" },
                                            [_vm._v(_vm._s(item.company))]
                                          ),
                                          item.title
                                            ? _c(
                                                "span",
                                                { staticClass: "title-text" },
                                                [_vm._v(_vm._s(item.title))]
                                              )
                                            : _vm._e(),
                                          item.isNew
                                            ? _c(
                                                "i",
                                                { staticClass: "new-icon" },
                                                [_vm._v("新")]
                                              )
                                            : _vm._e(),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  index !=
                                                  _vm.editWorkExperiencesIndex,
                                                expression:
                                                  "index != editWorkExperiencesIndex",
                                              },
                                            ],
                                            staticClass: "normal-btns",
                                          },
                                          [
                                            item.isNew && !item.isReplaced
                                              ? _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteLeftWorkFromRight(
                                                          item,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("font-icon", {
                                                      staticClass:
                                                        "replace-font-icon",
                                                      attrs: {
                                                        href: "#icon-shanchu",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            item.isReplaced
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "撤销替换",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.revokeExperience(
                                                              index,
                                                              "work"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("font-icon", {
                                                          staticClass:
                                                            "replace-font-icon revoke-icon",
                                                          attrs: {
                                                            href: "#icon-chexiao",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            !item.isNew &&
                                            !item.isReplaced &&
                                            _vm.resumeDataUpload
                                              .workExperiences &&
                                            _vm.resumeDataUpload.workExperiences
                                              .length > 0
                                              ? _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "替换为",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.replaceExperience(
                                                              index,
                                                              "work"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("font-icon", {
                                                          staticClass:
                                                            "replace-font-icon",
                                                          attrs: {
                                                            href: "#icon-tihuan3",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleWorkExperiencesEdit(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-icon", {
                                                  attrs: {
                                                    href: "#icon-bianji",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "normal-item-info" },
                                      [
                                        _vm.monthFormat(item)
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.monthFormat(item))
                                              ),
                                            ])
                                          : _vm._e(),
                                        item.location ? _c("i") : _vm._e(),
                                        item.location
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.provinceCityMap[
                                                    item.location
                                                  ] || "未知"
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.description,
                                            expression: "item.description",
                                          },
                                        ],
                                        staticClass: "normal-item-expand",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "normal-item-expand-title",
                                          },
                                          [_vm._v("工作内容:")]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.descriptionOverflow &&
                                                  !item.descriptionExpand,
                                                expression:
                                                  "item.descriptionOverflow && !item.descriptionExpand",
                                              },
                                            ],
                                            staticClass: "expand-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.expandWorkDescription(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: {
                                                href: "#icon-arrow-down",
                                              },
                                            }),
                                            _vm._v("展开"),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  item.descriptionOverflow &&
                                                  item.descriptionExpand,
                                                expression:
                                                  "item.descriptionOverflow && item.descriptionExpand",
                                              },
                                            ],
                                            staticClass: "fold-icon",
                                            on: {
                                              click: function ($event) {
                                                return _vm.foldWorkDescription(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: {
                                                href: "#icon-arrow-down",
                                              },
                                            }),
                                            _vm._v(
                                              "收起\n                                    "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: item.description,
                                            expression: "item.description",
                                          },
                                        ],
                                        staticClass: "normal-item-expand",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "expand-content desc",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "expand-content-text",
                                                class:
                                                  item.descriptionOverflow &&
                                                  !item.descriptionExpand
                                                    ? "expand-text-fold"
                                                    : "",
                                              },
                                              [_vm._v(_vm._s(item.description))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    index == _vm.editWorkExperiencesIndex
                                      ? _c("compare-resume-work-form", {
                                          ref: "editResumeWorkForm",
                                          refInFor: true,
                                          attrs: {
                                            "is-save-to-db": false,
                                            currentWork:
                                              _vm.mergeCurrentWork(index),
                                            "candidate-id": _vm.candidateId,
                                            "edit-url": _vm.editUrl,
                                            "add-url": _vm.addUrl,
                                          },
                                          on: {
                                            "resume-work-form-cancel":
                                              _vm.handleFormCancel,
                                            "save-btn-callback": (val) =>
                                              _vm.handleSaveBtnCallback(
                                                val,
                                                "workExperiences"
                                              ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.editWorkExperiencesIndex == 10000,
                                expression: "editWorkExperiencesIndex == 10000",
                              },
                            ],
                            staticClass: "form-wrap",
                          },
                          [
                            _c("compare-resume-work-form", {
                              ref: "compareResumeWorkForm",
                              attrs: {
                                "is-save-to-db": false,
                                "candidate-id": _vm.candidateId,
                                "edit-url": _vm.editUrl,
                                "add-url": _vm.addUrl,
                              },
                              on: {
                                "resume-work-form-cancel": function ($event) {
                                  return _vm.handleFormCancel("work")
                                },
                                "save-btn-callback": (val) =>
                                  _vm.handleSaveBtnCallback(
                                    val,
                                    "workExperiences"
                                  ),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "block-item-right" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("工作经历")]),
                      _vm.resumeDataUpload.workExperiences &&
                      _vm.resumeDataUpload.workExperiences.length > 0
                        ? _vm._l(
                            _vm.resumeDataUpload.workExperiences,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "normal-item",
                                  class:
                                    item.hasAddToLeft || item.isReplaced
                                      ? "grey"
                                      : "",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "normal-item-text" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-title" },
                                        [
                                          _c("span", [
                                            _c("i", { staticClass: "desc" }, [
                                              _vm._v(_vm._s(item.company)),
                                            ]),
                                            item.isNew
                                              ? _c(
                                                  "i",
                                                  { staticClass: "new-icon" },
                                                  [_vm._v("新")]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-info" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.title || "未知")
                                            ),
                                          ]),
                                          _vm.monthFormat(item)
                                            ? _c("i")
                                            : _vm._e(),
                                          _vm.monthFormat(item)
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.monthFormat(item))
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  item.hasAddToLeft
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "normal-item-operation",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    已补充至原简历\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.isReplaced
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "normal-item-operation",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    已被替换\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !item.hasAddToLeft &&
                                  !item.isReplaced &&
                                  item.isNew
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "normal-item-operation",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleAddWorkToLeft(
                                                item,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("font-icon", {
                                            attrs: { href: "#icon-tianjia1" },
                                          }),
                                          _vm._v(
                                            "\n                                    添加至左侧\n                                "
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          )
                        : _c("div", [_vm._v("无")]),
                    ],
                    2
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "block-item experience-block education" },
                  [
                    _c("div", { staticClass: "block-item-left" }, [
                      _c(
                        "div",
                        { staticClass: "block-item-list" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n                                教育经历\n                                "
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addItem("educationExperiences")
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-tianjia" },
                                }),
                                _vm._v("新增教育经历"),
                              ],
                              1
                            ),
                          ]),
                          !(
                            _vm.originalResumeData.educationExperiences &&
                            _vm.originalResumeData.educationExperiences.length >
                              0
                          ) && _vm.editEducationExperiencesIndex == -1
                            ? [_c("div", [_vm._v("无")])]
                            : _vm._l(
                                _vm.originalResumeData.educationExperiences,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "normal-item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-title" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.school) + " "),
                                            item.isNew
                                              ? _c(
                                                  "i",
                                                  { staticClass: "new-icon" },
                                                  [_vm._v("新")]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index !=
                                                    _vm.editEducationExperiencesIndex,
                                                  expression:
                                                    "index != editEducationExperiencesIndex",
                                                },
                                              ],
                                              staticClass: "normal-btns",
                                            },
                                            [
                                              item.isNew && !item.isReplaced
                                                ? _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteLeftEducationFromRight(
                                                            item,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-icon", {
                                                        staticClass:
                                                          "replace-font-icon",
                                                        attrs: {
                                                          href: "#icon-shanchu",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.isReplaced
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "撤销替换",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.revokeExperience(
                                                                index,
                                                                "education"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("font-icon", {
                                                            staticClass:
                                                              "replace-font-icon revoke-icon",
                                                            attrs: {
                                                              href: "#icon-chexiao",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !item.isNew &&
                                              !item.isReplaced &&
                                              _vm.resumeDataUpload
                                                .educationExperiences &&
                                              _vm.resumeDataUpload
                                                .educationExperiences.length > 0
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "替换为",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.replaceExperience(
                                                                index,
                                                                "education"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("font-icon", {
                                                            staticClass:
                                                              "replace-font-icon",
                                                            attrs: {
                                                              href: "#icon-tihuan3",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleEducationExperiencesEdit(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-bianji",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-info" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.degreeMap[item.degree] ||
                                                  "未知"
                                              )
                                            ),
                                          ]),
                                          _c("i"),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "major-text",
                                              attrs: { title: item.major },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(item.major || "未知")
                                              ),
                                            ]
                                          ),
                                          _vm.monthFormat(item)
                                            ? _c("i")
                                            : _vm._e(),
                                          _vm.monthFormat(item)
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.monthFormat(item))
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      index == _vm.editEducationExperiencesIndex
                                        ? _c("compare-resume-education-form", {
                                            ref: "editResumeEducationForm",
                                            refInFor: true,
                                            attrs: {
                                              "is-save-to-db": false,
                                              currentEducation:
                                                _vm.originalResumeData
                                                  .educationExperiences[index],
                                              "candidate-id": _vm.candidateId,
                                              "edit-url": _vm.editUrl,
                                              "add-url": _vm.addUrl,
                                            },
                                            on: {
                                              "resume-education-form-cancel":
                                                _vm.handleFormCancel,
                                              "save-btn-callback": (val) =>
                                                _vm.handleSaveBtnCallback(
                                                  val,
                                                  "educationExperiences"
                                                ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editEducationExperiencesIndex == 10000,
                                  expression:
                                    "editEducationExperiencesIndex == 10000",
                                },
                              ],
                              staticClass: "form-wrap",
                            },
                            [
                              _c("compare-resume-education-form", {
                                ref: "compareResumeEducationForm",
                                attrs: {
                                  "is-save-to-db": false,
                                  "candidate-id": _vm.candidateId,
                                  "edit-url": _vm.editUrl,
                                  "add-url": _vm.addUrl,
                                },
                                on: {
                                  "resume-education-form-cancel": function (
                                    $event
                                  ) {
                                    return _vm.handleFormCancel("education")
                                  },
                                  "save-btn-callback": (val) =>
                                    _vm.handleSaveBtnCallback(
                                      val,
                                      "educationExperiences"
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block-item-right" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("教育经历"),
                        ]),
                        _vm.resumeDataUpload.educationExperiences &&
                        _vm.resumeDataUpload.educationExperiences.length > 0
                          ? _vm._l(
                              _vm.resumeDataUpload.educationExperiences,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "normal-item",
                                    class:
                                      item.hasAddToLeft || item.isReplaced
                                        ? "grey"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "normal-item-text" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "normal-item-title" },
                                          [
                                            _c("span", [
                                              _c("i", { staticClass: "desc" }, [
                                                _vm._v(_vm._s(item.school)),
                                              ]),
                                              item.isNew
                                                ? _c(
                                                    "i",
                                                    { staticClass: "new-icon" },
                                                    [_vm._v("新")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "normal-item-info" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.degreeMap[item.degree]
                                                )
                                              ),
                                            ]),
                                            _c("i"),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "major-text",
                                                attrs: { title: item.major },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.major || "未知")
                                                ),
                                              ]
                                            ),
                                            _vm.monthFormat(item)
                                              ? _c("i")
                                              : _vm._e(),
                                            _vm.monthFormat(item)
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.monthFormat(item)
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    item.hasAddToLeft
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    已补充至原简历\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.isReplaced
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    已被替换\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !item.hasAddToLeft &&
                                    !item.isReplaced &&
                                    item.isNew
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddEducationToLeft(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-tianjia1" },
                                            }),
                                            _vm._v(
                                              "\n                                    添加至左侧\n                                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            )
                          : _c("div", [_vm._v("无")]),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "block-item experience-block project" },
                  [
                    _c("div", { staticClass: "block-item-left" }, [
                      _c(
                        "div",
                        { staticClass: "block-item-list" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n                                项目经历\n                                "
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addItem("projectExperiences")
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-tianjia" },
                                }),
                                _vm._v("新增项目经历"),
                              ],
                              1
                            ),
                          ]),
                          !(
                            _vm.originalResumeData.projectExperiences &&
                            _vm.originalResumeData.projectExperiences.length > 0
                          ) && _vm.editProjectExperiencesIndex == -1
                            ? [_c("div", [_vm._v("无")])]
                            : _vm._l(
                                _vm.originalResumeData.projectExperiences,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "normal-item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-title" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.projectName) + " "
                                            ),
                                            item.isNew
                                              ? _c(
                                                  "i",
                                                  { staticClass: "new-icon" },
                                                  [_vm._v("新")]
                                                )
                                              : _vm._e(),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index !=
                                                    _vm.editProjectExperiencesIndex,
                                                  expression:
                                                    "index != editProjectExperiencesIndex",
                                                },
                                              ],
                                              staticClass: "normal-btns",
                                            },
                                            [
                                              item.isNew && !item.isReplaced
                                                ? _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteLeftProjectFromRight(
                                                            item,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-icon", {
                                                        staticClass:
                                                          "replace-font-icon",
                                                        attrs: {
                                                          href: "#icon-shanchu",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.isReplaced
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "撤销替换",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.revokeExperience(
                                                                index,
                                                                "project"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("font-icon", {
                                                            staticClass:
                                                              "replace-font-icon revoke-icon",
                                                            attrs: {
                                                              href: "#icon-chexiao",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !item.isNew &&
                                              !item.isReplaced &&
                                              _vm.resumeDataUpload
                                                .projectExperiences &&
                                              _vm.resumeDataUpload
                                                .projectExperiences.length > 0
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "替换为",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.replaceExperience(
                                                                index,
                                                                "project"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("font-icon", {
                                                            staticClass:
                                                              "replace-font-icon",
                                                            attrs: {
                                                              href: "#icon-tihuan3",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleProjectExperiencesEdit(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-bianji",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-info" },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item.projectRole || "未知")
                                            ),
                                          ]),
                                          _vm.monthFormat(item)
                                            ? _c("i")
                                            : _vm._e(),
                                          _vm.monthFormat(item)
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.monthFormat(item))
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.responsibility,
                                              expression: "item.responsibility",
                                            },
                                          ],
                                          staticClass: "normal-item-expand",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "normal-item-expand-title",
                                            },
                                            [_vm._v("项目职责:")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.responsibilityOverflow &&
                                                    item.responsibilityExpand,
                                                  expression:
                                                    "item.responsibilityOverflow && item.responsibilityExpand",
                                                },
                                              ],
                                              staticClass: "fold-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.foldProjectResponsibility(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                attrs: {
                                                  href: "#icon-arrow-down",
                                                },
                                              }),
                                              _vm._v("收起"),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.responsibilityOverflow &&
                                                    !item.responsibilityExpand,
                                                  expression:
                                                    "item.responsibilityOverflow && !item.responsibilityExpand",
                                                },
                                              ],
                                              staticClass: "expand-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.expandProjectResponsibility(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                attrs: {
                                                  href: "#icon-arrow-down",
                                                },
                                              }),
                                              _vm._v("展开"),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.responsibility,
                                              expression: "item.responsibility",
                                            },
                                          ],
                                          staticClass: "normal-item-expand",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "expand-content responsibility",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "expand-content-text",
                                                  class:
                                                    item.responsibilityOverflow &&
                                                    !item.responsibilityExpand
                                                      ? "expand-text-fold"
                                                      : "",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.responsibility
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.description,
                                              expression: "item.description",
                                            },
                                          ],
                                          staticClass: "normal-item-expand",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "normal-item-expand-title",
                                            },
                                            [_vm._v("项目描述:")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.descriptionOverflow &&
                                                    item.descriptionExpand,
                                                  expression:
                                                    "item.descriptionOverflow && item.descriptionExpand",
                                                },
                                              ],
                                              staticClass: "fold-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.foldProjectDescription(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                attrs: {
                                                  href: "#icon-arrow-down",
                                                },
                                              }),
                                              _vm._v("收起"),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    item.descriptionOverflow &&
                                                    !item.descriptionExpand,
                                                  expression:
                                                    "item.descriptionOverflow && !item.descriptionExpand",
                                                },
                                              ],
                                              staticClass: "expand-icon",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.expandProjectDescription(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("font-icon", {
                                                attrs: {
                                                  href: "#icon-arrow-down",
                                                },
                                              }),
                                              _vm._v("展开"),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: item.description,
                                              expression: "item.description",
                                            },
                                          ],
                                          staticClass: "normal-item-expand",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "expand-content desc",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "expand-content-text",
                                                  class:
                                                    item.descriptionOverflow &&
                                                    !item.descriptionExpand
                                                      ? "expand-text-fold"
                                                      : "",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.description)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      index == _vm.editProjectExperiencesIndex
                                        ? _c("compare-resume-project-form", {
                                            ref: "editResumeProjectForm",
                                            refInFor: true,
                                            attrs: {
                                              "is-save-to-db": false,
                                              "is-edit": true,
                                              currentProject:
                                                _vm.originalResumeData
                                                  .projectExperiences[index],
                                              "candidate-id": _vm.candidateId,
                                              "edit-url": _vm.editUrl,
                                              "add-url": _vm.addUrl,
                                            },
                                            on: {
                                              "resume-project-form-cancel":
                                                _vm.handleFormCancel,
                                              "save-btn-callback": (val) =>
                                                _vm.handleSaveBtnCallback(
                                                  val,
                                                  "projectExperiences"
                                                ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }
                              ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editProjectExperiencesIndex == 10000,
                                  expression:
                                    "editProjectExperiencesIndex == 10000",
                                },
                              ],
                              staticClass: "form-wrap",
                            },
                            [
                              _c("compare-resume-project-form", {
                                ref: "compareResumeProjectForm",
                                attrs: {
                                  "is-save-to-db": false,
                                  "is-edit": false,
                                  "candidate-id": _vm.candidateId,
                                  "edit-url": _vm.editUrl,
                                  "add-url": _vm.addUrl,
                                },
                                on: {
                                  "resume-project-form-cancel": function (
                                    $event
                                  ) {
                                    return _vm.handleFormCancel("project")
                                  },
                                  "save-btn-callback": (val) =>
                                    _vm.handleSaveBtnCallback(
                                      val,
                                      "projectExperiences"
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block-item-right" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("项目经历"),
                        ]),
                        _vm.resumeDataUpload.projectExperiences &&
                        _vm.resumeDataUpload.projectExperiences.length > 0
                          ? _vm._l(
                              _vm.resumeDataUpload.projectExperiences,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "normal-item",
                                    class:
                                      item.hasAddToLeft || item.isReplaced
                                        ? "grey"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "normal-item-text" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "normal-item-title" },
                                          [
                                            _c("span", [
                                              _c("i", { staticClass: "desc" }, [
                                                _vm._v(
                                                  _vm._s(item.projectName)
                                                ),
                                              ]),
                                              item.isNew
                                                ? _c(
                                                    "i",
                                                    { staticClass: "new-icon" },
                                                    [_vm._v("新")]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "normal-item-info" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item.projectRole || "未知"
                                                )
                                              ),
                                            ]),
                                            _vm.monthFormat(item)
                                              ? _c("i")
                                              : _vm._e(),
                                            _vm.monthFormat(item)
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.monthFormat(item)
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                    item.hasAddToLeft
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    已补充至原简历\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.isReplaced
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    已被替换\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !item.hasAddToLeft &&
                                    !item.isReplaced &&
                                    item.isNew
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddProjectToLeft(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-tianjia1" },
                                            }),
                                            _vm._v(
                                              "\n                                    添加至左侧\n                                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            )
                          : _c("div", [_vm._v("无")]),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "block-item experience-block evaluate" },
                  [
                    _c("div", { staticClass: "block-item-left" }, [
                      _c(
                        "div",
                        { staticClass: "block-item-list" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n                                自我评价\n                                "
                            ),
                            !_vm.originalResumeData.selfEvaluation
                              ? _c(
                                  "span",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.addItem("selfEvaluation")
                                      },
                                    },
                                  },
                                  [
                                    _c("font-icon", {
                                      attrs: { href: "#icon-tianjia" },
                                    }),
                                    _vm._v("新增自我评价"),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "evaluate-add-btn" },
                                  [
                                    !_vm.showSelfEvaluateEdit &&
                                    _vm.resumeDataUpload.selfEvaluation &&
                                    _vm.replaceSelfEvaluateBtnVisible
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            attrs: {
                                              effect: "dark",
                                              content: "替换为",
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.replaceSelfEvaluate(
                                                      _vm.index,
                                                      "project"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-icon", {
                                                  staticClass:
                                                    "replace-font-icon",
                                                  attrs: {
                                                    href: "#icon-tihuan3",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: _vm.handleSelfEvaluateEdit,
                                        },
                                      },
                                      [
                                        _c("font-icon", {
                                          attrs: { href: "#icon-bianji" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                          _vm.originalResumeData.selfEvaluation &&
                          !_vm.showSelfEvaluateEdit
                            ? _c("div", { staticClass: "self-evaluate-text" }, [
                                _vm._v(
                                  _vm._s(_vm.originalResumeData.selfEvaluation)
                                ),
                              ])
                            : _vm._e(),
                          !_vm.originalResumeData.selfEvaluation &&
                          !_vm.showSelfEvaluateEdit
                            ? _c("div", [_vm._v("无")])
                            : _vm._e(),
                          _c("el-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.showSelfEvaluateEdit,
                                expression: "showSelfEvaluateEdit",
                              },
                            ],
                            staticClass: "self-evaluate-input",
                            attrs: { type: "textarea", maxlength: "4000" },
                            model: {
                              value: _vm.originalResumeData.selfEvaluation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.originalResumeData,
                                  "selfEvaluation",
                                  $$v
                                )
                              },
                              expression: "originalResumeData.selfEvaluation",
                            },
                          }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showSelfEvaluateEdit,
                                  expression: "showSelfEvaluateEdit",
                                },
                              ],
                              staticClass: "self-evaluate-btns",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: _vm.handleCancelEditSelfEvaluate,
                                  },
                                },
                                [_vm._v("取消")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.handleSaveSelfEvaluate },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block-item-right" },
                      [
                        _c(
                          "div",
                          { staticClass: "title self-evaluate-title" },
                          [
                            _vm._v(
                              "\n                            自我评价\n                            "
                            ),
                            _vm.resumeDataUpload.selfEvaluation &&
                            _vm.replaceSelfEvaluateBtnVisible
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "normal-item-operation",
                                    on: {
                                      click: _vm.handleAddSelfEvaluateToLeft,
                                    },
                                  },
                                  [
                                    _c("font-icon", {
                                      attrs: { href: "#icon-tianjia1" },
                                    }),
                                    _vm._v(
                                      "\n                                添加至左侧\n                            "
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm.resumeDataUpload.selfEvaluation
                          ? [
                              _c("div", { staticClass: "evaluate-item" }, [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.resumeDataUpload.selfEvaluation
                                    ) +
                                    "\n                            "
                                ),
                              ]),
                            ]
                          : _c("div", [_vm._v("无")]),
                      ],
                      2
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "block-item experience-block intention" },
                  [
                    _c("div", { staticClass: "block-item-left" }, [
                      _c(
                        "div",
                        { staticClass: "block-item-list" },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n                                期望工作\n                                "
                            ),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.addItem("intention")
                                  },
                                },
                              },
                              [
                                _c("font-icon", {
                                  attrs: { href: "#icon-tianjia" },
                                }),
                                _vm._v("新增期望工作"),
                              ],
                              1
                            ),
                          ]),
                          !(
                            _vm.originalResumeData.intentions &&
                            _vm.originalResumeData.intentions.length > 0
                          ) && _vm.editIntentionsIndex == -1
                            ? [_c("div", [_vm._v("无")])]
                            : _vm._l(
                                _vm.originalResumeData.intentions,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "normal-item intention-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "normal-item-info" },
                                        [
                                          _c("span", [
                                            item.locationId
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.provinceCityMap[
                                                        item.locationId
                                                      ] || "未知"
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.locationId
                                              ? _c("i")
                                              : _vm._e(),
                                            _c("span", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                            _c("i"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.minSalary) +
                                                  "-" +
                                                  _vm._s(item.maxSalary) +
                                                  "K"
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    index !=
                                                    _vm.editIntentionsIndex,
                                                  expression:
                                                    "index != editIntentionsIndex",
                                                },
                                              ],
                                              staticClass: "normal-btns",
                                            },
                                            [
                                              item.isNew && !item.isReplaced
                                                ? _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteLeftIntentionFromRight(
                                                            item,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("font-icon", {
                                                        staticClass:
                                                          "replace-font-icon",
                                                        attrs: {
                                                          href: "#icon-shanchu",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.isReplaced
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "撤销替换",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.revokeExperience(
                                                                index,
                                                                "intention"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("font-icon", {
                                                            staticClass:
                                                              "replace-font-icon revoke-icon",
                                                            attrs: {
                                                              href: "#icon-chexiao",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !item.isNew &&
                                              !item.isReplaced &&
                                              _vm.resumeDataUpload.intentions &&
                                              _vm.resumeDataUpload.intentions
                                                .length > 0
                                                ? _c(
                                                    "el-tooltip",
                                                    {
                                                      staticClass: "item",
                                                      attrs: {
                                                        effect: "dark",
                                                        content: "替换为",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.replaceExperience(
                                                                index,
                                                                "intention"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("font-icon", {
                                                            staticClass:
                                                              "replace-font-icon",
                                                            attrs: {
                                                              href: "#icon-tihuan3",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleIntentionEdit(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("font-icon", {
                                                    attrs: {
                                                      href: "#icon-bianji",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      index == _vm.editIntentionsIndex
                                        ? _c(
                                            "div",
                                            { staticClass: "form-wrap" },
                                            [
                                              _c(
                                                "compare-resume-intention-form",
                                                {
                                                  ref: "editResumeIntentionForm",
                                                  refInFor: true,
                                                  attrs: {
                                                    "is-save-to-db": false,
                                                    "is-edit": true,
                                                    currentIntention:
                                                      _vm.originalResumeData
                                                        .intentions[index],
                                                    "candidate-id":
                                                      _vm.candidateId,
                                                    "edit-url": _vm.editUrl,
                                                    "add-url": _vm.addUrl,
                                                  },
                                                  on: {
                                                    "resume-intention-form-cancel":
                                                      _vm.handleFormCancel,
                                                    "save-btn-callback": (
                                                      val
                                                    ) =>
                                                      _vm.handleSaveBtnCallback(
                                                        val,
                                                        "intentions"
                                                      ),
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editIntentionsIndex == 10000,
                                  expression: "editIntentionsIndex == 10000",
                                },
                              ],
                              staticClass: "form-wrap",
                            },
                            [
                              _c("compare-resume-intention-form", {
                                ref: "compareResumeIntentionForm",
                                attrs: {
                                  "is-save-to-db": false,
                                  "is-edit": false,
                                  "candidate-id": _vm.candidateId,
                                  "edit-url": _vm.editUrl,
                                  "add-url": _vm.addUrl,
                                },
                                on: {
                                  "resume-intention-form-cancel":
                                    _vm.handleFormCancel,
                                  "save-btn-callback": (val) =>
                                    _vm.handleSaveBtnCallback(
                                      val,
                                      "intentions"
                                    ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "block-item-right" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("期望工作"),
                        ]),
                        _vm.resumeDataUpload.intentions &&
                        _vm.resumeDataUpload.intentions.length > 0
                          ? _vm._l(
                              _vm.resumeDataUpload.intentions,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "normal-item intention-item",
                                    class:
                                      item.hasAddToLeft || item.isReplaced
                                        ? "grey"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "normal-item-text" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "normal-item-info" },
                                          [
                                            item.locationId
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.provinceCityMap[
                                                        item.locationId
                                                      ] || "未知"
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                            item.locationId
                                              ? _c("i")
                                              : _vm._e(),
                                            _c("span", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                            _c("i"),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.minSalary) +
                                                  "-" +
                                                  _vm._s(item.maxSalary) +
                                                  "K"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    item.hasAddToLeft
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    已补充至原简历\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.isReplaced
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    已被替换\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !item.hasAddToLeft &&
                                    !item.isReplaced &&
                                    item.isNew
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "normal-item-operation",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddIntentionToLeft(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("font-icon", {
                                              attrs: { href: "#icon-tianjia1" },
                                            }),
                                            _vm._v(
                                              "\n                                    添加至左侧\n                                "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            )
                          : _c("div", [_vm._v("无")]),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "page-footer" }, [
        _c("div", { staticClass: "page-footer-content" }, [
          _c("p", [_vm._v("简历已存在，请将右侧你上传的简历内容补充")]),
          _c(
            "div",
            { staticClass: "page-footer-btns" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain", loading: _vm.saveLoading },
                  on: { click: _vm.giveUpEdit },
                },
                [_vm._v("放弃")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "80px" },
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("replace-experience", {
        ref: "replaceExperienceDialog",
        on: { "replace-selected": _vm.handleReplaceSelected },
      }),
      _c("update-base-info-dialog", {
        ref: "updateBaseInfoDialog",
        attrs: { "is-save-to-db": false, isRecommend: false },
        on: { "save-btn-callback": _vm.handleBaseInfoSave },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.revokeDialogVisible,
            title: "提示",
            width: "480px",
            "custom-class": "revoke-dialog",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.revokeDialogVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("您确定要撤销当前内容吗？撤销后将放回右侧")]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleCancelRevoke } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleRevokeExperience },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.quitEditDialogVisible,
            title: "提示",
            width: "480px",
            "custom-class": "quit-edit-dialog",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.quitEditDialogVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("有内容没有保存，确定退出编辑吗？")]),
          _c("p", { staticClass: "desc-text" }, [
            _vm._v("退出编辑后，更新的内容不会自动保存"),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.quitEditDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleQuitEdit },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.replaceSelfEvaluateDialogVisible,
            title: "提示",
            width: "480px",
            "custom-class": "replace-self-evaluate-dialog",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.replaceSelfEvaluateDialogVisible = $event
            },
          },
        },
        [
          _c("p", [_vm._v("是否替换为新上传简历中的自我评价？")]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.replaceSelfEvaluateDialogVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleReplaceSelfEvaluate },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }