var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "compareResumeEducationForm",
      staticClass: "compare-resume-education-form col-15",
      attrs: {
        id: "compare-resume-education-form",
        model: _vm.formData,
        rules: _vm.rules,
        "label-width": "80px",
        "label-position": "top",
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "学校", prop: "school" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "select-search select-search-school",
                    attrs: {
                      "fetch-suggestions": _vm.querySchoolSearch,
                      placeholder: "请输入内容",
                      maxlength: 128,
                      "popper-class": "edit-dialog-autocomplete",
                    },
                    on: { select: _vm.handleSchool },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.formData.school,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "school", $$v)
                      },
                      expression: "formData.school",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space" }),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "专业", prop: "major" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "select-search",
                    attrs: {
                      "fetch-suggestions": _vm.queryMajorSearch,
                      placeholder: "请输入内容",
                      maxlength: 128,
                      "popper-class": "edit-dialog-autocomplete",
                    },
                    on: { select: _vm.handleMajor },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.formData.major,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "major", $$v)
                      },
                      expression: "formData.major",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开始时间", prop: "startDate" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "start-date-picker",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "month",
                              placeholder: "如 2018-08",
                              "value-format": "yyyy-MM",
                              "picker-options": _vm.startDateOptions,
                            },
                            model: {
                              value: _vm.formData.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "startDate", $$v)
                              },
                              expression: "formData.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "space-lg" }, [_vm._v("至")]),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "end-date-item",
                          attrs: { label: "结束时间", prop: "endDate" },
                        },
                        [
                          _c("el-date-picker", {
                            ref: "endDatePicker",
                            staticClass: "end-date-picker",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "month",
                              placeholder: "如 2018-08",
                              "value-format": "yyyy-MM",
                              "picker-options": _vm.endDateOptions,
                            },
                            model: {
                              value: _vm.formData.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "endDate", $$v)
                              },
                              expression: "formData.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space" }),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "学历", prop: "degree" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "degree-select-input",
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.formData.degree,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "degree", $$v)
                        },
                        expression: "formData.degree",
                      },
                    },
                    _vm._l(_vm.degreeList, function (degree) {
                      return _c("el-option", {
                        key: degree.value,
                        attrs: {
                          value: degree.value,
                          label: degree.label,
                          title: degree.label,
                          disabled: degree.label == "未知",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "btn-items lg-btn-items" },
        [
          _c("el-button", { on: { click: _vm._cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("compareResumeEducationForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }