var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "compareResumeWorkForm",
      staticClass: "compare-resume-work-form col-15",
      attrs: {
        id: "compare-resume-work-form",
        model: _vm.formData,
        rules: _vm.rules,
        "label-width": "80px",
        "label-position": "top",
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司", prop: "company" } },
                [
                  _c("customer-select-or-write", {
                    attrs: {
                      customerId: _vm.formData.customerId,
                      customerName: _vm.formData.company,
                    },
                    on: {
                      "update:customerId": function ($event) {
                        return _vm.$set(_vm.formData, "customerId", $event)
                      },
                      "update:customer-id": function ($event) {
                        return _vm.$set(_vm.formData, "customerId", $event)
                      },
                      "update:customerName": function ($event) {
                        return _vm.$set(_vm.formData, "company", $event)
                      },
                      "update:customer-name": function ($event) {
                        return _vm.$set(_vm.formData, "company", $event)
                      },
                      "finish-callback": _vm.finishCallback,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space" }),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "职位", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "128" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开始时间", prop: "startDate" } },
                        [
                          _c("el-date-picker", {
                            staticClass: "start-date-picker",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "month",
                              placeholder: "如 2018-08",
                              "value-format": "yyyy-MM",
                              "picker-options": _vm.startDateOptions,
                            },
                            model: {
                              value: _vm.formData.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "startDate", $$v)
                              },
                              expression: "formData.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "space-lg" }, [_vm._v("至")]),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "end-date-item",
                          attrs: { label: "结束时间", prop: "endDate" },
                        },
                        [
                          _c("el-date-picker", {
                            ref: "endDatePicker",
                            staticClass: "end-date-picker",
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "month",
                              placeholder: "如 2018-08",
                              "value-format": "yyyy-MM",
                              "picker-options": _vm.endDateOptions,
                            },
                            model: {
                              value: _vm.formData.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "endDate", $$v)
                              },
                              expression: "formData.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("span", { staticClass: "space" }),
          _c(
            "el-col",
            [
              _c(
                "el-form-item",
                { attrs: { label: "地区" } },
                [
                  _c("city-select", {
                    ref: "citySelect",
                    on: { citySelected: _vm.handleCitySelected },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "工作内容", prop: "description" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", maxlength: "8000" },
            model: {
              value: _vm.formData.description,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { staticClass: "btn-items" },
        [
          _c("el-button", { on: { click: _vm._cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("compareResumeWorkForm")
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }